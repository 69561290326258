import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';

const Accordion = ({ AccordionContent, Icon1, Icon2,...props }) => {
  return (
    <AccordionContainer>
      {AccordionContent.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          Icon1={Icon1}
          Icon2={Icon2}
          {...props}
        />
      ))}
    </AccordionContainer>
  );
};

const AccordionItem = ({ title, content, Icon1, Icon2,containerCustomCss,TitleCustomCss,contentCustomCss,SymbolCustomCss,...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Item style={containerCustomCss} {...props} tabIndex={0} aria-label='Accordion Tabs'>
      <Title onClick={() => setIsOpen(!isOpen)} {...props} style={TitleCustomCss} tabIndex={0} aria-label='Accordion Title'>
        {title}
        <Symbol {...props} style={SymbolCustomCss}>
          {isOpen ? (Icon1 || <Icon IconType="material" IconName="KeyboardArrowUp" />)
            : (Icon2 || <Icon IconType="material" IconName="KeyboardArrowDown" />)}
        </Symbol>
      </Title>
      <ContentWrapper isOpen={isOpen} {...props}>
        <Content {...props} style={contentCustomCss} tabIndex={0} aria-label='accordion Content'>{content}</Content>
      </ContentWrapper>
    </Item>
  );
};


const AccordionContainer = styled.div`
   width:100%;
   display:flex;
   justify-content:center;
   align-items:center;
   flex-direction:column;
   padding:5px 5px;
`;

const Item = styled.div`
  margin: 10px 0;
  border: ${props=>`${props.containerBorder || '1px solid #ccc'}`};
  border-radius:${props=>`${props.containerBorderRadius || '5px'}`}; 
  overflow: hidden;
  width:${props=>`${props.containerWidth || '100%'}`};
`;

const Title = styled.div`
  background-color: ${props=>`${props.TitleBgColor || '#f1f1f1'}`}; 
  cursor: pointer;
  padding: ${props=>`${props.TitlePadding || '15px'}`};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${props=>`${props.TitleTextColor || 'inherit'}`}; 
  font-size: ${props=>`${props.TitlefontSize|| 'inherit'}`}; 
  font-family: ${props=>`${props.TitlefontFamily || 'inherit'}`}; 
  font-weight: ${props=>`${props.TitlefontWeight || 'inherit'}`}; 
`;

const Symbol = styled.span`
  margin-left: 10px;
  color: ${props=>`${props.SymbolTextColor || 'inherit'}`}; 
  font-size: ${props=>`${props.SymbolfontSize|| '1.5rem'}`};
  font-weight: ${props=>`${props.SymbolfontWeight || 'inherit'}`}; 
`;

const ContentWrapper = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  ${({ isOpen }) => (isOpen ? 'transition: max-height 0.9s ease-in;' : '')}
`;


const Content = styled.div`
  padding: 15px;
  border-top: 1px solid white;
  text-align: left;
  background-color: ${props=>`${props.contentBgColor || 'white'}`}; 
  color: ${props=>`${props.contentTextColor || 'inherit'}`}; 
  font-size: ${props=>`${props.contentfontSize|| 'inherit'}`}; 
  font-family: ${props=>`${props.contentfontFamily || 'inherit'}`}; 
  font-weight: ${props=>`${props.contentfontWeight || 'inherit'}`}; 
`;

export default Accordion;
