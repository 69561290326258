import React from 'react';
import { useTheme } from './ThemeContext';
import styled from 'styled-components';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness2Icon from '@mui/icons-material/Brightness2';

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  box-sizing:border-box;
  padding:1px 1px;
  overflow:hidden;
`;

const ToggleLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 20px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #ccc;
  }

  &:checked + span:before {
    transform: translateX(100%);
  }
`;

const ToggleSlider = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.4s;
  padding:2px;

  &:before {
    content: '';
    position: absolute;
    top:0rem;
    left: 0px;
    bottom: 2px;
    right:2px;
    width: 20px;
    height: 20px;
    background-color: yellow;
    border-radius: 50%;
    transition: transform 0.4s;
   
  }
`;

const SunIcon = styled(WbSunnyIcon)`
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  color: gray;
  padding:3px 3px;
`;

const MoonIcon = styled(Brightness2Icon)`
  position: absolute;
  top: 50%;
  right: -3px;
  transform: translateY(-50%);
  color: gray;
  padding:3px 3px;
`;

const ThemeToggle = () => {
  const { theme, toggleTheme, cursor } = useTheme();

  return (
    <ToggleContainer>
      <ToggleLabel>
        <ToggleInput
          type="checkbox"
          onChange={toggleTheme}
          checked={theme === 'dark'}
          style={{ cursor: cursor }}
        />
        <ToggleSlider style={{ cursor: cursor }}>
          <SunIcon />
          <MoonIcon />
        </ToggleSlider>
      </ToggleLabel>
    </ToggleContainer>
  );
};

export default ThemeToggle;
