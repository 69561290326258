import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaCopy, FaCheck } from 'react-icons/fa';

const Container = styled.div`
  position: relative;
  background: linear-gradient(45deg,green,purple);
  padding: 0;
  border-radius: 8px;
  color: black;
  font-family: 'Courier New', Courier, monospace;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  margin: 0;
  background:rgb(72, 12, 12);
  color: white;
  padding: 10px;
  width: 100%;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); 
`;

const Heading = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  min-height: 100px;
`;

const LineNumbers = styled.div`
  text-align: right;
  padding: 10px;
  user-select: none;
  color: white;
  flex-shrink: 0;
  line-height: 28px;
`;

const CodeContent = styled.textarea`
  white-space: pre-wrap;  /* Allows text wrapping */
  background-color: inherit;
  color: white;
  border: none;
  resize: none;
  width: 100%;
  min-height: 100%;
  padding: 10px;
  padding-bottom: 40px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 28px;
  outline: none;
  spellcheck: false;
  overflow: auto;  /* Enables scrolling for overflow content */
`;

const HiddenDiv = styled.div`
  position: absolute;
  visibility: hidden;
  overflow: hidden;
  height: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: calc(100% - 20px); /* Adjust according to padding */
  padding: 10px;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 18px;
`;

const formatCode = (code) => {
  try {
    const parsed = JSON.parse(code);
    return JSON.stringify(parsed, null, 2);
  } catch (e) {
    return code;
  }
};

const CodeEditor = ({ children, heading, onChange }) => {
  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState(formatCode(children));
  const [lineCount, setLineCount] = useState(1);
  const hiddenDivRef = useRef(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };

  const handleChange = (event) => {
    const formattedCode = formatCode(event.target.value);
    setCode(formattedCode);
  };

  useEffect(() => {
    onChange(code);
  }, [code, onChange]);

  useEffect(() => {
    if (hiddenDivRef.current) {
      hiddenDivRef.current.innerText = code + '\n'; 
      const newLineCount = Math.ceil(hiddenDivRef.current.scrollHeight / 18);
      setLineCount(newLineCount);
    }
  }, [code]);

  return (
    <Container>
      <Header>
        <Heading>{heading}</Heading>
        <CopyButton onClick={handleCopy}>
          {copied ? <FaCheck /> : <FaCopy />}
        </CopyButton>
      </Header>
      <Content>
        <LineNumbers>
          {Array.from({ length: lineCount }).map((_, index) => (
            <div key={index}>{index + 1}</div>
          ))}
        </LineNumbers>
        <CodeContent value={code} onChange={handleChange} rows={lineCount} />
      </Content>
      <HiddenDiv ref={hiddenDivRef} />
    </Container>
  );
};

export default CodeEditor;
