import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Routes, Route, Link,useLocation } from 'react-router-dom';
import './style.css';
import Icon from '../Icon/Icon';

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => `${props.NavbarBgColor || '#1b2430'}`};
  padding: 20px 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  color: ${props => `${props.NavbartextColor || 'white'}`};
  font-family: ${props => `${props.NavbarFontFamily || 'inherit'}`};
  font-size: ${props => `${props.NavbarFontSize || '1.5rem'}`}; 
  position: relative;
  width: 100%;
  z-index: 2;
  height:100%;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 10px;
  cursor:pointer;

  img {
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    cursor:pointer;
  }

  @media(max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:0;
    left: 10px;
    bottom: 0;
  }
`;

const NavDiv = styled.div`
`;

const Menu = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    align-items:${props => (props.sideBarwidth > '300px' ? 'center' : 'flex-start')};
    text-align:left;
    justify-content: flex-start;
    top: ${props => `${props.sideBarTop || '63px'}`}; 
    bottom: 0;
    right: ${(props) => (props.hmbgrIsOpen ? '0' : `-${props.sideBarwidth || '300px'}`)};
    width: ${props => `${props.sideBarwidth || '300px'}`}; 
    height: 100vh;
    background-color: ${props => `${props.sideBarBgColor || '#2a3239'}`};
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    z-index: 3; 
    transition: right 0.3s ease;
  }
`;

const MenuItem = styled.li`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 20px;

  @media (max-width: 768px) {
    margin-bottom: 25px;
    width: auto;
  }
 
`;

const NavbarLink = styled(Link)`
  text-decoration: ${props => `${props.NavbartextDecoration || 'none'}`}; 
  color: ${props => `${props.NavbartextColor || 'white'}`};
  text-align: left;

  &:hover::after,
  &.active::after {
    content: '';
    width: 70%;
    height: 3px;
    background-color: ${props => `${props.HoverBgColor || 'aqua'}`}; 
    top: 100%;
    left: 20px;
    margin: 4px 0;
    position: absolute;
  }

  &:hover,
  &.active {
    color: ${props => `${props.HovertextColor || 'aqua'}`};
  }

  
`;

const Mobile = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
 
  @media (max-width: 768px) {
    display: flex;
  }
`;

const Backdrop = styled.div`
 @media(max-width:767px){
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1; 
  display: ${props => (props.show ? 'block' : 'none')}; 
 }
`;

const Navbar = ({ menuItems, logo, ...props }) => {
  const [hmbgrIsOpen, sethmbgrIsOpen] = useState(false);
  const location = useLocation();

  const handleHmbgr = () => {
    sethmbgrIsOpen(!hmbgrIsOpen);
  };

  const handleOnclickLink = () => {
    sethmbgrIsOpen(false);
  };

  useEffect(() => {
    if (hmbgrIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [hmbgrIsOpen]);

  return (
    <>
      <Nav {...props} aria-label='Menu' tabIndex={0}>
        <Logo {...props} tabIndex={0} aria-label='company_logo'>
          {logo ? logo : (
            <img 
              src="https://media.istockphoto.com/id/1331491686/vector/element-design.jpg?s=612x612&w=0&k=20&c=QIMRS2IPiQyyTZJR_G1JAjH_ErBBkrDPtQe2GBNgm2w="
              alt="company_Logo"
            />
          )}
        </Logo>
        <NavDiv>
          <Menu  tabIndex={0} aria-label='menu-section' hmbgrIsOpen={hmbgrIsOpen} {...props}>
            {menuItems.map((item, index) => (
              <MenuItem key={index}>
                <NavbarLink role='link' aria-label={`${item.label} link`}  {...props} onClick={handleOnclickLink} className={item.active ? 'active' : location.pathname === item.link ? 'active' : ''} to={item.link}>
                  {item.label}
                </NavbarLink>
              </MenuItem>
            ))}
          </Menu>
        </NavDiv>
        <Mobile onClick={handleHmbgr} role='button' aria-label='humbergerMenu' tabIndex={0}>
          {hmbgrIsOpen ? (
            <Icon IconType="fontawesome" IconName="faTimes" />
          ) : (
            <Icon IconType="fontawesome" IconName="faBars" />
          )}
        </Mobile>
      </Nav>
      <Backdrop show={hmbgrIsOpen ? hmbgrIsOpen.toString() : undefined} onClick={handleHmbgr} /> 

      <Routes>
        {menuItems.map((item, index) => (
           <Route path={item.link} key={index} element={item.element}>
           {item.element}
         </Route>
        ))}
      </Routes>
    </>
  );
};

export default Navbar;
