import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCopy, FaCheck } from 'react-icons/fa';

const Container = styled.div`
    position: relative;
    background: linear-gradient(45deg,green,purple);
    padding: 0;
    border-radius: 12px;
    color: black;
    font-family: 'Courier New', Courier, monospace;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
  margin: 0;
  background:rgb(72, 12, 12);
  color: white;
  padding: 10px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  padding: 10px; 
  box-sizing: border-box;
  color:white;
 font-weight:medium;
  font-size:18px;
`;

const LineNumbers = styled.div`
  text-align: right;
  padding-right: 10px;
  user-select: none;
  color: white;
  font-weight:medium;
  font-size:18px;
  flex-shrink: 0;
  line-height: 28px;
`;

const CodeContent = styled.div`
  position: relative;
  display: flex;
  white-space: pre-wrap;  /* Enables text wrapping */
  word-wrap: break-word;
  overflow-wrap: break-word; /* Ensures word wrapping */
  color: white;
  font-weight:medium;
  font-size:18px;
  line-height: 28px;
`;

const CodeBlock = styled.pre`
  margin: 0;
  white-space: pre-wrap;  /* Enables text wrapping */
`;

const Line = styled.div`
  display: flex;
`;

const CodeSnippet = ({ children, heading }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(children).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };

  const lines = children.split('\n');

  return (
    <Container>
      <Header>
        <Heading>{heading}</Heading>
        <CopyButton onClick={handleCopy}>
          {copied ? <FaCheck /> : <FaCopy />}
        </CopyButton>
      </Header>
      <Content>
        <LineNumbers>
          {lines.map((_, index) => (
            <div key={index}>{index + 1}</div>
          ))}
        </LineNumbers>
        <CodeContent>
          <CodeBlock>
            {lines.map((line, index) => (
              <Line key={index}>{line}</Line>
            ))}
          </CodeBlock>
        </CodeContent>
      </Content>
    </Container>
  );
};

export default CodeSnippet;
