import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';

const SliderAndRangePickerPage = () => {

  const initialCode = ` 
       <h3> Slider Picker</h3>
       <SliderPicker
        min={0} 
        max={100}
        step={1} 
        value={50}
        SliderWidth="300px"
        SliderBgColor='blue' />

        `;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };


  const handleRangeChange = () => {
      // console.log('range changed');
    };




  const initialCode2 = `
        <h3> MultiRange Picker</h3>
        <br/>
         <RangePicker
          min={0}
          max={100}
          step={10}
          onChange={${handleRangeChange}}
          PickerWidth="400px"
          PickerTrackBgColor="gray"
          PickerRangeBgColor="green"
        />
        `;

  const [code2, setCode2] = useState(initialCode2);
  const handleCodeChange2 = (newCode) => {
    setCode2(newCode);
  };

  

  const snipet_code = `
  import React, { useState } from 'react';
import SliderPicker from './customComponents/SliderPicker';
import RangePicker from './customComponents/RangePicker';

const App = () => {
  const [value, setValue] = useState(0);
  const handleSliderChange = (newValue) => {
    setValue(newValue);
  };

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const handleRangeChange = ({ min, max }) => {
    setMinValue(min);
    setMaxValue(max);
  };

  return (
    <div>
      <center>
        <h1>Slider and Range Picker</h1>
        <SliderPicker
          min={0}
          max={100}
          step={1}
          value={value}
          onChange={handleSliderChange}
          SliderWidth="800px"
          SliderBgColor="blue"
        />
        <div>Value : {value}</div>
      </center>

      <center>
        <h1>Range Picker</h1>
        <RangePicker
          min={0}
          max={100}
          step={10}
          onChange={handleRangeChange}
          PickerWidth="80%"
          PickerTrackBgColor="gray"
          PickerRangeBgColor="green"
        />
        <div style={{ marginTop: '60px' }}>
          <span>
            <label>Min Value : </label>
            {minValue}
          </span>
          <br />
          <span>
            <label>Max Value : </label>
            {maxValue}
          </span>
        </div>
      </center>
    </div>
  );
};

export default App;


  `;

   
     // State to track whether initial highlight should be shown
     const [showInitialHighlight, setShowInitialHighlight] = useState(true);

     // Function to handle hover effect (remove initial highlight)
     const handleMouseEnter = () => {
       setShowInitialHighlight(true);
     };
   
     // Function to handle leaving hover effect (restore initial highlight if needed)
     const handleMouseLeave = () => {
       setShowInitialHighlight(false);
     };
      

  return (
    <div className="wrapper-container">
       <ScrollButton/>
      {/* Heading */}
      <div className='Header-section'>
        <center>
           <h2>Slider and Range Picker</h2>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>A Slider is an interactive UI component for selecting a number within a range by dragging a handle along a track. A Range Picker, also number-related, allows users to select a range of numbers by specifying both a minimum and maximum value, enhancing input precision for numerical data.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

      
      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code2} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange2}>
                {code2}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <h3>Slider Picker Props Details</h3>
      <br/>
      <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">Prop</th>
                <th className="table-header">Type</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">min</td>
                <td className="table-data">number</td>
                <td className="table-data">The minimum value of the slider.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">max</td>
                <td className="table-data">number</td>
                <td className="table-data">The maximum value of the slider.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">step</td>
                <td className="table-data">number</td>
                <td className="table-data">The granularity of the slider.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">value</td>
                <td className="table-data">number</td>
                <td className="table-data">The current value of the slider.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">onChange</td>
                <td className="table-data">function</td>
                <td className="table-data">Callback function invoked when the value of the slider changes.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderCustomCss</td>
                <td className="table-data">object</td>
                <td className="table-data">Custom CSS styles for the slider container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">labelCustomCss</td>
                <td className="table-data">object</td>
                <td className="table-data">Custom CSS styles for the labels.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderWidth</td>
                <td className="table-data">string</td>
                <td className="table-data">Width of the slider. Defaults to '100%'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderBgColor</td>
                <td className="table-data">string</td>
                <td className="table-data">Background color of the slider. Defaults to 'blue'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderHeight</td>
                <td className="table-data">string</td>
                <td className="table-data">Height of the slider track. Defaults to '20px'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderborderRadius</td>
                <td className="table-data">string</td>
                <td className="table-data">Border radius of the slider track. Defaults to '5px'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderOutline</td>
                <td className="table-data">string</td>
                <td className="table-data">Outline style of the slider track. Defaults to 'none'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderThumbWidth</td>
                <td className="table-data">string</td>
                <td className="table-data">Width of the slider thumb. Defaults to '30px'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderThumbHeight</td>
                <td className="table-data">string</td>
                <td className="table-data">Height of the slider thumb. Defaults to '30px'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderThumbBgColor</td>
                <td className="table-data">string</td>
                <td className="table-data">Background color of the slider thumb. Defaults to 'white'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderThumbBorderRadius</td>
                <td className="table-data">string</td>
                <td className="table-data">Border radius of the slider thumb. Defaults to '10%'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderThumbTop</td>
                <td className="table-data">string</td>
                <td className="table-data">Position of the slider thumb. Defaults to '50%'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SliderThumbBorder</td>
                <td className="table-data">string</td>
                <td className="table-data">Border style of the slider thumb. Defaults to '1px solid gray'.</td>
              </tr>
            </tbody>
          </table>
      </div>

      <br/>
      <br/>
      <h3>Range Picker props Details</h3>
      <br/>
      <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Description</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">min</td>
                  <td className="table-data">number</td>
                  <td className="table-data">The minimum value of the slider.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">max</td>
                  <td className="table-data">number</td>
                  <td className="table-data">The maximum value of the slider.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">step</td>
                  <td className="table-data">number</td>
                  <td className="table-data">The granularity of the slider. Defaults to 1.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">onChange</td>
                  <td className="table-data">function</td>
                  <td className="table-data">Callback function invoked when the value of the slider changes.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerWidth</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Width of the slider picker. Defaults to '100%'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerTrackBgColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Background color of the slider track. Defaults to 'green' for Track and 'red' for Range.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerTrackerBorderRadius</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Border radius of the slider track. Defaults to '3px'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerTrackHeight</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Height of the slider track. Defaults to '20px'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerRangeBorderRadius</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Border radius of the slider range. Defaults to '3px'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerRangeHeight</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Height of the slider range. Defaults to '20px'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerThumbBgColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Background color of the slider thumb. Defaults to 'white'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerThumbBorder</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Border style of the slider thumb. Defaults to '1px solid black'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerThumbBorderRadius</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Border radius of the slider thumb. Defaults to '10%'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerThumbHeight</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Height of the slider thumb. Defaults to '30px'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerThumbWidth</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Width of the slider thumb. Defaults to '30px'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">PickerThumbTop</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Position of the slider thumb from the top. Defaults to '10px'.</td>
                </tr>
              </tbody>
            </table>
          </div>

      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
      </div>   
    </div>
  )
}

export default SliderAndRangePickerPage