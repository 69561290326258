import React, {useState, useEffect } from 'react';
import './Overview.css'; // Changed: Added import for CSS file
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from './ScrollButton';

const Installation = () => {
  const snipet_code = `npm install atomic-fusion-lib`;
  const snipet_code2 = `yarn add atomic-fusion-lib`;
  const snipet_code3 = `import { Button } from 'atomic-fusion-lib';`;
  const snipet_code4 = `const App = () => {
    return (
      <div>
        <Button variant="primary">Click Me</Button>
      </div>
    );
  };
  `;

  // State to track whether initial highlight should be shown
  const [showInitialHighlight, setShowInitialHighlight] = useState(true);

  // Function to handle hover effect (remove initial highlight)
  const handleMouseEnter = () => {
    setShowInitialHighlight(true);
  };

  // Function to handle leaving hover effect (restore initial highlight if needed)
  const handleMouseLeave = () => {
    setShowInitialHighlight(false);
  };


  return (
    <div className="container">
       <ScrollButton/>
      <header className="header">
          <h1>Installation</h1>
      </header>
      <main style={{ background: 'transparent' }}>
        {/* Changed: Added className 'section-div' to each section */}
        <div className={`section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <h4 style={{ zIndex: 10 }}>Begin by installing the Atomic-Fusion UI library using npm or yarn. Open your terminal and run one of the following commands:</h4>
         </div>
        <br />

        {/* Changed: Added className 'section-div' to each section */}
        <div className='section-div'>
          <h5>1.Step: Install the Library</h5>
          {/* Snippet code section */}
          <div className="section-C-Container" style={{ marginTop: '10px' }}>
              <CodeSnippet heading="bash">{snipet_code}</CodeSnippet>
          </div>
          <p>Or, if you're using yarn:</p>
          {/* Snippet code section */}
          <div className="section-C-Container" style={{ marginTop: '15px' }}>
              <CodeSnippet heading="bash">{snipet_code2}</CodeSnippet>
          </div>
        </div>

        <br />
        {/* Changed: Added className 'section-div' to each section */}
        <div className='section-div'>
          <h5>2.Step: Import the Components</h5>
          <p>Next, import the Button component from the library into your React project. Here's an example:</p>
          {/* Snippet code section */}
          <div className="section-C-Container" style={{ marginTop: '10px' }}>
              <CodeSnippet heading="Javascript">{snipet_code3}</CodeSnippet>
          </div>
        </div>

        <br />
        {/* Changed: Added className 'section-div' to each section */}
        <div className='section-div'>
          <h5>3.Step: Use the Button Component</h5>
          <p>Incorporate the imported Button component into your React component. Here's a basic example of how to use the Button:</p>
          {/* Snippet code section */}
          <div className="section-C-Container" style={{ marginTop: '15px' }}>
              <CodeSnippet heading="Javascript">{snipet_code4}</CodeSnippet>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Installation;
