import React,{useState} from 'react';
import './Overview.css';
import Button from '../../customComponents/Button/Button';
import Icon from '../../customComponents/Icon/Icon';
import { NavLink } from 'react-router-dom';
import ScrollButton from './ScrollButton';



const Overview = () => {
    // State to track whether initial highlight should be shown
  const [showInitialHighlight, setShowInitialHighlight] = useState(true);

  // Function to handle hover effect (remove initial highlight)
  const handleMouseEnter = () => {
    setShowInitialHighlight(true);
  };

  // Function to handle leaving hover effect (restore initial highlight if needed)
  const handleMouseLeave = () => {
    setShowInitialHighlight(false);
  };
  return (
    <div class="container">
         <ScrollButton/>
        <header class="header">
            <h1>Atomic-Fusion UI Library</h1>
        </header>
      
           <section className={`component section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave} style={{padding:'35px 30px'}}>
                <h2>Button</h2>
                <p>Description: The <strong>Button</strong> component is a versatile UI element that can trigger actions, navigate to different views, or submit forms. It supports various styles, sizes, and states to match the design requirements of any application.</p>
                <ul>
                    <li>Multiple variants (e.g., primary, secondary, outline)</li>
                    <li>Different sizes (small, medium, large)</li>
                    <li>Customizable colors and styles</li>
                    <li>Support for icons</li>
                    <li>Disabled state</li>
                    <li>Click event handling</li>
                </ul>
               <NavLink className="More-link" to="/component/Button"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Typography</h2>
                <p>Description: The <strong>Typography</strong> component is designed to render text in a consistent and accessible way. It supports different text styles, sizes, and weights, making it suitable for headings, body text, captions, and more.</p>
                <ul>
                    <li>Various text styles (e.g., h1, h2, h3, body, caption)</li>
                    <li>Customizable font size, color, and weight</li>
                    <li>Responsive scaling</li>
                    <li>Text alignment options</li>
                </ul>
                <NavLink className="More-link" to="/component/Typography"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Table</h2>
                <p>Description: The <strong>Table</strong> component provides a flexible and powerful way to display data in a tabular format. It supports features like sorting, pagination, and custom cell rendering.</p>
                <ul>
                    <li>Column sorting</li>
                    <li>Pagination</li>
                    <li>Customizable headers and cells</li>
                    <li>Row selection</li>
                    <li>Expandable rows</li>
                </ul>
                <NavLink className="More-link" to="/component/Table"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Accordion</h2>
                <p>Description: The <strong>Accordion</strong> component organizes content into collapsible sections, allowing users to expand and collapse different parts of the content as needed. It is ideal for FAQs, multi-step forms, and more.</p>
                <ul>
                    <li>Multiple collapsible sections</li>
                    <li>Customizable icons for open/close states</li>
                    <li>Smooth animations</li>
                    <li>Custom styling for headers and content</li>
                </ul>
                <NavLink className="More-link" to="/component/Accordion"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Card</h2>
                <p>Description: The <strong>Card</strong> component is a container used to display content and actions on a single topic. It supports various layouts and can include images, text, and interactive elements.</p>
                <ul>
                    <li>Header, content, and footer sections</li>
                    <li>Image and media support</li>
                    <li>Customizable borders and shadows</li>
                    <li>Interactive elements (e.g., buttons, links)</li>
                </ul>
                <NavLink className="More-link" to="/component/Card"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Chart</h2>
                <p>Description: The <strong>Chart</strong> component integrates with popular charting libraries to provide various types of charts (e.g., bar, line, pie) for data visualization. It supports customization and interactivity.</p>
                <ul>
                    <li>Multiple chart types</li>
                    <li>Customizable axes, labels, and colors</li>
                    <li>Interactive elements (e.g., tooltips, legends)</li>
                    <li>Data-driven updates</li>
                </ul>
                <NavLink className="More-link" to="/component/Chart"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Loader</h2>
                <p>Description: The <strong>Loader</strong> component indicates loading states in your application, providing visual feedback to users while data or content is being fetched.</p>
                <ul>
                    <li>Different styles (e.g., spinner, bar)</li>
                    <li>Customizable size and color</li>
                    <li>Support for different loading animations</li>
                </ul>
                <NavLink className="More-link" to="/component/Loader"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Modal</h2>
                <p>Description: The <strong>Modal</strong> component creates overlay dialogs that can display content, forms, or actions. It is useful for getting user input or providing additional information without navigating away from the current page.</p>
                <ul>
                    <li>Header, body, and footer sections</li>
                    <li>Customizable size and backdrop</li>
                    <li>Support for actions (e.g., confirm, cancel)</li>
                    <li>Smooth animations</li>
                </ul>
                <NavLink className="More-link" to="/component/Modal"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Date Picker</h2>
                <p>Description: The <strong>Date Picker</strong> component allows users to select dates and times. It supports various formats, ranges, and customization options to fit different use cases.</p>
                <ul>
                    <li>Single date and range selection</li>
                    <li>Customizable date formats</li>
                    <li>Time selection support</li>
                    <li>Disable specific dates</li>
                </ul>
                <NavLink className="More-link" to="/component/Date-Picker"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Form Components</h2>
                <p>Description: The <strong>Form Components</strong> collection includes various input elements like text fields, checkboxes, radio buttons, and select boxes, designed to create robust and accessible forms.</p>
                <ul>
                    <li>Standardized styling</li>
                    <li>Validation support</li>
                    <li>Customizable labels and placeholders</li>
                    <li>Accessible focus states</li>
                </ul>
                <NavLink className="More-link" to="/component/Form"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Icon</h2>
                <p>Description: The <strong>Icon</strong> component provides a set of scalable vector icons that can be customized and used across your application. It supports different sizes, colors, and interactive states.</p>
                <ul>
                    <li>Wide range of icons</li>
                    <li>Customizable size and color</li>
                    <li>Clickable and hover states</li>
                </ul>
                <NavLink className="More-link" to="/component/Icon"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Navigation</h2>
                <p>Description: The <strong>Navigation</strong> components include menus, tabs, and breadcrumbs that help users navigate through the application. They are designed to be intuitive and accessible.</p>
                <ul>
                    <li>Horizontal and vertical layouts</li>
                    <li>Dropdown and mega menus</li>
                    <li>Customizable styles and active states</li>
                    <li>Responsive design</li>
                </ul>
                <NavLink className="More-link" to="/component/Navigation"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Slider and Range Picker</h2>
                <p>Description: The <strong>Slider and Range Picker</strong> component allows users to select a value or range of values by sliding a handle. It is useful for adjusting settings or filtering data.</p>
                <ul>
                    <li>Single and range selection</li>
                    <li>Customizable min and max values</li>
                    <li>Step increments</li>
                    <li>Visual feedback for current value</li>
                </ul>
                <NavLink className="More-link" to="/component/Slider-&-Range-Picker"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
            <section class="component">
                <h2>Tooltips and Popover</h2>
                <p>Description: The <strong>Tooltips and Popover</strong> components provide additional information when users hover over or click on an element. They are useful for hints, explanations, and additional actions.</p>
                <ul>
                    <li>Customizable content and positioning</li>
                    <li>Smooth show/hide animations</li>
                    <li>Trigger options (hover, click)</li>
                    <li>Custom styling</li>
                </ul>
                <NavLink className="More-link" to="/component/Tooltips-Popover"><Icon IconType='material' IconName="PlayCircleOutline"/> More..</NavLink>
            </section>
    </div>
  )
}

export default Overview;