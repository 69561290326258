import React,{useState,useEffect} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';
const LoaderPage = () => {

  
//  For Circle progress bar
  const initialCode = `
          <div>
          <h3>CircleProgressBar with label</h3>
          <CircleProgressBar
            totalSize={100}
            downloadedSize={50}
            strokeColor="#4caf50"
            strokeWidth={30}
            circleSize={150}
            normalTextSize={24}
            TextWeight="bold"
            TextColor="#fff"
            mobileTextSize={18}
            Labeldisplay={true}
          />
           <br/>
           <h3>CircleProgressBar without label</h3>
           <CircleProgressBar
            totalSize={100}
            downloadedSize={50}
            strokeColor="#4caf50"
            strokeWidth={30}
            circleSize={150}
            normalTextSize={24}
            TextWeight="bold"
            TextColor="#fff"
            mobileTextSize={18}
            Labeldisplay={false}
          />
          <h3>LinearProgressBar with label</h3>
          <LinearProgressBar
            totalSize={100}
            downloadedSize={50}
            duration={100}
            progressBeforefillBgColor="#ccc"
            progressfillBgColor="#007bff"
            textAlign="center"
            fontSize="1em"
            fontWeight="bold"
            textColor="#fff"
            progressHeight="20px"
            Labeldisplay={true}
          />
          <br/>
          <h3>LinearProgressBar without label</h3>
          <LinearProgressBar
            totalSize={100}
            downloadedSize={50}
            duration={100}
            progressBeforefillBgColor="#ccc"
            progressfillBgColor="#007bff"
            textAlign="center"
            fontSize="1em"
            fontWeight="bold"
            textColor="#fff"
            progressHeight="20px"
            Labeldisplay={false}
          />
          </div>
  `;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  

  const snipet_code = `
    import React,{useState,useEffect} from 'react';
    import CircleProgressBar from './customComponents/Loader/CircleProgressBar';

    const App = () => {

      const totalSize = 100; // in MB
      const [downloadedSize, setDownloadedSize] = useState(0);

      useEffect(() => {
        // Simulate download
        const interval = setInterval(() => {
          setDownloadedSize((prev) => {
            const newSize = prev + 1;
            if (newSize >= totalSize) {
              clearInterval(interval);
              return totalSize;
            }
            return newSize;
          });
        }, 100); // update every 100ms

        return () => clearInterval(interval);
      }, [totalSize]);
    

      return (
      
          <>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
          <CircleProgressBar
            totalSize={totalSize}
            downloadedSize={downloadedSize}
            strokeColor="#4caf50"
            strokeWidth={30}
            circleSize={150}
            normalTextSize={24}
            TextWeight="bold"
            TextColor="#fff"
            mobileTextSize={18}
            Labeldisplay={true}
          />
          <br/>
            <CircleProgressBar
            totalSize={totalSize}
            downloadedSize={downloadedSize}
            strokeColor="#4caf50"
            strokeWidth={30}
            circleSize={150}
            normalTextSize={24}
            TextWeight="bold"
            TextColor="#fff"
            mobileTextSize={18}
            Labeldisplay={false}
          />
          <br/>
            <LinearProgressBar
            totalSize={totalSize}
            downloadedSize={downloadedSize}
            duration={100}
            progressBeforefillBgColor="#ccc"
            progressfillBgColor="#007bff"
            textAlign="center"
            fontSize="1em"
            fontWeight="bold"
            textColor="#fff"
            progressHeight="20px"
            Labeldisplay={true}
          />
          <br/>
          <LinearProgressBar
            totalSize={totalSize}
            downloadedSize={downloadedSize}
            duration={100}
            progressBeforefillBgColor="#ccc"
            progressfillBgColor="#007bff"
            textAlign="center"
            fontSize="1em"
            fontWeight="bold"
            textColor="#fff"
            progressHeight="20px"
            Labeldisplay={false}
          />
          </div>
          </>
      );
    };

    export default App;

 `;
// End





// For Loader 
const initialCode3 = `
   <div>
      <center>  
                <Loader
                LoaderName="Circles"
                LoaderHeight="80"
                LoaderWidth="80"
                LoaderRadius="9"
                LoaderColor="green"
                wrapperCustomCss ={{alignItems:'center',justifyContent:'center'}}
                visibility={true}
                />

                <br/>
                 <Loader
                  LoaderName="Bars"
                  LoaderHeight="80"
                  LoaderWidth="80"
                  LoaderRadius="9"
                  LoaderColor="red"
                  wrapperCustomCss ={{alignItems:'center',justifyContent:'center'}}
                  visibility={true}
                />

      </center>
    </div>
`;

  const [code3, setCode3] = useState(initialCode3);
  const handleCodeChange3 = (newCode) => {
    setCode3(newCode);
  };

  

  const snipet_code3 = `
    import React, { useState } from 'react';
import Loader from './customComponents/Loader/Loader';
import Button from './customComponents/Button/Button';


const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  return (
    <div>
      <center>
    
             <h2>Loaders</h2>
              <Button variant="primary" marginB="30px" marginT="30px"  onClick={startLoading}>Start Loading</Button>
              <Button variant="danger" marginB="30px" marginT="30px"  onClick={stopLoading}>Stop Loading</Button>
               
                <Loader
                LoaderName="Circles"
                LoaderHeight="80"
                LoaderWidth="80"
                LoaderRadius="9"
                LoaderColor="green"
                wrapperCustomCss ={{alignItems:'center',justifyContent:'center'}}
                visibility={isLoading}
                />
               
                <br/>
                 <Loader
                  LoaderName="Bars"
                  LoaderHeight="80"
                  LoaderWidth="80"
                  LoaderRadius="9"
                  LoaderColor="red"
                  wrapperCustomCss ={{alignItems:'center',justifyContent:'center'}}
                  visibility={isLoading}
                />

      </center>
    </div>
  );
};

export default App;

 `;

// End

// For skeleton
const initialCode4 = `
<div>
        <center>
        
                <h3>Material UI Skeleton:</h3>
                  <Skeleton 
                  type="material"
                  SkeletonWidth="200px"
                  SkeletonHeight="20px"
                  SkeletonVariant="rectangular"
                  SkeletonAnimation="wave"
                  SkeletonStyle={{ marginBottom: '10px' }}
                  />
                  <Skeleton 
                  type="material"
                  SkeletonWidth="50px"
                  SkeletonHeight="50px"
                  SkeletonVariant="circular"
                  SkeletonAnimation="wave"
                  SkeletonStyle={{ marginBottom: '10px',backgroundColor:'tranparent'}}
                  />

              <br/>
              <h3>Ant Design Skeleton:</h3>
                <Skeleton 
                  type="ant"
                  SkeletonWidth="200px"
                  SkeletonVariant="Avatar"
                  SkeletonActive={true}
                  SkeletonStyle={{ marginBottom: '10px',backgroundColor:'tranparent'}}
                  />
                  <br/>
                  <Skeleton 
                  type="ant"
                  SkeletonVariant="Button"
                  SkeletonWidth="50px"
                  SkeletonActive={true}
                  SkeletonStyle={{ marginBottom: '10px',backgroundColor:'tranparent',width:'200px' }}
                  />

        </center>
      </div>
`;

  const [code4, setCode4] = useState(initialCode4);
  const handleCodeChange4 = (newCode) => {
    setCode4(newCode);
  };

  

  const snipet_code4 = `
  import React, { useState } from 'react';
  import SkeletonLoader from './customComponents/Loader/SkeletonLoader';


  const App = () => {


    return (
      <div>
        <center>
        
                <h3>Material UI SkeletonLoader:</h3>
                  <Skeleton 
                  type="material"
                  SkeletonWidth="200px"
                  SkeletonHeight="20px"
                  SkeletonVariant="rectangular"
                  SkeletonAnimation="wave"
                  SkeletonStyle={{ marginBottom: '10px' }}
                  />
                  <Skeleton 
                  type="material"
                  SkeletonWidth="50px"
                  SkeletonHeight="50px"
                  SkeletonVariant="circular"
                  SkeletonAnimation="wave"
                  SkeletonStyle={{ marginBottom: '10px',backgroundColor:'tranparent'}}
                  />

              <br/>
              <h3>Material UI Skeleton:</h3>
                <Skeleton 
                  type="ant"
                  SkeletonWidth="200px"
                  SkeletonVariant="Avatar"
                  SkeletonActive={true}
                  SkeletonStyle={{ marginBottom: '10px',backgroundColor:'tranparent'}}
                  />
                  <br/>
                  <Skeleton 
                  type="ant"
                  SkeletonVariant="Button"
                  SkeletonWidth="50px"
                  SkeletonActive={true}
                  SkeletonStyle={{ marginBottom: '10px',backgroundColor:'tranparent' }}
                  />

        </center>
      </div>
    );
  };

  export default App;

 `;

// End
  
     // State to track whether initial highlight should be shown
     const [showInitialHighlight, setShowInitialHighlight] = useState(true);

     // Function to handle hover effect (remove initial highlight)
     const handleMouseEnter = () => {
       setShowInitialHighlight(true);
     };
   
     // Function to handle leaving hover effect (restore initial highlight if needed)
     const handleMouseLeave = () => {
       setShowInitialHighlight(false);
     };

  return (
    <div className="wrapper-container">
       <ScrollButton/>

       <center>
           <h2 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>Progress Bar /Loader / Skeleton</h2>
        </center>
       
       {/* Circle Progress Bar */}
        <div className='Loader-container'>
            {/* Heading */}
          <div className='Header-section'>
            <center>
              <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>Progress Bar</h3>
            </center>
            <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
                <p><strong>Circle progress bar</strong> visually represents the progress of a task, such as a download,upload,fetch data,etc. It dynamically calculates and displays the progress percentage based on the downloaded size relative to the total size. Customizable properties include stroke color, stroke width, circle size, and optional label display.</p>
                <br/>
                <p><strong>Linear progress bar</strong> is a React component that visually represents progress as a horizontal bar. It accepts props such as totalSize, downloadedSize, and Labeldisplay to calculate and display the completion percentage. Styled-components are used to customize the bar's appearance, including its color, height, and label text. The component updates dynamically as the downloadedSize changes.</p>
            </div>

          </div>
        

          {/* Preview and Editor section */}
          <div className="section-A-Container section-div">
            <p>Code Editor with preview.</p>
            <div className="preview-editor-section column">
                <div className='width-100'>
                  <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
                </div>
                <div className='width-100'>
                  <CodeEditor heading="Editor" onChange={handleCodeChange}>
                    {code}
                  </CodeEditor>
                </div>
            </div> 
          </div>

        {/* Props details section */}
          <div className="section-B-Container section-div">
          <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

          <h2>Circle Progress Bar Props Details</h2>
          <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Description</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">totalSize</td>
                  <td className="table-data">number</td>
                  <td className="table-data">Required. The total size representing 100% of the progress.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">downloadedSize</td>
                  <td className="table-data">number</td>
                  <td className="table-data">Required. The size of the progress made so far.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">strokeColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. The color of the progress stroke. Default is 'green'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">strokeWidth</td>
                  <td className="table-data">number</td>
                  <td className="table-data">Optional. The width of the progress stroke. Default is 10.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">circleSize</td>
                  <td className="table-data">number</td>
                  <td className="table-data">Optional. The size of the circle. Default is 100.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">start</td>
                  <td className="table-data">boolean</td>
                  <td className="table-data">Optional. Determines if the progress should start. Default is false.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">Labeldisplay</td>
                  <td className="table-data">boolean</td>
                  <td className="table-data">Optional. Determines if the label should be displayed. Default is true.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">normalTextSize</td>
                  <td className="table-data">number</td>
                  <td className="table-data">Optional. Font size of the label text. Default is 18.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">TextWeight</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. Font weight of the label text. Default is 'bold'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">TextColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. Color of the label text. Default is 'black'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">mobileTextSize</td>
                  <td className="table-data">number</td>
                  <td className="table-data">Optional. Font size of the label text on mobile devices. Default is 14.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <br/>
          <br/>
          <h2>Linear Progress Bar Props Details</h2>
          <div className="table-container">
                <table className="styled-table">
                  <thead className="table-head">
                    <tr>
                      <th className="table-header">Prop Name</th>
                      <th className="table-header">Type</th>
                      <th className="table-header">Default Value</th>
                      <th className="table-header">Description</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    <tr className="table-row">
                      <td className="table-data">totalSize</td>
                      <td className="table-data">number</td>
                      <td className="table-data">N/A</td>
                      <td className="table-data">The total size value for calculating the progress percentage.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">downloadedSize</td>
                      <td className="table-data">number</td>
                      <td className="table-data">N/A</td>
                      <td className="table-data">The downloaded size value for calculating the progress percentage.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">Labeldisplay</td>
                      <td className="table-data">boolean</td>
                      <td className="table-data">true</td>
                      <td className="table-data">A flag to show or hide the progress percentage label.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">progressHeight</td>
                      <td className="table-data">string</td>
                      <td className="table-data">'30px'</td>
                      <td className="table-data">The height of the progress bar.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">progressBorderRadius</td>
                      <td className="table-data">string</td>
                      <td className="table-data">'15px'</td>
                      <td className="table-data">The border-radius of the progress bar.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">progressBeforefillBgColor</td>
                      <td className="table-data">string</td>
                      <td className="table-data">'#e6e6e6'</td>
                      <td className="table-data">The background color of the progress bar before it is filled.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">progressfillBgColor</td>
                      <td className="table-data">string</td>
                      <td className="table-data">'green'</td>
                      <td className="table-data">The background color of the progress bar when it is filled.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">textAlign</td>
                      <td className="table-data">string</td>
                      <td className="table-data">'center'</td>
                      <td className="table-data">The text alignment of the label text.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">fontSize</td>
                      <td className="table-data">string</td>
                      <td className="table-data">'1.2em'</td>
                      <td className="table-data">The font size of the label text.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">fontWeight</td>
                      <td className="table-data">string</td>
                      <td className="table-data">'bold'</td>
                      <td className="table-data">The font weight of the label text.</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-data">textColor</td>
                      <td className="table-data">string</td>
                      <td className="table-data">'white'</td>
                      <td className="table-data">The color of the label text.</td>
                    </tr>
                  </tbody>
                </table>
              </div>


          </div> 

          {/* Snippet code section */}
          <div className="section-C-Container section-div">
              <p>Using this component in any page, full code snippet.</p><br/>
              <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
          </div>   

        </div>

       {/* End */}


  


      {/* Loader */}
    <div className='Loader-container'>
        {/* Heading */}
          <div className='Header-section'>
          <center>
            <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'rgb(130, 149, 234)'}}>Loader</h3>
          </center>
          <div className="content-section section-div">
              <p>The Loader component is a versatile React component designed to dynamically display various types of loaders/spinners. It allows you to specify the type of loader, its size, color, and other attributes via props, making it highly customizable for different loading states in your application.The component supports a wide range of loader types, which can be specified via the LoaderName prop. Here are the available loaders:</p>
              
              <div style={{marginLeft:'20px'}}>
              <ul>
                  <li>Audio</li>
                  <li>BallTriangle</li>
                  <li>Bars</li>
                  <li>Blocks</li>
                  <li>CirclesWithBar</li>
                  <li>Circles</li>
                  <li>Color Ring</li>
                  <li>Comment</li>
                  <li>Discuss</li>
                  <li>DNA</li>
                  <li>FallingLines</li>
                  <li>FidgetSpinner</li>
                  <li>Grid</li>
                  <li>Hearts</li>
                  <li>Hourglass</li>
                  <li>Infinity Spin</li>
                  <li>LineWave</li>
                  <li>MagnifyingGlass</li>
                  <li>MutatingDots</li>
                  <li>Oval</li>
                  <li>ProgressBar</li>
                  <li>Puff</li>
                  <li>Radio</li>
                  <li>RevolvingDot</li>
                  <li>Rings</li>
                  <li>RotatingLines</li>
                  <li>RotatingSquare</li>
                  <li>RotatingTriangles</li>
                  <li>TailSpin</li>
                  <li>ThreeCircles</li>
                  <li>ThreeDots</li>
                  <li>Triangle</li>
                  <li>Vortex</li>
                  <li>Watch</li>
                </ul>
              </div>
          </div>

        </div>
      

        {/* Preview and Editor section */}
        <div className="section-A-Container section-div">
          <p>Code Editor with preview.</p>
          <div className="preview-editor-section column">
              <div className='width-100'>
                <Preview code={code3} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
              </div>
              <div className='width-100'>
                <CodeEditor heading="Editor" onChange={handleCodeChange3}>
                  {code3}
                </CodeEditor>
              </div>
          </div> 
        </div>

      {/* Props details section */}
        <div className="section-B-Container section-div">
        <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

        <div className="table-container">
              <table className="styled-table">
                <thead className="table-head">
                  <tr className="">
                    <th className="table-header">Prop Name</th>
                    <th className="table-header">Type</th>
                    <th className="table-header">Default</th>
                    <th className="table-header">Description</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  <tr className="table-row">
                    <td className="table-data">LoaderName</td>
                    <td className="table-data">string</td>
                    <td className="table-data">-</td>
                    <td className="table-data">The name of the loader component from react-loader-spinner.</td>
                  </tr>
                  <tr className="table-row">
                    <td className="table-data">LoaderHeight</td>
                    <td className="table-data">number</td>
                    <td className="table-data">-</td>
                    <td className="table-data">The height of the loader.</td>
                  </tr>
                  <tr className="table-row">
                    <td className="table-data">LoaderWidth</td>
                    <td className="table-data">number</td>
                    <td className="table-data">-</td>
                    <td className="table-data">The width of the loader.</td>
                  </tr>
                  <tr className="table-row">
                    <td className="table-data">LoaderRadius</td>
                    <td className="table-data">number</td>
                    <td className="table-data">-</td>
                    <td className="table-data">The radius of the loader (if applicable).</td>
                  </tr>
                  <tr className="table-row">
                    <td className="table-data">LoaderColor</td>
                    <td className="table-data">string</td>
                    <td className="table-data">-</td>
                    <td className="table-data">The color of the loader.</td>
                  </tr>
                  <tr className="table-row">
                    <td className="table-data">id</td>
                    <td className="table-data">string</td>
                    <td className="table-data">-</td>
                    <td className="table-data">The id attribute for the loader component.</td>
                  </tr>
                  <tr className="table-row">
                    <td className="table-data">className</td>
                    <td className="table-data">string</td>
                    <td className="table-data">-</td>
                    <td className="table-data">The className attribute for the loader component.</td>
                  </tr>
                  <tr className="table-row">
                    <td className="table-data">wrapperCustomCss</td>
                    <td className="table-data">object</td>
                    <td className="table-data">-</td>
                    <td className="table-data">Custom CSS to style the loader component.</td>
                  </tr>
                  <tr className="table-row">
                    <td className="table-data">ariaLabel</td>
                    <td className="table-data">string</td>
                    <td className="table-data">'Loading'</td>
                    <td className="table-data">This prop is to set the aria label for the loader.</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div> 

        {/* Snippet code section */}
        <div className="section-C-Container section-div">
            <p>Using this component in any page, full code snippet.</p><br/>
            <CodeSnippet heading="Snipet Code">{snipet_code3}</CodeSnippet>      
        </div>  
    </div> 

      {/* End */}

      {/* Skeleton */}
    <div className='Loader-container'>
        {/* Heading */}
        <div className='Header-section'>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'rgb(130, 149, 234)'}}>Skeleton</h3>
        </center>
         <div className="content-section section-div">
            <p>Skeleton is a versatile component designed to simulate the loading state of various UI elements using skeleton loaders. It supports both Material UI and Ant Design skeleton types, allowing customization of dimensions, variants, animations, and styles to suit different design requirements.</p>
            <div style={{marginLeft:'20px'}}>
            <br/>
            <ul>
                <li>Material UI Skeleton Variants:</li>
                <ul>
                  <li>text</li>
                  <li>rectangular</li>
                  <li>circular</li>
                  <li>rounded</li>
                </ul>
              </ul>
              <br/>
              <ul>
                <li>Ant Design Skeleton Variants:</li>
                <ul>
                  <li>Skeleton</li>
                  <li>Avatar</li>
                  <li>Button</li>
                  <li>Input</li>
                  <li>Image</li>
                  <li>Card</li>
                  <li>Paragraph</li>
                  <li>Title</li>
                  <li>Collapse</li>
                  <li>List</li>
                  <li>Table</li>
                  <li>Tag</li>
                  <li>Tabs</li>
                  <li>Space</li>
                  <li>Grid</li>
                  <li>Descriptions</li>
                  <li>Statistic</li>
                  <li>Comment</li>
                  <li>Menu</li>
                </ul>
              </ul>

            </div>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code4} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange4}>
                {code4}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div class="table-container section-div">
        <table class="styled-table">
          <thead class="table-head">
            <tr>
              <th class="table-header">Prop Name</th>
              <th class="table-header">Type</th>
              <th class="table-header">Default</th>
              <th class="table-header">Description</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr class="table-row">
              <td class="table-data">type</td>
              <td class="table-data">string</td>
              <td class="table-data">null</td>
              <td class="table-data">Specifies the type of skeleton loader. Possible values: 'material' or 'ant'. This prop is required.</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">SkeletonWidth</td>
              <td class="table-data">number</td>
              <td class="table-data">null</td>
              <td class="table-data">The width of the skeleton loader.</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">SkeletonHeight</td>
              <td class="table-data">number</td>
              <td class="table-data">null</td>
              <td class="table-data">The height of the skeleton loader.</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">SkeletonVariant</td>
              <td class="table-data">string</td>
              <td class="table-data">null</td>
              <td class="table-data">The variant of the skeleton loader. For Material UI, valid variants are 'text', 'rectangular', 'circular', 'rounded'. For Ant Design, valid variants include 'Skeleton', 'Avatar', 'Button', 'Input', 'Image', ...</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">SkeletonAnimation</td>
              <td class="table-data">string</td>
              <td class="table-data">-</td>
              <td class="table-data">The animation type of the skeleton loader. For Material UI, valid animations are 'wave' or 'pulse'. For Ant Design, set to true for an active animation.</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">SkeletonActive</td>
              <td class="table-data">boolean</td>
              <td class="table-data">false</td>
              <td class="table-data">Specifies whether the skeleton loader is active. Applicable only for Ant Design.</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">SkeletonColor</td>
              <td class="table-data">string</td>
              <td class="table-data">null</td>
              <td class="table-data">The color of the skeleton loader. Applicable only for Material UI.</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">SkeletonHighlightColor</td>
              <td class="table-data">string</td>
              <td class="table-data">null</td>
              <td class="table-data">The highlight color of the skeleton loader. Applicable only for Material UI.</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">SkeletonStyle</td>
              <td class="table-data">object</td>
              <td class="table-data">null</td>
              <td class="table-data">Additional CSS styles to be applied to the skeleton loader.</td>
            </tr>
            <tr class="table-row">
              <td class="table-data">...props</td>
              <td class="table-data">object</td>
              <td class="table-data">null</td>
              <td class="table-data">Additional props to be passed to the underlying skeleton component.</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code4}</CodeSnippet>      
      </div>    
      
    </div> 

      {/* End */}
    </div>
  )
}

export default LoaderPage