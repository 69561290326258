import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAlert = styled.div`
  position: relative;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: ${({ fontSize }) => fontSize || '16px'}!important;
  font-family: ${({ fontFamily }) => fontFamily || 'Arial, sans-serif'};
  background-color: ${({ variant }) => {
    switch (variant) {
      case 'success':
        return '#d4edda';
      case 'warning':
        return '#fff3cd';
      case 'error':
        return '#f8d7da';
      default:
        return '#f8f9fa';
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case 'success':
        return '#155724';
      case 'warning':
        return '#856404';
      case 'error':
        return '#721c24';
      default:
        return '#000';
    }
  }};
`;

const Icon = styled.span`
  margin-right: 10px;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

const AlertMessage = ({ message, icon, variant, fontSize, fontFamily }) => {
  const [closed, setClosed] = useState(false);

  const handleClose = () => {
    setClosed(true);
  };

  return !closed ? (
    <StyledAlert variant={variant} fontSize={fontSize} fontFamily={fontFamily} tabIndex={0} aria-label='alert Message'>
      {icon && <Icon>{icon}</Icon>}
      {message}
      <CloseButton onClick={handleClose}>&times;</CloseButton>
    </StyledAlert>
  ) : null;
};

AlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.node, 
  variant: PropTypes.oneOf(['success', 'warning', 'error']),
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
};

export default AlertMessage;
