import React from 'react';
import styled from 'styled-components';

// Styled components
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding:${props=>`${props.PaginationPadding || '1px'}`};
  background:${props=>`${props.PaginationBgColor || 'inherit'}`};
  border:${props=>`${props.PaginationBorder || 'none'}`};
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: ${props => props.active ? props.activeBgColor || '#007bff' : '#fff'};
  color: ${props => props.active ? props.activeTextColor || '#fff' : '#007bff'};
  border:${props=>`${props.ButtonBorder || 'none'}`};
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: ${props => props.hoverActiveBgColor || '#007bff'};
    color: ${props => props.hoverActiveTextColor || '#fff'};
  }
`;

// Pagination component
const Pagination = ({ currentPage, totalPages, onPageChange,...props }) => {
  const handleClick = (page) => {
    onPageChange(page);
  };

  const renderPaginationButtons = () => {
    const numToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(numToShow / 2));
    const endPage = Math.min(startPage + numToShow - 1, totalPages);

    const renderPageButtons = () => {
      const buttons = [];
      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <PaginationButton {...props} key={i} active={i === currentPage} onClick={() => handleClick(i)}>
            {i}
          </PaginationButton>
        );
      }
      return buttons;
    };

    return (
      <>
        {currentPage > 1 && (
          <PaginationButton {...props} onClick={() => handleClick(currentPage - 1)}>Prev</PaginationButton>
        )}
        {renderPageButtons()}
        {endPage < totalPages && (
          <PaginationButton {...props} onClick={() => handleClick(endPage + 1)}>...</PaginationButton>
        )}
        {currentPage < totalPages && (
          <PaginationButton {...props} onClick={() => handleClick(currentPage + 1)}>Next</PaginationButton>
        )}
      </>
    );
  };

  return (
    <PaginationContainer {...props} tabIndex={0} aria-label= 'Pagination'>
      {renderPaginationButtons()}
    </PaginationContainer>
  );
};

export default Pagination;
