import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';

const TooltipsAndPopoverPage = () => {


  const initialCode = `
       <h3>Tooltips example</h3>
       <div style={{display:'flex',flexDirection:'colomn',justifyContent:'center',alignItems:'center',gap:'2rem'}}>

       <Tooltip
        message="Short message"
        position="top">
       <Button >Hover me too</Button>
      </Tooltip>

      <Tooltip 
      message="This is a much longer message." 
      position="right">
        <Button >Hover me too</Button>
      </Tooltip>

      <Tooltip 
      message="Another tooltip with left positioning" 
      position="left">
        <Button >Hover me left</Button>
      </Tooltip>

    </div>

    <br/>
       
    <h3>Popover example</h3>
       <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',gap:'2rem'}}>

       <Popover
        message="Top Popover" 
        position="top">
        <Button >Top</Button>
      </Popover>

      <Popover 
      message="Top Left Popover" 
      position="top-left">
        <Button >Top Left</Button>
      </Popover>

      <Popover 
      message="Top Right Popover" 
      position="top-right">
        <Button >Top Right</Button>
      </Popover>
      </div>
  `;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  

  const snipet_code = `
  import React from 'react';
import Popover from './customComponents/Tooltips/Popover';
import Tooltip from './customComponents/Tooltips/Tooltips';

const App = () => {

  
return (
    <>
    <h3>Tooltips example</h3>
       <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',gap:'2rem'}}>
       <Tooltip
        message="Short message"
        position="top">
       <button>Hover me too</button>
      </Tooltip>
      <Tooltip 
      message="This is a much longer message that should dynamically adjust the tooltip width and positioning accordingly." 
      position="bottom">
        <button>Hover me too</button>
      </Tooltip>
      <Tooltip 
      message="Another tooltip with left positioning" 
      position="left">
        <button>Hover me left</button>
      </Tooltip>
      <Tooltip 
      message="Another tooltip with right positioning" 
      position="right">
        <button>Hover me right</button>
      </Tooltip>
      <Tooltip 
      message="Another tooltip with bottom-left positioning" 
      position="bottom-left">
        <button>Hover me bottom-left</button>
      </Tooltip>
       </div>

       <br/>

    <h3>Popover example</h3>
       <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',gap:'2rem'}}>

       <Popover
        message="Top Popover" 
        position="top">
        <button>Top</button>
      </Popover>

      <Popover 
      message="Top Left Popover" 
      position="top-left">
        <button>Top Left</button>
      </Popover>

      <Popover 
      message="Top Right Popover" 
      position="top-right">
        <button>Top Right</button>
      </Popover>

      <Popover 
      message="Bottom Popover" 
      position="bottom">
        <button>Bottom</button>
      </Popover>

      <Popover 
      message="Bottom Left Popover" 
      position="bottom-left">
        <button>Bottom Left</button>
      </Popover>
      </div>
    </>
);
};

export default App;


  `;

        // State to track whether initial highlight should be shown
  const [showInitialHighlight, setShowInitialHighlight] = useState(true);

  // Function to handle hover effect (remove initial highlight)
  const handleMouseEnter = () => {
    setShowInitialHighlight(true);
  };

  // Function to handle leaving hover effect (restore initial highlight if needed)
  const handleMouseLeave = () => {
    setShowInitialHighlight(false);
  }; 

  return (
    <div className="wrapper-container">
       <ScrollButton/>
      {/* Heading */}
      <div className='Header-section'>
        <center>
           <h2>Tooltips and Popover</h2>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>Tooltips and popovers are UI components commonly used to provide additional information or context to users when interacting with elements on a webpage or application. Tooltips typically appear when users hover over or click on an element, offering brief explanations or hints. Popovers, on the other hand, are larger and more interactive, often containing additional content such as forms, images, or links, and are triggered by specific user actions like clicks or taps.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

       <h3>Tooltip Props Details</h3>
       <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">Prop Name</th>
                <th className="table-header">Type</th>
                <th className="table-header">Default</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">message</td>
                <td className="table-data">String</td>
                <td className="table-data">-</td>
                <td className="table-data">The text message to be displayed in the tooltip.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">children</td>
                <td className="table-data">ReactNode</td>
                <td className="table-data">-</td>
                <td className="table-data">The content to which the tooltip is attached.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">position</td>
                <td className="table-data">String</td>
                <td className="table-data">'top'</td>
                <td className="table-data">The position of the tooltip relative to its parent element. Possible values: 'top', 'top-left', 'top-right', 'bottom', 'bottom-left', 'bottom-right', 'left', 'left-top', 'left-bottom', 'right', 'right-top', 'right-bottom'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">messageFontFamily</td>
                <td className="table-data">String</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">The font family of the tooltip message.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">messageFontSize</td>
                <td className="table-data">String</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">The font size of the tooltip message.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">messageFontWeight</td>
                <td className="table-data">String</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">The font weight of the tooltip message.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">messageBgColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'blue'</td>
                <td className="table-data">The background color of the tooltip.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">messageTextColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'white'</td>
                <td className="table-data">The text color of the tooltip message.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">messageWidth</td>
                <td className="table-data">String</td>
                <td className="table-data">'120px'</td>
                <td className="table-data">The width of the tooltip.</td>
              </tr>
            </tbody>
          </table>
        </div>


        <br/>

        <h3>Popover Props Details</h3>
        <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop Name</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Default</th>
                  <th className="table-header">Description</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">message</td>
                  <td className="table-data">String</td>
                  <td className="table-data">-</td>
                  <td className="table-data">The text message to be displayed in the popover.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">children</td>
                  <td className="table-data">ReactNode</td>
                  <td className="table-data">-</td>
                  <td className="table-data">The content to which the popover is attached.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">position</td>
                  <td className="table-data">String</td>
                  <td className="table-data">'top'</td>
                  <td className="table-data">The position of the popover relative to its parent element. Possible values: 'top', 'top-left', 'top-right', 'bottom', 'bottom-left', 'bottom-right', 'left', 'right'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">messageFontFamily</td>
                  <td className="table-data">String</td>
                  <td className="table-data">'inherit'</td>
                  <td className="table-data">The font family of the popover message.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">messageFontSize</td>
                  <td className="table-data">String</td>
                  <td className="table-data">'inherit'</td>
                  <td className="table-data">The font size of the popover message.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">messageFontWeight</td>
                  <td className="table-data">String</td>
                  <td className="table-data">'inherit'</td>
                  <td className="table-data">The font weight of the popover message.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">messageBgColor</td>
                  <td className="table-data">String</td>
                  <td className="table-data">'blue'</td>
                  <td className="table-data">The background color of the popover.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">messageTextColor</td>
                  <td className="table-data">String</td>
                  <td className="table-data">'#fff'</td>
                  <td className="table-data">The text color of the popover message.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">messageWidth</td>
                  <td className="table-data">String</td>
                  <td className="table-data">'120px'</td>
                  <td className="table-data">The width of the popover.</td>
                </tr>
              </tbody>
            </table>
          </div>
      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
      </div>   
    </div>
  )
}

export default TooltipsAndPopoverPage