import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import AutoPlayVideo from '../../customComponents/AutoPlayVideo/AutoPlayVideo';
import Video from '../Components/Video/NavBarVideo.mp4';
import ScrollButton from '../Get-Started/ScrollButton';

const NavigationPage = () => {

  
// BreadCrumb------
  const initialCode = `
  <BreadCrumbs/>`;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  

  const snipet_code = `
   import React from 'react';
    import BreadCrumbs from './customComponents/Navigations/BreadCrumbs';

    const App = () => {
        return (
            <>
                <BreadCrumbs/>
          </>    
        );
    };

    export default App;

  `;

  const snipet_code_Router=`
  import React from 'react';
  import ReactDOM from 'react-dom/client';
  import {BrowserRouter as Router} from 'react-router-dom'

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <Router>
      <App />
      </Router>
      
    </React.StrictMode>
  );
  `;
// End--------------------

// Pagination------
const initialCode_pagi = `
<Pagination
  currentPage={3}
  totalPages={20}
  onPageChange={handlePageChange}
  PaginationPadding="10px"
  PaginationBgColor="#f0f0f0"
  PaginationBorder="1px solid #ccc"
  ButtonBorder="1px solid #ccc"
  activeBgColor="#28a745"
  activeTextColor="#fff"
  hoverActiveBgColor="#218838"
  hoverActiveTextColor="#fff"
/>
`;

const [code_pagi, setCode_pagi] = useState(initialCode_pagi);
const handleCodeChange_pagi = (newCode) => {
  setCode_pagi(newCode);
};



const snipet_code_pagi = `
import React,{useState} from 'react';
import Pagination from './customComponents/Navigations/Pagination';

const App = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10; 

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
    return (
        <>
             <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              PaginationPadding="10px"
              PaginationBgColor="#f0f0f0"
              PaginationBorder="1px solid #ccc"
              ButtonBorder="1px solid #ccc"
              activeBgColor="#28a745"
              activeTextColor="#fff"
              hoverActiveBgColor="#218838"
              hoverActiveTextColor="#fff"
            />
             
       </>    
    );
};

export default App;
`;
// End--------------------

// Tabs------

const tabsData = [
      { label: 'Tab 1', content: `Content for Tab 1` },
      { label: 'Tab 2', content: `Content for Tab 2` },  
      { label: 'Tab 3', content: `Content for Tab 3` }, 
      { label: 'Tab 4', content: `Content for Tab 4` }, 
      { label: 'Tab 5', content: `Content for Tab 5` }, 
      { label: 'Tab 6', content: `Content for Tab 6` }, 
      { label: 'Tab 7', content: `Content for Tab 7` }, 
      { label: 'Tab 8', content: `Content for Tab 8` }, 
      { label: 'Tab 9', content: `Content for Tab 9` }, 
      { label: 'Tab 10', content: `Content for Tab 10` },
    ];
  
   
const initialCode_tab = `
 <h1>Tabs Component Example</h1>
 <br/>
<Tabs
tabs={${JSON.stringify(tabsData)}}
visibleTabCount={5}
fontfamily="Verdana"
width="90%"
height="300px"
TabHeadercustomcss={{ justifyContent: 'flex-start', alignItems: 'center' }}
/>
`;

const [code_tab, setCode_tab] = useState(initialCode_tab);
const handleCodeChange_tab = (newCode) => {
  setCode_tab(newCode);
};



const snipet_code_tab = `
import React from 'react';

import Tabs from './customComponents/Navigations/Tabs';

 

const App = () => {

  const tabsData =[
      { label: 'Tab 1', content: 'Content for Tab 1' },
      { label: 'Tab 2', content: 'Content for Tab 2' },  
      { label: 'Tab 3', content: 'Content for Tab 3' }, 
      { label: 'Tab 4', content: 'Content for Tab 4' }, 
      { label: 'Tab 5', content: 'Content for Tab 5' }, 
      { label: 'Tab 6', content: 'Content for Tab 6' }, 
      { label: 'Tab 7', content: 'Content for Tab 7' }, 
      { label: 'Tab 8', content: 'Content for Tab 8' }, 
      { label: 'Tab 9', content: 'Content for Tab 9' }, 
      { label: 'Tab 10', content: 'Content for Tab 10' },
    ];
  return (
    <div>
      <h1>Tabs Component Example</h1>
      <Tabs
        tabs={tabsData}
        visibleTabCount={5}
        fontfamily="Verdana"
        width="90%"
        height="300px"
        buttonbgcolor="#f0f0f0"
        buttoncolor="#007bff"
        buttonfontsize="18px"
        buttonfontFamily="Verdana"
        buttonfontWeight="bold"
        buttonhoverbgcolor="#e0e0e0"
        arrowbgcolor="#f0f0f0"
        arrowcolor="#007bff"
        arrowfontsize="18px"
        arrowfontFamily="Verdana"
        arrowfontWeight="bold"
        arrowhoverbgcolor="#e0e0e0"
        contentbgcolor="#f9f9f9"
        contentcolor="#333"
        contentfontsize="18px"
        contentfontFamily="Verdana"
        contentfontWeight="normal"
        TabHeadercustomcss={{ justifyContent: 'flex-start', alignItems: 'center' }}
      />
    </div>
  );
};
export default App;
`;
// End--------------------


// Navbar------
const menuItems = [
  { label: 'Home', link: '/',element:'', active: true },
  { label: 'About', link: '/about',element:'', active: false },
  { label: 'Services', link: '/services',element:'', active: false },
  { label: 'Contact', link: '/contact',element:'', active: false }
];




const snipet_code_nav = `
import React from 'react';
import Navbar from './customComponents/Navigations/Navbar';
import { element } from 'prop-types';

const App = () => {
  // Define your menu items
  const menuItems = [
    { label: 'Home', link: '/',element:'', active: true },
    { label: 'About', link: '/about',element:'', active: false },
    { label: 'Services', link: '/services',element:'', active: false },
    { label: 'Contact', link: '/contact',element:'', active: false }
  ];
  // Optionally, define a logo
  const logo = (
    <img
      src="https://media.istockphoto.com/id/1331491686/vector/element-design.jpg?s=612x612&w=0&k=20&c=QIMRS2IPiQyyTZJR_G1JAjH_ErBBkrDPtQe2GBNgm2w="
      alt="Logo"
    />
  );
  return (
      <>
        <Navbar 
          menuItems={menuItems} 
          logo={logo} 
          navbarbgColor="black"
          NavbartextColor="white"
          NavbarFontFamily="Arial, sans-serif"
          NavbarFontSize="1.5rem"
          NavbartextDecoration="none"
          HoverBgColor="aqua"
          HovertextColor="aqua"
          sideBarwidth="300px"
          sideBarBgColor="black"
        />
      </>
  );
};
export default App;
`;
// End--------------------
   
     // State to track whether initial highlight should be shown
     const [showInitialHighlight, setShowInitialHighlight] = useState(true);

     // Function to handle hover effect (remove initial highlight)
     const handleMouseEnter = () => {
       setShowInitialHighlight(true);
     };
   
     // Function to handle leaving hover effect (restore initial highlight if needed)
     const handleMouseLeave = () => {
       setShowInitialHighlight(false);
     };
   

  return (
    <div className="wrapper-container">
       <ScrollButton/>
      <div>
          {/* Heading */}
      <div className='Header-section'>
      <center>
           <h2>Navigation</h2>
        </center>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>BreadCrumbs</h3>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>A BreadCrumbs component displays the user's navigation path within an application, enhancing usability by providing context and allowing easy navigation back to previous pages. It typically includes a series of links or items separated by a character such as a slash or arrow.</p>
         </div>


         <h3>Example</h3>
         <p>Here is an example of how to use the BreadCrumbs component in a React application:</p>
         <p>Firstly, in the index.js file import the BrowserRouter as Router and then wrap the <app/> with {`<Router>`} for using all navigation components as given below:</p>
         <br/>
         <CodeSnippet heading="Snipet Code">{snipet_code_Router}</CodeSnippet> 
      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <h3>BreadCrumb Props Details</h3>
      <p>The BreadCrumbs component accepts several props to customize its appearance and behavior. Here are the details:</p>

      <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr>
                <th className="table-header">Prop</th>
                <th className="table-header">Type</th>
                <th className="table-header">Default</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">className</td>
                <td className="table-data">string</td>
                <td className="table-data">-</td>
                <td className="table-data">A class name to be passed for styling the BreadCrumbs component. Used internally by styled-components.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">id</td>
                <td className="table-data">string</td>
                <td className="table-data">-</td>
                <td className="table-data">An id to uniquely identify the BreadCrumbs component.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">BreadCrumbsBgColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'FFF5F8'</td>
                <td className="table-data">Sets the background color of the BreadCrumbsDiv container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">BreadCrumbsBorder</td>
                <td className="table-data">string</td>
                <td className="table-data">'1px solid black'</td>
                <td className="table-data">Sets the border of the BreadCrumbsDiv container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">BreadCrumbsBorderRadius</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px'</td>
                <td className="table-data">Sets the border-radius of the BreadCrumbsDiv container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SeparatorColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'blue'</td>
                <td className="table-data">Sets the color of the Separator.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SeparatorFontSize</td>
                <td className="table-data">string</td>
                <td className="table-data">'16px'</td>
                <td className="table-data">Sets the font size of the Separator.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">linkTextDecoration</td>
                <td className="table-data">string</td>
                <td className="table-data">'none'</td>
                <td className="table-data">Sets the text-decoration of the CrumbLink.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">linkTextColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'blue'</td>
                <td className="table-data">Sets the color of the CrumbLink.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">linkFontSize</td>
                <td className="table-data">string</td>
                <td className="table-data">'16px'</td>
                <td className="table-data">Sets the font size of the CrumbLink.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">linkPadding</td>
                <td className="table-data">string</td>
                <td className="table-data">'none'</td>
                <td className="table-data">Sets the padding of the CrumbLink.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">hoverlinkTextDecoration</td>
                <td className="table-data">string</td>
                <td className="table-data">'underline'</td>
                <td className="table-data">Sets the text-decoration of the CrumbLink on hover.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">linkfontFamily</td>
                <td className="table-data">string</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Sets the font family of the CrumbLink.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">linkfontWeight</td>
                <td className="table-data">string</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Sets the font weight of the CrumbLink.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">linklineHeight</td>
                <td className="table-data">string</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Sets the line height of the CrumbLink.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 

      {/* Snippet code section */}
      <div className="section-C-Containersection-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
      </div> 
      </div>  


      <div>
          {/* Heading */}
      <div className='Header-section'>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'rgb(130, 149, 234)'}}>Pagination</h3>
        </center>
         <div className="content section section-div">
            <p>Pagination is a process used in applications to divide large sets of data into manageable chunks or pages. It enhances user experience by breaking content into smaller, easily navigable segments, reducing load times, and improving data handling. Users can navigate through pages via navigation controls.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code_pagi} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange_pagi}>
                {code_pagi}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>The Pagination component also accepts various CSS props for customization. Here is a detailed list of these CSS props:</p>

      <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr>
                <th className="table-header">Prop Name</th>
                <th className="table-header">Type</th>
                <th className="table-header">Default</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">PaginationPadding</td>
                <td className="table-data">String</td>
                <td className="table-data">'1px'</td>
                <td className="table-data">Padding for the pagination container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">PaginationBgColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Background color for the pagination container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">PaginationBorder</td>
                <td className="table-data">String</td>
                <td className="table-data">'none'</td>
                <td className="table-data">Border for the pagination container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">ButtonBorder</td>
                <td className="table-data">String</td>
                <td className="table-data">'none'</td>
                <td className="table-data">Border for the pagination buttons.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">activeBgColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'#007bff'</td>
                <td className="table-data">Background color for the active page button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">activeTextColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'#fff'</td>
                <td className="table-data">Text color for the active page button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">hoverActiveBgColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'#007bff'</td>
                <td className="table-data">Background color for the button when hovered.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">hoverActiveTextColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'#fff'</td>
                <td className="table-data">Text color for the button when hovered.</td>
              </tr>
            </tbody>
          </table>
        </div>


      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code_pagi}</CodeSnippet>      
      </div> 
      </div>

      <div>
          {/* Heading */}
      <div className='Header-section'>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'rgb(130, 149, 234)'}}>Tabs</h3>
        </center>
         <div className="content section section-div">
            <p>Tabs are a UI component that allows users to navigate between different content sections within the same page. Each tab is associated with a specific content panel, and clicking on a tab displays its corresponding panel while hiding others. This enhances user experience by organizing related content in a compact, accessible manner.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code_tab} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange_tab}>
                {code_tab}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">Prop Name</th>
                <th className="table-header">Type</th>
                <th className="table-header">Default</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">fontfamily</td>
                <td className="table-data">string</td>
                <td className="table-data">'Arial'</td>
                <td className="table-data">Sets the font family for the TabWrapper.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TabContainerBorder</td>
                <td className="table-data">string</td>
                <td className="table-data">'1px solid transparent'</td>
                <td className="table-data">Sets the border for the TabWrapper container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TabContainerBorderRadius</td>
                <td className="table-data">string</td>
                <td className="table-data">'0'</td>
                <td className="table-data">Sets the border radius for the TabWrapper container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">width</td>
                <td className="table-data">string</td>
                <td className="table-data">'80%'</td>
                <td className="table-data">Sets the minimum width for the TabWrapper container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">height</td>
                <td className="table-data">string</td>
                <td className="table-data">'200px'</td>
                <td className="table-data">Sets the minimum height for the TabWrapper container.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TabHeaderBorderBottom</td>
                <td className="table-data">string</td>
                <td className="table-data">'1px solid transparent'</td>
                <td className="table-data">Sets the bottom border for the TabHeader.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">buttoncolor</td>
                <td className="table-data">string</td>
                <td className="table-data">'#000000'</td>
                <td className="table-data">Sets the text color for non-active TabButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">buttonfontsize</td>
                <td className="table-data">string</td>
                <td className="table-data">'14px'</td>
                <td className="table-data">Sets the font size for TabButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">buttonfontFamily</td>
                <td className="table-data">string</td>
                <td className="table-data">'sans-serif'</td>
                <td className="table-data">Sets the font family for TabButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">buttonfontWeight</td>
                <td className="table-data">string</td>
                <td className="table-data">'normal'</td>
                <td className="table-data">Sets the font weight for TabButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">buttonPadding</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px'</td>
                <td className="table-data">Sets the padding for TabButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">buttonHeight</td>
                <td className="table-data">string</td>
                <td className="table-data">'auto'</td>
                <td className="table-data">Sets the height for TabButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">buttonWidth</td>
                <td className="table-data">string</td>
                <td className="table-data">'80px'</td>
                <td className="table-data">Sets the width for TabButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">arrowbgcolor</td>
                <td className="table-data">string</td>
                <td className="table-data">'transparent'</td>
                <td className="table-data">Sets the background color for ArrowButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">arrowcolor</td>
                <td className="table-data">string</td>
                <td className="table-data">'#000000'</td>
                <td className="table-data">Sets the text color for ArrowButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">arrowfontsize</td>
                <td className="table-data">string</td>
                <td className="table-data">'14px'</td>
                <td className="table-data">Sets the font size for ArrowButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">arrowfontFamily</td>
                <td className="table-data">string</td>
                <td className="table-data">'sans-serif'</td>
                <td className="table-data">Sets the font family for ArrowButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">arrowfontWeight</td>
                <td className="table-data">string</td>
                <td className="table-data">'normal'</td>
                <td className="table-data">Sets the font weight for ArrowButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">arrowPadding</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px 5px'</td>
                <td className="table-data">Sets the padding for ArrowButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentMargin</td>
                <td className="table-data">string</td>
                <td className="table-data">'10px 0px'</td>
                <td className="table-data">Sets the margin for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentPadding</td>
                <td className="table-data">string</td>
                <td className="table-data">'10px'</td>
                <td className="table-data">Sets the padding for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentbgcolor</td>
                <td className="table-data">string</td>
                <td className="table-data">'#ffffff'</td>
                <td className="table-data">Sets the background color for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentcolor</td>
                <td className="table-data">string</td>
                <td className="table-data">'#000000'</td>
                <td className="table-data">Sets the text color for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentfontsize</td>
                <td className="table-data">string</td>
                <td className="table-data">'14px'</td>
                <td className="table-data">Sets the font size for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentfontFamily</td>
                <td className="table-data">string</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Sets the font family for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentfontWeight</td>
                <td className="table-data">string</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Sets the font weight for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentHeight</td>
                <td className="table-data">string</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Sets the height for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentWidth</td>
                <td className="table-data">string</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Sets the width for TabContent.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TabHeadercustomcss</td>
                <td className="table-data">object</td>
                <td className="table-data">-</td>
                <td className="table-data">Custom CSS object to style the TabHeader.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TabArraycustomcss</td>
                <td className="table-data">object</td>
                <td className="table-data">-</td>
                <td className="table-data">Custom CSS object to style the ArrowButton.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TabContentcustomcss</td>
                <td className="table-data">object</td>
                <td className="table-data">-</td>
                <td className="table-data">Custom CSS object to style the TabContent.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code_tab}</CodeSnippet>      
      </div> 
      </div>

      <div>
          {/* Heading */}
      <div className='Header-section'>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'rgb(130, 149, 234)'}}>Navbar</h3>
        </center>
         <div className="content section section-div">
            <p>The Navbar component is a customizable navigation bar built using React and styled-components. It features a responsive design with a toggleable hamburger menu for mobile devices. Users can navigate between different routes using the provided menu items, and the active route is highlighted dynamically. It offers flexibility in logo placement, menu item configuration, and styling options, making it suitable for a wide range of web applications.</p>
         </div>

      </div>

   

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Demo Video of Navbar.</p>
        <AutoPlayVideo src={Video} width={840} height={360} />
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <h3>Navbar Props Details</h3>     

      <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">Prop Name</th>
                <th className="table-header">Type</th>
                <th className="table-header">Default</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">menuItems</td>
                <td className="table-data">Array</td>
                <td className="table-data">-</td>
                <td className="table-data">Array of objects containing label, link, and optionally active boolean for each menu item.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">logo</td>
                <td className="table-data">React Element or String</td>
                <td className="table-data">-</td>
                <td className="table-data">Logo to be displayed on the left side of the navbar.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">NavbarBgColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'#1b2430'</td>
                <td className="table-data">Background color of the navbar.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">NavbartextColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'white'</td>
                <td className="table-data">Text color of the navbar.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">NavbarFontFamily</td>
                <td className="table-data">String</td>
                <td className="table-data">'inherit'</td>
                <td className="table-data">Font family of the navbar text.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">NavbarFontSize</td>
                <td className="table-data">String</td>
                <td className="table-data">'1.5rem'</td>
                <td className="table-data">Font size of the navbar text.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">NavbartextDecoration</td>
                <td className="table-data">String</td>
                <td className="table-data">'none'</td>
                <td className="table-data">Text decoration for navbar links.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">HoverBgColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'aqua'</td>
                <td className="table-data">Background color of navbar links on hover.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">HovertextColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'aqua'</td>
                <td className="table-data">Text color of navbar links on hover.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">sideBarBgColor</td>
                <td className="table-data">String</td>
                <td className="table-data">'#2a3239'</td>
                <td className="table-data">Background color of the sidebar for mobile devices.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">sideBarwidth</td>
                <td className="table-data">String</td>
                <td className="table-data">'300px'</td>
                <td className="table-data">Width of the sidebar for mobile devices.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">sideBarTop</td>
                <td className="table-data">String</td>
                <td className="table-data">'63px'</td>
                <td className="table-data">Top position of the sidebar for mobile devices.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code_nav}</CodeSnippet>      
      </div> 
      </div>

    </div>
  )
}

export default NavigationPage