import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FontAsIcons from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import * as MUIcons from '@mui/icons-material';
import * as AntIcons from '@ant-design/icons';

const Icon = ({ IconName, IconType, IconfontSize, IconColor,id,IconBgColor, className,IconCustomCss, ...props }) => {
  let StyledIcon;

  if (IconType === 'material') {
    StyledIcon = styled(MUIcons[IconName])`
        font-size: ${IconfontSize}!important;
        color: ${IconColor}!important;
        background-color:${IconBgColor}!important;
        ${IconCustomCss}
    `;
  } else if (IconType === 'ant') {
    StyledIcon = styled(AntIcons[IconName])`
        font-size: ${IconfontSize}!important;
        color: ${IconColor}!important;
        background-color:${IconBgColor}!important;
        ${IconCustomCss}
    `;
  } else if (IconType === 'fontawesome') {
    const FontIcon = FontAsIcons[IconName];
    StyledIcon = styled(FontAwesomeIcon)`
          font-size: ${IconfontSize}!important;
          color: ${IconColor}!important;
          background-color:${IconBgColor}!important;
          ${IconCustomCss}
    `;
    return <StyledIcon icon={FontIcon} className={className} id={id}/>;
  } else {
    return null; 
  }

  return <StyledIcon className={className}  id={id}/>;
};

export default Icon;
