import React, { useState } from 'react';
import styled from 'styled-components';

const TabWrapper = styled.div`
  font-family: ${(props) => props.fontfamily || 'Arial'};
  border: ${(props) => props.TabContainerBorder || '1px solid transparent'}; 
  border-radius: ${(props) => props.TabContainerBorderRadius || '0'};
  overflow: hidden;
  min-width: ${(props) => props.width || '80%'};
  min-height: ${(props) => props.height || '200px'};
`;

const TabHeader = styled.div`
  display: flex;
  border-bottom: ${(props) => props.TabHeaderBorderBottom || '1px solid transparent'};
  align-items: center;
  justify-content: flex-start;
  padding:-2px;
`;

const TabButton = styled.button`
  background-color: ${(props) => (props.active ? 'white' : '#F3F3F3')};
  color:${(props) => (props.active ? 'blue' : props.buttoncolor)};
  font-size: ${(props) => props.buttonfontsize || '14px'};
  font-family: ${(props) => props.buttonfontFamily || 'sans-serif'};
  font-weight: ${(props) => props.buttonfontWeight || 'normal'};
  border: 1px solid lightgray;
  border-bottom: ${(props) => (props.active ? 'none' : '1px solid lightgray')};
  border-left: ${(props) => (props.active ? 'none' : '1px solid lightgray')};
  border-right: ${(props) => (props.active ? 'none' : '1px solid lightgray')};
  padding: ${(props) => props.buttonPadding || '5px'};
  height: ${(props) => props.buttonHeight || 'auto'};
  width: ${(props) => props.buttonWidth || '80px'};
  border-radius:2px;
  border-bottom-left-radius:${(props) => (props.active ? 'none' : '2px')};;
  border-bottom-right-radius:${(props) => (props.active ? 'none' : '2px')};;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  z-index:5;
  margin: 0px;
`;

const ArrowButton = styled.button`
  background-color: ${(props) => props.arrowbgcolor || 'transparent'};
  color: ${(props) => props.arrowcolor || '#000000'};
  font-size: ${(props) => props.arrowfontsize || '14px'};
  font-family: ${(props) => props.arrowfontFamily || 'sans-serif'};
  font-weight: ${(props) => props.arrowfontWeight || 'normal'};
  border:1px solid lightgray;
  padding: ${(props) => props.arrowPadding || '5px 5px'};
  cursor: pointer;
  outline: none;
`;

const TabContent = styled.div`
  margin: ${(props) => props.contentMargin || '10px 0px'}; 
  padding: ${(props) => props.contentPadding || '10px'};
  background-color: ${(props) => props.contentbgcolor || '#ffffff'};
  color: ${(props) => props.contentcolor || '#000000'};
  font-size: ${(props) => props.contentfontsize || '14px'};
  font-family: ${(props) => props.contentfontFamily || 'inherit'};
  font-weight: ${(props) => props.contentfontWeight || 'inherit'};
  height: ${(props) => props.contentHeight || 'inherit'};
  width: ${(props) => props.contentWidth || 'inherit'};
  min-height: 100px;
`;

const Tabs = ({ tabs, visibleTabCount, TabHeadercustomcss, TabArraycustomcss, TabContentcustomcss, ...props }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [visibleRange, setVisibleRange] = useState([0, visibleTabCount]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handlePrevClick = () => {
    setVisibleRange((prevRange) => {
      const start = Math.max(0, prevRange[0] - 1);
      const end = Math.min(tabs.length, start + visibleTabCount);
      return [start, end];
    });
  };

  const handleNextClick = () => {
    setVisibleRange((prevRange) => {
      const start = Math.min(tabs.length - visibleTabCount, prevRange[0] + 1);
      const end = Math.min(tabs.length, start + visibleTabCount);
      return [start, end];
    });
  };

  return (
    <TabWrapper {...props} tabIndex={0} aria-label="Tabs">
      <TabHeader style={TabHeadercustomcss} tabIndex={0} aria-label="Tabs Header" {...props}>
        <ArrowButton style={TabArraycustomcss} onClick={handlePrevClick} {...props}>
          {'<'}
        </ArrowButton>
        {tabs.slice(visibleRange[0], visibleRange[1]).map((tab, index) => (
          <TabButton
            key={index + visibleRange[0]}
            onClick={() => handleTabClick(index + visibleRange[0])}
            active={index + visibleRange[0] === activeTab}
            {...tab}
            {...props}
          >
            {tab.label}
          </TabButton>
        ))}
        <ArrowButton style={TabArraycustomcss} onClick={handleNextClick} {...props}>
          {'>'}
        </ArrowButton>
      </TabHeader>
      <TabContent style={TabContentcustomcss} {...props} tabIndex={0} aria-label="Tabs Content">
        {tabs[activeTab].content}
      </TabContent>
    </TabWrapper>
  );
};

export default Tabs;
