import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Typography from '../../customComponents/Typography/Typography';
import Icon from '../../customComponents/Icon/Icon';
import { Margin, Padding } from '@mui/icons-material';

const Home = () => {
  return (
    <WrapperContainer>
      <CenteredText>
        <Typography variant="h3" fontFamily='sans-serif' fontWeight='900' style={{textAlign:'center'}} animate>Atomic-Fusion is a component library</Typography>
      </CenteredText>
      <ContentWrapper>
        <AnimatedText>
          <p style={{fontFamily:'sans-serif',fontWeight:'500',fontSize:'1.4rem',color:'black',lineHeight:'28px'}}>Beautifully designed components that you can use your apps from this library and you can use the components by passing it's props</p>
        </AnimatedText>
      </ContentWrapper>
      <NavContainer>
        <NavButton to="/Get-Started/Overview" isLeft>
        <Icon  IconType='material' IconName="PlayCircleOutline"/>
        Get Started
        </NavButton>

        <NavButton to="/component">
        <Icon IconType='material' IconName="AppRegistrationOutlined"/>
        Component
        </NavButton>
      </NavContainer>
    </WrapperContainer>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
`;

const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  min-height: 400px;
  margin: 200px auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 4px gray;
  background:linear-gradient(45deg, white, lightblue);
  overflow: hidden;
  opacity:0.8;

  @media (max-width: 864px) {
    width: 95%;
  }
`;

const CenteredText = styled.div`
  animation: ${fadeIn} 2s ease;
  text-align:center;
`;

const ContentWrapper = styled.div`
  padding:20px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  text-align:center;
`;

const AnimatedText = styled.div`
  animation: ${slideIn} 1s ease;
`;

const NavContainer= styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  gap:2rem;
  over-flow:hidden;
`;

const NavButton = styled(NavLink)`
  display:flex;
  align-items:center;
  justify-content:space-between;
  gap:0.2rem;
  padding-top:8px;
  padding-bottom:10px;
  padding-left:12px;
  padding-right:17px;
  box-sizing:border-box;
  height:2.5rem;
  background-color:black;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-decoration:none;
  font-family:sans-serif;
  position: relative;
  animation: ${({ isLeft }) => isLeft ? slideInLeft : slideInRight} 2s ease;

  &:hover {
    background-color: blue;
  }
`;

export default Home;
