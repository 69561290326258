import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

// Styled-components for the dropdown
const DropdownWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const DropdownHeader = styled.div`
  padding: 10px;
  border: ${(props) => `${props.border || '1px solid rgba(0, 123, 255, 0.25)'}`};
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const ArrowIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

const SelectedOption = styled.span`
  padding: 5px;
  margin-right: 5px;
  background-color: #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  cursor: pointer;
`;

const DropdownList = styled.ul`
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
`;

const DropdownListItem = styled.li`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.isFocused ? '#f0f0f0' : 'transparent')};

  &:hover {
    background-color: #f0f0f0;
  }
`;

const MultiSelect = ({ value, options, onChange, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const headerRef = useRef(null);
  const listRef = useRef(null);
  const [position, setPosition] = useState('below');

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
    setFocusedIndex(-1);
  };

  const handleSelectOption = (optionValue) => {
    const isSelected = value.includes(optionValue);
    let newSelectedValues = [...value];

    if (isSelected) {
      newSelectedValues = newSelectedValues.filter((val) => val !== optionValue);
    } else {
      newSelectedValues.push(optionValue);
    }

    onChange(newSelectedValues);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (dropdownRect.bottom + dropdownRect.height > windowHeight) {
        setPosition('above');
      } else {
        setPosition('below');
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && focusedIndex >= 0 && listRef.current) {
      const focusedItem = listRef.current.children[focusedIndex];
      if (focusedItem) {
        focusedItem.scrollIntoView({
          block: 'nearest'
        });
      }
    }
  }, [focusedIndex, isOpen]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Escape':
        setIsOpen(false);
        if (headerRef.current) {
          headerRef.current.focus();
        }
        break;
      case 'ArrowUp':
        if (isOpen) {
          event.preventDefault();
          navigateOptions(-1);
        }
        break;
      case 'ArrowDown':
        if (isOpen) {
          event.preventDefault();
          navigateOptions(1);
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (isOpen && focusedIndex >= 0) {
          handleSelectOption(options[focusedIndex].value);
        } else {
          setIsOpen(true);
          if (listRef.current) {
            listRef.current.focus();
          }
        }
        break;
      default:
        if (event.key >= '1' && event.key <= String(options.length)) {
          event.preventDefault();
          handleSelectOption(options[Number(event.key) - 1].value);
        }
        break;
    }
  };

  const navigateOptions = (step) => {
    if (!isOpen) {
      setIsOpen(true);
      setFocusedIndex(0);
      return;
    }
    setFocusedIndex((prevIndex) => {
      const newIndex = (prevIndex + step + options.length) % options.length;
      return newIndex;
    });
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownHeader
        ref={headerRef}
        onClick={handleToggleDropdown}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {value.length > 0 && value.map((selectedValue) => (
          <SelectedOption key={selectedValue}>
            {options.find((option) => option.value === selectedValue).label}
            <CloseIcon
              icon={faTimes}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectOption(selectedValue);
              }}
            />
          </SelectedOption>
        ))}
        <ArrowIcon><FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} /></ArrowIcon>
      </DropdownHeader>
      {isOpen && (
        <DropdownList ref={listRef} onKeyDown={handleKeyDown} tabIndex={0} position={position}>
          {options.map((option, index) => (
            <DropdownListItem
              key={option.value}
              onClick={() => handleSelectOption(option.value)}
              isFocused={focusedIndex === index}
            >
              {option.label}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

MultiSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

MultiSelect.defaultProps = {
  name: '',
};

export default MultiSelect;
