import React from 'react'
import './About.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../customComponents/Button/Button';
import './pricing.css';

const Pricing = () => {
  return (
    <div className='WrapperContainer'>
      <div className='Container'>
        <div className='row'>
        <div className='col-md-12 col-sm-12 col-xs-12 col-lg-2'></div>

          <div className='col-md-12 col-sm-12 col-xs-12 col-lg-4'>
          <div className='card price-card' style={{minHeight:'600px',background: 'linear-gradient(45deg,green,purple,gray)',padding:'50px',borderRadius:'50px',border:'1px solid gray',color:'white',opacity:0.9}}>
              <div className='card-header' style={{display:'flex',flexDirection:'column',gap:'1rem',justifyContent:'center',alignItems:'center',border:'none',background:'transparent'}}>
              <h3>Basic Plan</h3>
              <h4>$0/month</h4>
              </div>
              <div className='card-body' style={{display:'flex',flexDirection:'column',gap:'1rem',justifyContent:'center',alignItems:'center',color:'white'}}>
                <img src='' alt='' style={{minHeight:'100px',minWidth:'80px'}}/>
                <ul>
                            <li style={{color:'white'}}>10 GB Storage</li>
                            <li style={{color:'white'}}>100 GB Bandwidth</li>
                            <li style={{color:'white'}}>24/7 Support</li>
                            <li style={{color:'white'}}>Basic Analytics</li>
                  </ul>
              </div>
              <div className='card-footer' style={{display:'flex',flexDirection:'column',gap:'1rem',justifyContent:'center',alignItems:'center',border:'none',background:'transparent'}}>
              <Button variant="success">Sign Up</Button>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-sm-12 col-xs-12 col-lg-4'>
          <div className='card price-card' style={{minHeight:'600px',background: 'linear-gradient(45deg,green,purple,blue)',padding:'50px',borderRadius:'50px',border:'1px solid gray',color:'white',opacity:0.9}}>
              <div className='card-header' style={{display:'flex',flexDirection:'column',gap:'1rem',justifyContent:'center',alignItems:'center',border:'none',background:'transparent'}}>
              <h3>Premium Plan</h3>
              <h4>$10/month</h4>
              </div>
              <div className='card-body' style={{display:'flex',flexDirection:'column',gap:'1rem',justifyContent:'center',alignItems:'center',color:'white'}}>
                <img src='' alt='' style={{minHeight:'100px',minWidth:'80px'}}/>
                <ul>
                            <li style={{color:'white'}}>10 GB Storage</li>
                            <li style={{color:'white'}}>100 GB Bandwidth</li>
                            <li style={{color:'white'}}>24/7 Support</li>
                            <li style={{color:'white'}}>Basic Analytics</li>
                  </ul>
              </div>
              <div className='card-footer' style={{display:'flex',flexDirection:'column',gap:'1rem',justifyContent:'center',alignItems:'center',border:'none',background:'transparent'}}>
              <Button variant="secondary" disabled>Sign Up</Button>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-sm-12 col-xs-12 col-lg-2'></div>

        </div>
      </div>

      
    </div>
  )
}

export default Pricing