import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioGroupLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;

  @media (max-width: 864px) {
    flex-direction: column;
  }
`;

const RadioOption = styled.label`
  margin-bottom: 5px;
  font-size: 16px;
  position: relative;
`;

const StyledRadio = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 123, 255, 0.25);
  border-radius: 50%;
  transition: all 150ms;
`;

const SelectedCheckmark = styled(Checkmark)`
  background-color: blue;

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 2.7px;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
  }
`;

const OptionLabel = styled.span`
  margin-left: 30px; 
  padding: 5px;
`;

const ErrorMessage = styled.span`
  color: #ff0000;
  font-size: 0.8rem;
  padding: 0.6rem;
  margin: 5px 6px;
`;

const RadioInput = ({ name, options, value, onChange, label }) => {
  const [error, setError] = useState(false);
  const radioRefs = useRef([]);

  const handleChange = (option) => {
    setError(false);
    onChange(option);
  };

  const handleKeyDown = (e, index) => {
    switch (e.key) {
      case 'ArrowRight':
      case 'ArrowDown':
        if (index < options.length - 1) {
          radioRefs.current[index + 1].focus();
        } else {
          radioRefs.current[0].focus();
        }
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        if (index > 0) {
          radioRefs.current[index - 1].focus();
        } else {
          radioRefs.current[options.length - 1].focus();
        }
        break;
      case 'Enter':
      case ' ':
        handleChange(options[index]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    radioRefs.current = radioRefs.current.slice(0, options.length);
  }, [options.length]);

  return (
    <div>
      <RadioGroupLabel id={`${name}-label`}>{label}</RadioGroupLabel>
      <RadioWrapper role="radiogroup" aria-labelledby={`${name}-label`}>
        {options.map((option, index) => (
          <RadioOption key={option}>
            <StyledRadio
              type="radio"
              id={option}
              name={name}
              checked={value === option}
              onChange={() => handleChange(option)}
              value={option}
              aria-label={option}
              role="radio"
              tabIndex={0}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (radioRefs.current[index] = el)}
            />
            {value === option ? <SelectedCheckmark /> : <Checkmark />}
            <OptionLabel>{option}</OptionLabel>
          </RadioOption>
        ))}
      </RadioWrapper>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
  );
};

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default RadioInput;
