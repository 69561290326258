import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 999; 
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${props=> props.DialogBgColor || 'white'};
  color: ${props=> props.DialogTextColor || 'inherit'};
  font-family: ${props=> props.DialogFontFaily || 'inherit'};
  font-size: ${props=> props.DialogFontSize || 'inherit'};
  font-weight: ${props=> props.DialogFontWeight || 'inherit'};
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
`;


const AlertDialogs = ({ children, onClick, isOpen, ...props }) => {
  const [closed, setClosed] = useState(!isOpen);

  useEffect(() => {
    setClosed(!isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setClosed(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!closed && event.target === overlayRef.current) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [closed, onClick]);

  const overlayRef = React.useRef(null);

  return !closed ? (
    <Overlay ref={overlayRef}>
      <Content tabIndex={0} aria-label='dialog Box'>
        <div>{children}</div>
      </Content>
    </Overlay>
  ) : null;
};

AlertDialogs.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired, // Change to onClick
  isOpen: PropTypes.bool.isRequired,
};

export default AlertDialogs;
