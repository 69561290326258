import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const BreadCrumb = ({ className, seperatorIcon, id, ...props }) => {
  const location = useLocation();

  let currentLink = '';
  const crumbs = location.pathname.split('/')
    .filter(crumb => crumb !== '')
    .map((crumb, index, array) => {
      currentLink += `/${crumb}`;
      const isLast = index === array.length - 1;
      const capitalizedCrumb = crumb.replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
      return (
        <React.Fragment key={crumb}>
          <CrumbLink tabIndex={0} aria-label={`${crumb} link`} to={currentLink} {...props}>{capitalizedCrumb}</CrumbLink>
          {!isLast && <Separator>{seperatorIcon || '\u003E'}</Separator>}
        </React.Fragment>
      );
    });

  return (
    <BreadCrumbsDiv tabIndex={0} className={className} seperatorIcon={seperatorIcon} id={id} aria-label={props.ariaLabel || 'breadCrumb'} {...props}>
      {crumbs}
    </BreadCrumbsDiv>
  );
};

export default BreadCrumb;

const BreadCrumbsDiv = styled.div`
  display: inline-flex;
  background-color: ${props => props.BreadCrumbsBgColor || 'transparent'}; 
  border: ${props => props.BreadCrumbsBorder || ''}; 
  border-radius: ${props => props.BreadCrumbsBorderRadius || '5px'}; 
  padding: ${props => props.BreadCrumbsPadding || '5px'};  
  margin: ${props => props.BreadCrumbsMargin || 'inherit'}; 
`;

const Separator = styled.span`
  color: ${props => props.SeparatorColor || 'blue'}; 
  font-size: ${props => props.SeparatorFontSize || '16px'};
  margin: 0 5px; 
`;

const CrumbLink = styled(Link)`
  text-decoration: ${props => props.linkTextDecoration || 'none'};  
  color: ${props => props.linkTextColor || 'blue'};
  font-size: ${props => props.linkFontSize || '16px'};
  padding: ${props => props.linkPadding || 'none'};
  font-family: ${props => props.linkFontFamily || 'inherit'};
  font-weight: ${props => props.linkFontWeight || 'inherit'};
  line-height: ${props => props.linkLineHeight || 'inherit'};
  &:hover {
    text-decoration: ${props => props.hoverlinkTextDecoration || 'underline'}; 
  }
`;
