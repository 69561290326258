import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

// Styled-components for the dropdown
const DropdownWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const DropdownHeader = styled.div`
  padding: 10px;
  border: ${(props) => `${props.border || '1px solid rgba(0, 123, 255, 0.25)'}`}; 
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownList = styled.ul`
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  top: ${(props) => (props.position === 'above' ? 'auto' : '100%')};
  bottom: ${(props) => (props.position === 'above' ? '100%' : 'auto')};
`;

const DropdownListItem = styled.li`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.isFocused ? '#f0f0f0' : 'transparent')};
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Dropdown = ({ value, options, onChange, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState('below');
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
    setFocusedIndex(-1);
  };

  const handleSelectOption = (optionValue) => {
    onChange(optionValue);
    setIsOpen(false);
    // Return focus to the dropdown header after selection
    dropdownRef.current.focus();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (dropdownRect.bottom + dropdownRect.height > windowHeight) {
        setPosition('above');
      } else {
        setPosition('below');
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && focusedIndex >= 0 && listRef.current) {
      const focusedItem = listRef.current.children[focusedIndex];
      if (focusedItem) {
        focusedItem.scrollIntoView({
          block: 'nearest'
        });
      }
    }
  }, [focusedIndex, isOpen]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Escape':
        setIsOpen(false);
        break;
      case 'ArrowUp':
        event.preventDefault();
        navigateOptions(-1);
        break;
      case 'ArrowDown':
        event.preventDefault();
        navigateOptions(1);
        break;
      case 'Enter':
        event.preventDefault();
        if (isOpen && focusedIndex >= 0) {
          handleSelectOption(options[focusedIndex].value);
        } else {
          setIsOpen(!isOpen);
        }
        break;
      default:
        // Handle number key navigation
        if (event.key >= '1' && event.key <= String(options.length)) {
          event.preventDefault();
          handleSelectOption(options[Number(event.key) - 1].value);
        }
        break;
    }
  };

  const navigateOptions = (step) => {
    if (!isOpen) {
      setIsOpen(true);
      setFocusedIndex(0);
      return;
    }
    setFocusedIndex((prevIndex) => {
      const newIndex = (prevIndex + step + options.length) % options.length;
      return newIndex;
    });
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownHeader 
        onClick={handleToggleDropdown} 
        onKeyDown={handleKeyDown} 
        tabIndex={0}
        role="button"
        aria-expanded={isOpen}
      >
        {options.find((option) => option.value === value)?.label || 'Select an option'}
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </DropdownHeader>
      {isOpen && (
        <DropdownList ref={listRef} position={position} tabIndex={-1}>
          {options.map((option, index) => (
            <DropdownListItem
              key={option.value}
              onClick={() => handleSelectOption(option.value)}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              isFocused={focusedIndex === index}
            >
              {option.label}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

Dropdown.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

Dropdown.defaultProps = {
  name: '',
};

export default Dropdown;
