import React,{useState} from 'react';
import Navbar from './LandingPage/Navbar/Navbar';
import Home from './LandingPage/Menu/Home';
import ComponentPage from './LandingPage/Menu/ComponentPage';
import {Routes,Route} from 'react-router-dom';
import ButtonPage from './LandingPage/Components/ButtonPage';
import TablePage from './LandingPage/Components/TablePage';
import AccordionPage from './LandingPage/Components/AccordionPage';
import TypographyPage from './LandingPage/Components/TypographyPage';
import CardPage from './LandingPage/Components/CardPage';
import DatePickerPage from './LandingPage/Components/DatePickerPage';
import ChartPage from './LandingPage/Components/ChartPage';
import ModalPage from './LandingPage/Components/ModalPage';
import LoaderPage from './LandingPage/Components/LoaderPage';
import IconPage from './LandingPage/Components/IconPage';
import FormComponentPage from './LandingPage/Components/FormComponentPage';
import TooltipAndPoverPage from './LandingPage/Components/TooltipsAndPopoverPage';
import NavigationPage from './LandingPage/Components/NavigationPage';
import SliderAndRangePickerPage from './LandingPage/Components/SliderAndRangePickerPage';
import About from './LandingPage/Menu/About';
import Pricing from './LandingPage/Menu/Pricing';
import AlertDialogs from './customComponents/Modal/AlertDialogs';
import Button from './customComponents/Button/Button';
import Overview from './LandingPage/Get-Started/Overview';
import Installation from './LandingPage/Get-Started/Installation';
import Usage from './LandingPage/Get-Started/Usage';
import FAQ from './LandingPage/Get-Started/FAQ';
import Logoimage from './LandingPage/Images/logo2.png';





const App = () => {
  const navbarData = {
    logo: Logoimage,
    companyName: 'AtomicFusion/UI',
    Home_link:'/',
    menuItems: [
      { label: 'Home', link: '/' },
      { label: 'Components', link: '/component' },
      { label: 'About Us', link: '/About' },
      { label: 'Pricing', link: '/Pricing' },
    ]
  };

  const sidebarData = {
    components: [
        { label: 'Button', link: '/component/Button' },
        { label: 'Typography', link: '/component/Typography' },
        { label: 'Table', link: '/component/Table' },
        { label: 'Accordion', link: '/component/Accordion' },
        { label: 'Card', link: '/component/Card' },
        { label: 'Chart', link: '/component/Chart' },
        { label: 'Loader', link: '/component/Loader' },
        { label: 'Modal', link: '/component/Modal' },
        { label: 'Date-Picker', link: '/component/Date-Picker' },
        { label: 'Form-Components', link: '/component/Form' },
        { label: 'Icon', link: '/component/Icon' },
        { label: 'Navigation', link: '/component/Navigation' },
        { label: 'Slider And Range Picker', link: '/component/Slider-&-Range-Picker' },
        { label: 'Tooltips And Popover', link: '/component/Tooltips-Popover' },
    ],
    menuItems: [
      { label: 'Home', link: '/' },
      { label: 'About Us', link: '/about-us' },
      { label: 'Pricing', link: '/pricing' },
    ],
    getStartedDetails : [
      { label: 'Overview', link: '/Get-Started/Overview' },
      { label: 'Installation', link: '/Get-Started/Installation' },
      { label: 'Usage', link: '/Get-Started/Usage' },
      { label: 'FAQs', link: '/Get-Started/FAQs' },
    ]
  };

  

  return (
    <>
     
      <Navbar navbarData={navbarData} sidebarData={sidebarData} />
      <div style={{marginTop:'10vh'}}>
        
      <Routes>
         <Route path='/' element={<Home/>}/>
         <Route path='/Get-Started' element={<ComponentPage/>}>
            <Route path='Overview' element={<Overview/>} />
            <Route path='Installation' element={<Installation/>} />
            <Route path='Usage' element={<Usage/>} />
            <Route path='FAQs' element={<FAQ/>} />
        </Route>
         <Route path='/component' element={<ComponentPage/>}>
            <Route path='Button' element={<ButtonPage/>} />
            <Route path='Typography' element={<TypographyPage/>} />
            <Route path='Table' element={<TablePage/>} />
            <Route path='Accordion' element={<AccordionPage/>} />
            <Route path='Chart' element={<ChartPage/>} />
            <Route path='Card' element={<CardPage/>} />
            <Route path='Loader' element={<LoaderPage/>} />
            <Route path='Modal' element={<ModalPage/>} />
            <Route path='Date-Picker' element={<DatePickerPage/>} />
            <Route path='Form' element={<FormComponentPage/>} />
            <Route path='Icon' element={<IconPage/>} />
            <Route path='Navigation' element={<NavigationPage/>} />
            <Route path='Slider-&-Range-Picker' element={<SliderAndRangePickerPage/>} />
            <Route path='Tooltips-Popover' element={<TooltipAndPoverPage/>}/>
        </Route>
        <Route path='/About' element={<About/>}/>
        <Route path='/Pricing' element={<Pricing/>}/>

      </Routes>
      </div>
    </>
  );
};

export default App;
