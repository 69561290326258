import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ScrollButton = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  // Function to handle scrolling down
  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  // Function to handle scrolling up
  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Function to toggle scroll button visibility based on scroll position
  const toggleScrollButton = () => {
    const scrollY = window.scrollY;
    const pageHeight = document.documentElement.scrollHeight;

    // Show scroll button if scrolled down past 30% of the page height
    if (scrollY > pageHeight * 0.3) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  // Effect to add scroll listener
  useEffect(() => {
    window.addEventListener('scroll', toggleScrollButton);
    return () => {
      window.removeEventListener('scroll', toggleScrollButton);
    };
  }, []);

  return (
    <ScrollButtonContainer>
      {showScrollButton ? (
        <SButton onClick={handleScrollUp}>
          <ArrowUpwardIcon style={{ fontSize: '70px' }} />
        </SButton>
      ) : (
        <SButton onClick={handleScrollDown}>
          <ArrowDownwardIcon style={{ fontSize: '70px' }} />
        </SButton>
      )}
    </ScrollButtonContainer>
  );
};

const ScrollButtonContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    right: 10px;
    bottom: 10px;
  }
`;

const SButton = styled(Button)`
  && {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding: 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 20px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: green;
    }
  }

  @media (max-width: 768px) {
    && {
      width: 60px;
      height: 60px;
      padding: 15px;
      font-size: 16px;
    }
  }
`;

export default ScrollButton;
