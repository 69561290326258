import React, { useState, useEffect } from 'react';
import './ComponentPage.css';
import styled from 'styled-components';
import BreadCrumbs from '../../customComponents/Navigations/BreadCrumbs';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Icon from '../../customComponents/Icon/Icon';
import Component from './Component';


const ComponentPage = () => {
  const location = useLocation();
  const start_details = [
    { label: 'Overview', link: '/Get-Started/Overview' },
    { label: 'Installation', link: '/Get-Started/Installation' },
    { label: 'Usage', link: '/Get-Started/Usage' },
    { label: 'FAQs', link: '/Get-Started/FAQs' },
  ];
  const components = [
    { label: 'Button', link: '/component/Button' },
    { label: 'Typography', link: '/component/Typography' },
    { label: 'Table', link: '/component/Table' },
    { label: 'Accordion', link: '/component/Accordion' },
    { label: 'Card', link: '/component/Card' },
    { label: 'Chart', link: '/component/Chart' },
    { label: 'Loader', link: '/component/Loader' },
    { label: 'Modal', link: '/component/Modal' },
    { label: 'Date-Picker', link: '/component/Date-Picker' },
    { label: 'Form-Components', link: '/component/Form' },
    { label: 'Icon', link: '/component/Icon' },
    { label: 'Navigation', link: '/component/Navigation' },
    { label: 'Slider And Range Picker', link: '/component/Slider-&-Range-Picker' },
    { label: 'Tooltips And Popover', link: '/component/Tooltips-Popover' },
  ];

  const [startOpen, setStartOpen] = useState(false);
  const [componentsOpen, setComponentsOpen] = useState(false);

  const handleStartToggle = () => {
    setStartOpen(!startOpen);
  };

  const handleComponentsToggle = () => {
    setComponentsOpen(!componentsOpen);
  };

  useEffect(() => {
    if (location.pathname.startsWith('/component')) {
      setComponentsOpen(true);
    } else {
      setComponentsOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.startsWith('/Get-Started')) {
      setStartOpen(true);
    } else {
      setStartOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Scroll to top on location change
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className='componentContainer'>
      <div className='component-list-section scrollable'>
     
        {/* Get started section */}
        <div>
          <div className='accordion-header' onClick={handleStartToggle}>
            <h5>Get Started</h5>
            {startOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
          {startOpen && (
            <div className='component-group'>
              {start_details.map(({ label, link }, index) => (
                <StyledNavLink className='component-item' key={index} to={link}>
                  {label}
                </StyledNavLink>
              ))}
            </div>
          )}
        </div>
        {/* Get started section End */}

        {/* Components Section */}
        <div>
          <div className='accordion-header' onClick={handleComponentsToggle}>
            <h5>React Components</h5>
            {componentsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
          {componentsOpen && (
            <div className='component-group'>
              {components.map(({ label, link }, index) => (
                <StyledNavLink className='component-item' key={index} to={link}>
                  {label}
                </StyledNavLink>
              ))}
            </div>
          )}
        </div>
        {/* Components Section End*/}
      </div>

      {/* Component details section */}
      <div className='component-details-section'>
        <div className='content'>
          <ComponentDetailsContainer>
           <BreadCrumbsSection><Icon IconType='material' IconName='Dashboard' IconColor='black'/><BreadCrumbs seperatorIcon='/' linkFontSize="18px" linkFontWeight="500" linkTextColor='black'/></BreadCrumbsSection>
            {location.pathname === '/component' ? (
              <Component />
            ) : (
              <Outlet />
            )}
          </ComponentDetailsContainer>
        </div>
      </div>
    </div>
  );
};

const ComponentDetailsContainer = styled.div`
  margin: 20px 30px;
  width: 100%;
  height: 100vh;
`;

const StyledNavLink = styled(NavLink)`
   font-family:roboto;
   font-size: 16px;
   font-weight:500;
   color:black;
  &.active {
    color: blue;
  }
`;
const BreadCrumbsSection = styled.div`
  display:flex;
  align-items:center;
  flex-direction:row;
  padding-bottom:20px;
`;

export default ComponentPage;
