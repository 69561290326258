import React, { createContext, useState, useContext, useEffect } from 'react';
import './Themes.css';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [cursor, setCursor] = useState('default');

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  const changeCursor = (newCursor, customCursorImage = '') => {
    if (newCursor === 'custom' && customCursorImage) {
      setCursor(`url(${customCursorImage}), auto`);
      document.documentElement.style.cursor = `url(${customCursorImage}), auto`;
    } else {
      setCursor(newCursor);
      document.documentElement.style.cursor = newCursor;
    }
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    document.documentElement.style.cursor = cursor;
  }, [cursor]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, cursor, changeCursor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
