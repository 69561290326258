import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSelect = styled.select`
    display: ${props =>`${props.display || 'block'}`};
    width: ${props =>`${props.width || '100%'}`};
    padding: .375rem .75rem;
    font-size: ${props =>`${props.fontSize || '1rem'}`};
    font-weight: ${props =>`${props.fontWeight || '400'}`};
    line-height: ${props =>`${props.lineHeight || '40px'}`};
    color:${props =>`${props.textColor || '#212529'}`};
    background-color:${props =>`${props.selectbgColor || '#fff'}`};
    background-clip: padding-box;
    border: ${props =>`${props.border || '1px solid rgba(0, 123, 255, 0.25)'}`}; 
    border-radius:${props =>`${props.borderRadius || '.25rem'}`};
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    ${ props => props.InputCustomCss}
    box-sizing:border-box;

    &:focus {
        border: 1px solid rgba(0, 123, 255, 0.25)!important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        outline: 0ch;

    }
    
    option{
     position:relative;
     background:white;
     color:black;
     padding:100px;
    }
    
`;

const ErrorMessage = styled.span`
        display:${props =>`${props.errorDisplay || 'block'}`};
        color: ${props =>`${props.errorTextColor || '#ff0000'}`}; 
        font-size: ${props =>`${props.errorfontSize || '0.9rem'}`}; 
        padding:  0.5rem;
        margin: 3px 2px;
        ${props => props.ErrorCustomCss}
`;

const FormDiv = styled.div`
        display: block;
        unicode-bidi: isolate;
        box-sizing: border-box;
        margin-top: 10px;
        margin-bottom: 10px;
        ${props => props.InputWrapperCustomCss}
`;

const Label = styled.label`
    margin-bottom: .5rem;
    display: inline-block;
    ${props => props.LabelCustomCss}
`;

const SelectInput = ({ value, label, options, onChange,...props }) => {
    const [error, setError] = useState(false);

    const handleChange = (event) => {
        if(onChange){
            const { value } = event.target;
            setError(validateInput(value));
            onChange(value);
        }
        
    };

    return (
        <FormDiv>
            {label && <Label>{label}</Label>}
            <StyledSelect value={value} {...props} onChange={handleChange}>
                <option value="">Select an option</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </StyledSelect>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
        </FormDiv>
    );
};

SelectInput.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })),
    onChange: PropTypes.func.isRequired
};

SelectInput.defaultProps = {
    value: '',
    label: '',
    options: []
};

const validateInput = (value) => {
    if (!value || value === '') {
        return { error: true, message: 'Please select an option' };
    }
    return false;
};

export default SelectInput;
