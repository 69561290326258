
import React from 'react';
import * as loader from 'react-loader-spinner';
import styled from 'styled-components';

const Loader = ({ 
  LoaderName, 
  LoaderHeight, 
  LoaderWidth, 
  LoaderRadius, 
  LoaderColor, 
  id, 
  className, 
  wrapperCustomCss,
  ariaLabel,
  visibility
}) => {
  // Dynamically select the loader component based on LoaderName
  const LoaderComponent = loader[LoaderName];

  if (!LoaderComponent) {
    console.error(`Loader ${LoaderName} not found`);
    return null;
  }

  // Create a styled version of the selected loader component
  const StyledLoader = styled(LoaderComponent)`
  `;

  return (
    < >
      <StyledLoader
      height={LoaderHeight}
      width={LoaderWidth}
      radius={LoaderRadius}
      color={LoaderColor}
      ariaLabel={ariaLabel || "loading"}
      wrapperStyle={wrapperCustomCss}
      wrapperClass={className}
      visible={visibility}
      outerCircleColor={LoaderColor}
      innerCircleColor={LoaderColor}
      barColor={LoaderColor}
      glassColor={LoaderColor}
    />
    </>
  );
};

export default Loader;


