import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import "./Typography.css"
import '../customFont.css';
// Define default font sizes and font weights for each variant
const defaultStyles = {
  h1: { fontSize: '64px', fontWeight: '900' },
  h2: { fontSize: '50px', fontWeight: '700' },
  h3: { fontSize: '40px', fontWeight: '600' },
  h4: { fontSize: '30px', fontWeight: '500' },
  h5: { fontSize: '25px', fontWeight: '400' },
  h6: { fontSize: '20px', fontWeight: '300' },
  body: { fontSize: '16px', fontWeight: 'normal' },
  body2: { fontSize: '14px', fontWeight: 'normal' },
  small: { fontSize: '12px', fontWeight: 'normal' },
  subheading: { fontSize: '14px', fontWeight: '500' },
};



// Define your reusable typography component
const StyledTypography = styled(motion.p)`
  font-size: ${(props) => props.fontSize || (defaultStyles[props.variant] && defaultStyles[props.variant].fontSize)};
  font-weight: ${(props) => props.fontWeight || (defaultStyles[props.variant] && defaultStyles[props.variant].fontWeight)};
  color: ${(props) => props.textColor || '#000'};
  background-color: ${(props) => props.bgColor || 'transparent'};
  text-decoration:${(props) => props.textDecoration || 'none'};
  text-align: ${props => props.textAlign || 'left'};
  text-shadow: ${props => `${props.shadowX || '0px'} ${props.shadowY || '0px'} ${props.shadowB || '0px'} ${props.shadowColor}`};
  word-wrap: break-word; 
  overflow-wrap: break-word;
  border:${props => `${props.border}`};
  border-radius:${props => `${props.borderR}`};
  width:${props => `${props.width}`};
  height:${props => `${props.height || 'auto'}`};
  line-height:${props =>`${props.lineHeight || 'auto'}`}
  margin-left:${props => `${props.marginL || 'auto'}`};
  margin-right:${props => `${props.marginR || 'auto'}`};
  margin-top:${props => `${props.marginT || 'auto'}`};
  margin-bottom:${props => `${props.marginB || 'auto'}`};
  padding-left:${props => `${props.paddingL || 'auto'}`};
  padding-right:${props => `${props.paddingR || 'auto'}`};
  padding-top:${props => `${props.paddingT || 'auto'}`};
  padding-bottom:${props => `${props.paddingB || 'auto'}`};
  font-family: ${props => props.fontFamily || 'sans-serif,Arial'};


  @media (max-width: 768px) {
    font-size: ${(props) => props.mediafontSize};
    text-shadow: ${props => `${props.mediashadowX || '0px'} ${props.mediashadowY || '0px'} ${props.mediashadowB || '0px'} ${props.mediashadowColor}`};
    margin-left:${props => `${props.mediaMarginL }`};
    margin-right:${props => `${props.mediaMarginR }`};
    margin-top:${props => `${props.mediaMarginT}`};
    margin-bottom:${props => `${props.mediaMarginB }`};
    padding-left:${props => `${props.mediapaddingL }`};
    padding-right:${props => `${props.mediapaddingR }`};
    padding-top:${props => `${props.mediapaddingT}`};
    padding-bottom:${props => `${props.mediapaddingB }`}
    font-weight: ${(props) => props.mediafontWeight};
    color: ${(props) => props.mediatextColor};
    background-color: ${(props) => props.mediabgColor || 'transparent'};
    text-decoration:${(props) => props.mediatextDecoration || 'none'};
    text-align: ${props => `${props.mediatextAlign}`}
    font-weight: ${props => `${props.mediafontWeight}`}
    border:${props => `${props.mediaborder}`};
    border-radius:${props => `${props.mediaborderR}`};
    width:${props => `${props.mediawidth}`};
    height:${props => `${props.mediaheight || 'auto'}`};
    line-height :${props =>`${props.medialineHeight}`}
  }

`;

const Typography = ({ variant, className, as: Tag = 'p', ...props }) => {
  return <StyledTypography as={Tag} variant={variant} className={className} {...props} tabIndex={0} aria-label={variant} role={variant +''+'tag'}/>;
};

// Export functions for each typography variant
export const H1 = (props) => <Typography variant="h1" {...props} />;
export const H2 = (props) => <Typography variant="h2" {...props} />;
export const H3 = (props) => <Typography variant="h3" {...props} />;
export const H4 = (props) => <Typography variant="h4" {...props} />;
export const H5 = (props) => <Typography variant="h5" {...props} />;
export const H6 = (props) => <Typography variant="h6" {...props} />;
export const Body = (props) => <Typography variant="body" {...props} />;
export const Body2 = (props) => <Typography variant="body2" {...props} />;
export const Small = (props) => <Typography variant="small" {...props} />;
export const Subheading = (props) => <Typography variant="subheading" {...props} />;

export default Typography;
