import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CircleProgressBar = ({ totalSize, downloadedSize, strokeColor, strokeWidth, circleSize, start = false, Labeldisplay=true, ...props }) => {
  const [value, setValue] = useState(0);
  const [offset, setOffset] = useState(0);
  const [LabelVisible] = useState(Labeldisplay);
  
  const sizeValue = circleSize || 100;
  const strokeW = strokeWidth || 10;
  const center = sizeValue / 2;
  const radius = (sizeValue - strokeW) / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const percentage = (downloadedSize / totalSize) * 100;
    setValue(percentage);
  }, [downloadedSize, totalSize]);

  useEffect(() => {
    const progressOffset = ((100 - value) / 100) * circumference;
    setOffset(progressOffset);
  }, [value, circumference]);

  return (
    <ProgressCircleContainer size={sizeValue} {...props} tabIndex={0} aria-label="Circle Progress Bar">
      <ProgressSvg width={sizeValue} height={sizeValue}>
        <CircleBackground
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeW}
        />
        <CircleProgress
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeW}
          stroke={strokeColor || 'green'}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </ProgressSvg>
      {LabelVisible ? <Text>{`${value.toFixed(2)}%`}</Text>:''}
    </ProgressCircleContainer>
  );
};

const ProgressCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const ProgressSvg = styled.svg`
  transform: rotate(-90deg);
`;

const CircleBackground = styled.circle`
  fill: none;
  stroke: #e6e6e6;
`;

const CircleProgress = styled.circle`
  fill: none;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
`;

const Text = styled.div`
  position: absolute;
  font-size: ${({ normalTextSize }) => normalTextSize || 18}px;
  font-weight: ${({ TextWeight }) => TextWeight || 'bold'};
  color: ${({ TextColor }) => TextColor || 'black'};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media(max-width: 768px) {
    font-size: ${({ mobileTextSize }) => mobileTextSize || 14}px;
  }
`;

export default CircleProgressBar;
