import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';

const ChartPage = () => {

  const data = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label:'My Datas',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ]
           
        }]
    };

  const initialCode = `
   
                 <h1>Charts Example</h1>
    
                  <h2>Bar Chart</h2>
                  <Chart
                      data={${JSON.stringify(data)}}
                      type="bar"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="600px"
                      height="300px"
                  />
              
                  <h2>Line Chart</h2>
                  <Chart
                      data={${JSON.stringify(data)}}
                      type="line"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="600px"
                      height="300px"
                  />
              
                  <h2>Pie Chart</h2>
                  <Chart
                      data={${JSON.stringify(data)}}
                      type="pie"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="600px"
                      height="300px"
                  />
             
                  <h2>Doughnut Chart</h2>
                  <Chart
                      data={${JSON.stringify(data)}}
                      type="doughnut"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="600px"
                      height="300px"
                  />
              
                  <h2>Polar Area Chart</h2>
                  <Chart
                      data={${JSON.stringify(data)}}
                      type="polarArea"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="600px"
                      height="300px"
                  />
            
                  <h2>Radar Chart</h2>
                  <Chart
                      data={${JSON.stringify(data)}}
                      type="radar"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="600px"
                      height="300px"
                  />
              
                  <h2>Scatter Chart</h2>
                  <Chart
                      data={${JSON.stringify(data)}}
                      type="scatter"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="600px"
                      height="300px"
                  />
              
                  <h2>Bubble Chart</h2>
                  <Chart
                      data={${JSON.stringify(data)}}
                      type="bubble"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="600px"
                      height="300px"
                  />
          `;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  

  const snipet_code = `
  import React, { useState } from 'react';
  import Chart from './customComponents/ChartAndGraphs/Chart';

  const App = () => {
    const data = {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [{
          label:'My Datas',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ]
         
      }]
  };

    return (
      <div>
        <center>
        <h1>Charts Example</h1>

            <h1>Charts Example</h1>
    
                  <h2>Bar Chart</h2>
                  <Chart
                      data={data}
                      type="bar"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="400px"
                      height="300px"
                  />
              
                  <h2>Line Chart</h2>
                  <Chart
                      data={data}
                      type="line"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="400px"
                      height="300px"
                  />
              
                  <h2>Pie Chart</h2>
                  <Chart
                      data={data}
                      type="pie"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="400px"
                      height="300px"
                  />
             
                  <h2>Doughnut Chart</h2>
                  <Chart
                      data={data}
                      type="doughnut"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="400px"
                      height="300px"
                  />
              
                  <h2>Polar Area Chart</h2>
                  <Chart
                      data={data}
                      type="polarArea"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="400px"
                      height="300px"
                  />
            
                  <h2>Radar Chart</h2>
                  <Chart
                      data={data}
                      type="radar"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="400px"
                      height="300px"
                  />
              
                  <h2>Scatter Chart</h2>
                  <Chart
                      data={data}
                      type="scatter"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="400px"
                      height="300px"
                  />
              
                  <h2>Bubble Chart</h2>
                  <Chart
                      data={data}
                      type="bubble"
                      legend={true}
                      color="#000"
                      bgColor="#fff"
                      steps={5}
                      tooltips={true}
                      fontFamily="Arial"
                      fontSize={14}
                      width="400px"
                      height="300px"
                  />
      </center>
    </div>
  );
};

export default App;


  `;

        // State to track whether initial highlight should be shown
  const [showInitialHighlight, setShowInitialHighlight] = useState(true);

  // Function to handle hover effect (remove initial highlight)
  const handleMouseEnter = () => {
    setShowInitialHighlight(true);
  };

  // Function to handle leaving hover effect (restore initial highlight if needed)
  const handleMouseLeave = () => {
    setShowInitialHighlight(false);
  };

  return (
    <div className="wrapper-container">
       <ScrollButton/>
      {/* Heading */}
      <div className='Header-section'>
        <center>
           <h2>Chart</h2>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>Chart component renders a variety of Chart.js charts based on the specified type:</p>
             <div style={{marginLeft:'20px'}}>
             <ul>
              <li>Bar</li>
              <li>Line</li>
              <li> Pie</li>
              <li>Doughnut</li>
              <li>Polar Area</li>
              <li>Radar</li>
              <li>Scatter</li>
              <li>Bubble</li>
            </ul>
             </div>
            <p>It customizes chart options such as legend display, tooltip enablement, colors, font styles, and dimensions, ensuring responsiveness and a consistent layout across different chart types.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Description</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">data</td>
                  <td className="table-data">object</td>
                  <td className="table-data">Required. The data object for the chart. Must follow Chart.js data structure.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">type</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Required. Specifies the type of chart. Can be one of 'bar', 'line', 'pie', 'doughnut', 'polarArea', 'radar', 'scatter', 'bubble', 'mixed'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">legend</td>
                  <td className="table-data">boolean</td>
                  <td className="table-data">Optional. Determines if the legend should be displayed. Default is true.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">legendLayout</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. Specifies the layout of the legend. Can be 'row' or 'column'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">color</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. Color of the axis ticks and data labels. Default is '#000'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">bgColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. Background color of the chart container.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">steps</td>
                  <td className="table-data">number</td>
                  <td className="table-data">Optional. Size of the points on the chart. Only applies to point charts. Default is 1.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">tooltips</td>
                  <td className="table-data">boolean</td>
                  <td className="table-data">Optional. Determines if tooltips should be displayed. Default is true.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">fontFamily</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. Font family for the axis ticks and data labels.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">fontSize</td>
                  <td className="table-data">number</td>
                  <td className="table-data">Optional. Font size for the axis ticks and data labels.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">width</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. Width of the chart container.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">height</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Optional. Height of the chart container.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">options</td>
                  <td className="table-data">object</td>
                  <td className="table-data">Optional. Additional options to be passed to the Chart.js instance.</td>
                </tr>
              </tbody>
            </table>
          </div>
      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
      </div>   
    </div>
  )
}

export default ChartPage