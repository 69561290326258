import React from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x:auto;
    z-index:1200;
`;

const ModalContent = styled.div`
    width: ${props => `${props.ModalWidth || '500px'}`}; 
    background-color: ${props => `${props.ModalBgColor || '#fff'}`};
    border-radius: ${props => `${props.ModalBorderRadius || '8px'}`};
    font-size: ${props => `${props.ModalfontSize || 'inherit'}`}; 
    box-shadow: ${props => `${props.ModalboxShadow || '0 4px 8px rgba(0, 0, 0, 0.2)'}`}; 
    display: ${props => `${props.Modaldisplay || 'flex'}`};
    flex-direction: column;
    ${props => props.ModalcustomCss};
    position:relative;
    top:${props => props.ModalTopPosition || '2vh'};
    box-sizing:border-box;

`;

const MHeader = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    position: relative; 
    ${props => props.ModalHeadercustomCss};
    background-color: ${props => `${props.ModalHeaderbgColor || '#fff'}`};
`;

const ModalCloseButton = styled.button`
    background-color: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: -10px; 
    right: 0px;  
    margin: 0;
    padding: 0;
`;

const MBody = styled.div`
    background-color: ${props => `${props.ModalBodybgColor || '#fff'}`};
    border-radius: ${props => `${props.ModalBodyborderRadius || ''}`};
    display: ${props => `${props.ModalBodydisplay || 'flex'}`};
    flex-direction: ${props => `${props.ModalBodyflexDirection || 'column'}`};
    ${props => props.ModalBodycustomCss};
    padding:10px;
    border-top:1px solid rgba(0, 0, 0, 0.2);
    border-bottom:1px solid rgba(0, 0, 0, 0.2);
    border:${props => props.ModalBodyBorder};
`;

const MFooter = styled.div`
    padding:10px;
    ${props => props.ModalFootercustomCss};
`;

const ModalHeader = ({ children, onClose, ...props }) => {
    return (
        <MHeader {...props}>
            <ModalCloseButton onClick={onClose}>&times;</ModalCloseButton>
            <div style={{marginTop:'10px',padding:'10px'}}>{children}</div>
        </MHeader>
    );
};

const ModalBody = ({ children, ...props }) => {
    return <MBody {...props}>{children}</MBody>;
};

const ModalFooter = ({ children, ...props }) => {
    return <MFooter {...props}>{children}</MFooter>;
};

const Modal = ({ isOpen, onClose, children, ...props }) => {
    if (!isOpen) return null;
    return (
        <ModalWrapper {...props}>
            <ModalContent {...props}>
                {children}
            </ModalContent>
        </ModalWrapper>
    );
};

export default Modal;
export { Modal, ModalBody, ModalFooter, ModalHeader };
