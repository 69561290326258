import React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

function DatePickers({ variant, onChange }) {
  const handleDateChange = (date) => {
    console.log('Selected Date:', date.format('YYYY-MM-DD')); 
    if (onChange) {
      onChange(date);
    }
  };

  const renderPicker = () => {
    switch (variant) {
      case 'desktop':
        return (
          <DesktopDatePicker tabIndex={0} aria-label={variant + '' +'DatePicker'}
            defaultValue={dayjs('2022-04-17')}
            onChange={handleDateChange}
          />
        );
      case 'mobile':
        return (
          <MobileDatePicker tabIndex={0} aria-label={variant + '' +'DatePicker'}
            defaultValue={dayjs('2022-04-17')}
            onChange={handleDateChange}
          />
        );
      case 'responsive':
        return (
          <DatePicker tabIndex={0} aria-label={variant + '' +'DatePicker'}
            defaultValue={dayjs('2022-04-17')}
            onChange={handleDateChange}
          />
        );
      case 'static':
        return (
          <StaticDatePicker tabIndex={0} aria-label={variant + '' +'DatePicker'}
            defaultValue={dayjs('2022-04-17')}
            onChange={handleDateChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} tabIndex={0} aria-label='DatePicker'>
      {renderPicker()}
    </LocalizationProvider>
  );
}

export default DatePickers;
