import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';

const TypographyPage = () => {

  const initialCode = `<Typography variant="h1" textColor="black">Heading</Typography>
  <Typography variant="h2" textColor="black" marginT="20px">Heading</Typography>
  <Typography variant="h3" textColor="black"  marginT="20px">Heading</Typography>
  <Typography variant="h4" textColor="black"  marginT="20px">Heading</Typography>
  <Typography variant="h5" textColor="black"  marginT="20px">Heading</Typography>
  <Typography variant="h6" textColor="black"  marginT="20px">Heading</Typography>
  <Typography variant="body" textColor="black"  marginT="20px">Body</Typography>
  <Typography variant="body2" textColor="black"  marginT="20px">Body2</Typography>
  <Typography variant="small" textColor="black"  marginT="20px">Small</Typography>
  <Typography variant="subheading" textColor="black"  marginT="20px">Subheading</Typography>`;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  

  const snipet_code = `
  import React from 'react';
  import Button from './Components/Typography/Typography';
  const App = () => {
    return (
            <>
              <Typography variant="h1" textColor="black">Heading</Typography>
              <Typography variant="h2" textColor="black">Heading</Typography>
              <Typography variant="h3" textColor="black">Heading</Typography>
              <Typography variant="h4" textColor="black">Heading</Typography>
              <Typography variant="h5" textColor="black">Heading</Typography>
              <Typography variant="h6" textColor="black">Heading</Typography>
              <Typography variant="body" textColor="black">Body</Typography>
              <Typography variant="body2" textColor="black">Body2</Typography>
              <Typography variant="small" textColor="black">Small</Typography>
              <Typography variant="subheading" textColor="black">Subheading</Typography>
            </>
        );
      };
  export default App;
  `;
      // State to track whether initial highlight should be shown
      const [showInitialHighlight, setShowInitialHighlight] = useState(true);

      // Function to handle hover effect (remove initial highlight)
      const handleMouseEnter = () => {
        setShowInitialHighlight(true);
      };
    
      // Function to handle leaving hover effect (restore initial highlight if needed)
      const handleMouseLeave = () => {
        setShowInitialHighlight(false);
      };

  return (

    <div className="wrapper-container">
       <ScrollButton/>
      {/* Heading */}
      <div className='Header-section'>
        <center>
            <h2>Typography</h2>
          </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>Typography is a reusable UI component that handles the styling and structure of text elements. Typography components ensure consistency across a website or application by standardizing the appearance of text elements.</p>
      
            <p>There are ten variants in this Typography component</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
           <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100 section-div'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>



     {/* Props details section */}
      <div className="section-B-Container section-div">
           <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

          <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Default</th>
                  <th className="table-header">Description</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">variant</td>
                  <td className="table-data">string</td>
                  <td className="table-data">undefined</td>
                  <td className="table-data">Defines the text style variant. Possible values: 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body', 'body2', 'small', 'subheading'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">fontSize</td>
                  <td className="table-data">string | number</td>
                  <td className="table-data">undefined</td>
                  <td className="table-data">Sets the font size. Can be a string ('sm', 'md', 'lg', 'xl') or a number (in px or rem units).</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">textColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">#000</td>
                  <td className="table-data">Sets the text color.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">fontWeight</td>
                  <td className="table-data">string</td>
                  <td className="table-data">normal</td>
                  <td className="table-data">Sets the font weight. Possible values: '100', '200', '300', '400', '500', '600', '700', '800', '900'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">border</td>
                  <td className="table-data">string</td>
                  <td className="table-data">none</td>
                  <td className="table-data">Sets the border style.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">borderR</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0px</td>
                  <td className="table-data">Sets the border radius.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">outline</td>
                  <td className="table-data">string</td>
                  <td className="table-data">none</td>
                  <td className="table-data">Sets the outline style.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">marginL</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0</td>
                  <td className="table-data">Sets the left margin.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">marginR</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0</td>
                  <td className="table-data">Sets the right margin.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">marginT</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0</td>
                  <td className="table-data">Sets the top margin.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">marginB</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0</td>
                  <td className="table-data">Sets the bottom margin.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">paddingL</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0</td>
                  <td className="table-data">Sets the left padding.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">paddingR</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0</td>
                  <td className="table-data">Sets the right padding.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">paddingT</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0</td>
                  <td className="table-data">Sets the top padding.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">paddingB</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0</td>
                  <td className="table-data">Sets the bottom padding.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">disabled</td>
                  <td className="table-data">boolean</td>
                  <td className="table-data">false</td>
                  <td className="table-data">Disables the element if true.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">shadowX</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0px</td>
                  <td className="table-data">Sets the horizontal shadow offset.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">shadowY</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0px</td>
                  <td className="table-data">Sets the vertical shadow offset.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">shadowB</td>
                  <td className="table-data">string</td>
                  <td className="table-data">0px</td>
                  <td className="table-data">Sets the blur radius of the shadow.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">shadowColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">transparent</td>
                  <td className="table-data">Sets the color of the shadow.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">height</td>
                  <td className="table-data">string | number</td>
                  <td className="table-data">auto</td>
                  <td className="table-data">Sets the height of the element.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">width</td>
                  <td className="table-data">string | number</td>
                  <td className="table-data">auto</td>
                  <td className="table-data">Sets the width of the element.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">fontFamily</td>
                  <td className="table-data">string</td>
                  <td className="table-data">sans-serif, Arial</td>
                  <td className="table-data">Sets the font family of the text.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">hover</td>
                  <td className="table-data">boolean</td>
                  <td className="table-data">true</td>
                  <td className="table-data">Enables hover effect if true.</td>
                </tr>
              </tbody>
            </table> 
          </div> 

      </div>

     {/* Snippet code section */}
     <div className="section-C-Container section-div">
      <p>Using this component in any page, full code snippet.</p><br/>
      <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>   
      </div>   
    </div>
    
  )
};



export default TypographyPage