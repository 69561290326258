import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';

const IconPage = () => {


  const initialCode = ` 
      <div style={{margin:'auto',width:'80%',display:'flex',flexDirection:'raw',gap:'4rem'}}>
        {/* Material Icon */}
        <Icon 
        IconName='Delete' 
        IconColor="red"
        IconfontSize="30px"
        IconType='material'/> 

        <Icon 
        IconName='RightCircleOutlined' 
        IconColor="blue"
        IconfontSize="30px"
        IconType='ant'/> 

        <Icon 
        IconName='faHouse' 
        IconColor="green"
        IconfontSize="30px"
        IconType='fontawesome'/>   
      </div>`;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  

  const snipet_code = `
  import React from 'react';
  import Icon from './customComponents/Icon/Icon'

  const App = () => {
      
      return (
          <div style={{margin:'auto',width:'80%',display:'flex',flexDirection:'column'}}>

          {/* Material Icon */}
          <Icon 
          IconName='Add' 
          IconType='material'/>     
        </div>
      );
  };

  export default App;


  `;

    
    // State to track whether initial highlight should be shown
    const [showInitialHighlight, setShowInitialHighlight] = useState(true);

    // Function to handle hover effect (remove initial highlight)
    const handleMouseEnter = () => {
      setShowInitialHighlight(true);
    };
  
    // Function to handle leaving hover effect (restore initial highlight if needed)
    const handleMouseLeave = () => {
      setShowInitialHighlight(false);
    };

  return (
    <div className="wrapper-container">
       <ScrollButton/>
      {/* Heading */}
      <div className='Header-section'>
        <center>
           <h2>Icon</h2>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>An Icon component is a reusable UI element that displays graphical symbols or icons. It enhances user interfaces by visually representing actions, objects, or statuses. Often customizable, it can adapt in size, color, and style to fit various design needs and improve user experience.Icon component is the reusable component that is build by using the existing libraries. Such as :</p>
            
            <div style={{marginLeft:'20px'}}>
            <ul>
              <li>Material UI</li>
              <li>Ant Design</li>
              <li>Font Awesome</li>
            </ul>
            </div>

            <p>IconName is the name of the Icon, which you want to use and the name should be same as in the libraries (Material UI Icon/Ant Design Icon/FontAwesome icon).</p>

             <p>IconType is the property of the icon component which is use for specifying from which library’ Icon (Material UI Icon/Ant Design Icon/FontAwesome icon) want to use in the code.</p>
            
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">Name</th>
                <th className="table-header">Props name</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">Icon Name</td>
                <td className="table-data">IconName</td>
                <td className="table-data">IconName prop is used for selecting the specific icon from the Library of Material UI Icon/ Ant Design Icon/ Fontawesome Icon.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">Icon Type</td>
                <td className="table-data">IconType</td>
                <td className="table-data">IconType prop is used for selecting the specific icon from any of the three libraries available. It specifies the library name.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">Font Size</td>
                <td className="table-data">IconfontSize</td>
                <td className="table-data">This property is used to change the font size of the icon.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">Color</td>
                <td className="table-data">IconColor</td>
                <td className="table-data">This property is used to change the Icon color.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">Background color</td>
                <td className="table-data">IconBgColor</td>
                <td className="table-data">This property of the Icon component is specifically used for changing the background color of the icon.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">Style</td>
                <td className="table-data">IconCustomCss</td>
                <td className="table-data">This property of the Icon component allows the developer to set custom CSS.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
      </div>   
    </div>
  )
}

export default IconPage