import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';


const TablePage = () => {
 

  // Table section
const data = [
  { Name: 'Airi Satou', Position: 'Accountant', Office: 'Tokyo', Age: 33, 'Start date': '2008-11-28', Salary: '$162,700' },
{ Name: 'Angelica Ramos', Position: 'Chief Executive Officer (CEO)', Office: 'London', Age: 47, 'Start date': '2009-10-09', Salary: '$1,200,000' },
{ Name: 'Ashton Cox', Position: 'Junior Technical Author', Office: 'San Francisco', Age: 66, 'Start date': '2009-01-12', Salary: '$86,000' },
{ Name: 'Bradley Greer', Position: 'Software Engineer', Office: 'London', Age: 41, 'Start date': '2012-10-13', Salary: '$132,000' },
{ Name: 'Brenden Wagner', Position: 'Software Engineer', Office: 'San Francisco', Age: 28, 'Start date': '2011-06-07', Salary: '$206,850' },
{ Name: 'Brielle Williamson', Position: 'Integration Specialist', Office: 'New York', Age: 61, 'Start date': '2012-12-02', Salary: '$372,000' },
{ Name: 'Bruno Nash', Position: 'Software Engineer', Office: 'London', Age: 38, 'Start date': '2011-05-03', Salary: '$163,500' },
{ Name: 'Caesar Vance', Position: 'Pre-Sales Support', Office: 'New York', Age: 21, 'Start date': '2011-12-12', Salary: '$106,450' },
{ Name: 'Cara Stevens', Position: 'Sales Assistant', Office: 'New York', Age: 46, 'Start date': '2011-12-06', Salary: '$145,600' },
{ Name: 'Cedric Kelly', Position: 'Senior Javascript Developer', Office: 'Edinburgh', Age: 22, 'Start date': '2012-03-29', Salary: '$433,060' },
{ Name: 'Airi Satou', Position: 'Accountant', Office: 'Tokyo', Age: 33, 'Start date': '2008-11-28', Salary: '$162,700' },
{ Name: 'Angelica Ramos', Position: 'Chief Executive Officer (CEO)', Office: 'London', Age: 47, 'Start date': '2009-10-09', Salary: '$1,200,000' }

];

const columns = ['Name', 'Position', 'Office', 'Age', 'Start date', 'Salary', 'Age', 'Start date', 'Salary'];

 
const initialCode = `<Table columns={${JSON.stringify(columns)}} data={${JSON.stringify(data)}}  tableHeadBgColor="gray" tableHeadFontFamily="Arial"/>`;
const [code, setCode] = useState(initialCode);
const handleCodeChange = (newCode) => {
  setCode(newCode);
};


  const snipet_code = `
  import React, { useState } from 'react';
  import Table from './customComponents/Table/Table';

  const App = () => {
        

          // Table section
          const data = [
              { Name: 'Airi Satou', Position: 'Accountant', Office: 'Tokyo', Age: 33, 'Start date': '2008-11-28', Salary: '$162,700' },
            { Name: 'Angelica Ramos', Position: 'Chief Executive Officer (CEO)', Office: 'London', Age: 47, 'Start date': '2009-10-09', Salary: '$1,200,000' },
            { Name: 'Ashton Cox', Position: 'Junior Technical Author', Office: 'San Francisco', Age: 66, 'Start date': '2009-01-12', Salary: '$86,000' },
            { Name: 'Bradley Greer', Position: 'Software Engineer', Office: 'London', Age: 41, 'Start date': '2012-10-13', Salary: '$132,000' },
            { Name: 'Brenden Wagner', Position: 'Software Engineer', Office: 'San Francisco', Age: 28, 'Start date': '2011-06-07', Salary: '$206,850' },
            { Name: 'Brielle Williamson', Position: 'Integration Specialist', Office: 'New York', Age: 61, 'Start date': '2012-12-02', Salary: '$372,000' }
            ];
            
            const columns = ['Name', 'Position', 'Office', 'Age', 'Start date', 'Salary'];

    return (
        <div>

            <Table 
             columns={columns}
             data={data} 
             tableHeadBgColor="gray"
             tableHeadFontFamily="Arial"
             />

        </div>
    );
};

export default App;


  `;

     // State to track whether initial highlight should be shown
     const [showInitialHighlight, setShowInitialHighlight] = useState(true);

     // Function to handle hover effect (remove initial highlight)
     const handleMouseEnter = () => {
       setShowInitialHighlight(true);
     };
   
     // Function to handle leaving hover effect (restore initial highlight if needed)
     const handleMouseLeave = () => {
       setShowInitialHighlight(false);
     };

  return (

    <div className="wrapper-container">
       <ScrollButton/>
      {/* Heading */}
      <div className='Header-section'>
        <center>
           <h2>Table</h2>
        </center>
        <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
             <p>The table component organizes data into rows and columns, facilitating structured display and interaction with tabular data.</p>
        </div>
      </div>
   
      
       
      
     {/* Preview and Editor section */}
     <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
           <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>



     {/* Props details section */}
     <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

       <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">Prop</th>
                <th className="table-header">Type</th>
                <th className="table-header">Default</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">tableHeadBgColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'#f4f4f4'</td>
                <td className="table-data">Background color of the table header.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">tableHeadFontFamily</td>
                <td className="table-data">string</td>
                <td className="table-data">'arial'</td>
                <td className="table-data">Font family of the table header.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">tableHeadFontSize</td>
                <td className="table-data">string</td>
                <td className="table-data">'14px'</td>
                <td className="table-data">Font size of the table header.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">tableBodyBgColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'transparent'</td>
                <td className="table-data">Background color of the table body.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">tableBodyFontFamily</td>
                <td className="table-data">string</td>
                <td className="table-data">'arial'</td>
                <td className="table-data">Font family of the table body.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">tableBodyFontSize</td>
                <td className="table-data">string</td>
                <td className="table-data">'14px'</td>
                <td className="table-data">Font size of the table body.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SearchFocusBorderColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'#BED7F6'</td>
                <td className="table-data">Border color of the search input when focused.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SearchBorderColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'#8F9398'</td>
                <td className="table-data">Border color of the search input.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SearchBgColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'white'</td>
                <td className="table-data">Background color of the search input.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">PaginationButtonMargin</td>
                <td className="table-data">string</td>
                <td className="table-data">'0 5px'</td>
                <td className="table-data">Margin of the pagination buttons.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">PaginationButtonPadding</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px 10px'</td>
                <td className="table-data">Padding of the pagination buttons.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">PaginationButtonBorder</td>
                <td className="table-data">string</td>
                <td className="table-data">'1px solid #ddd'</td>
                <td className="table-data">Border of the pagination buttons.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">PaginationButtonBgColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'gray'</td>
                <td className="table-data">Background color of the pagination buttons.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">PaginationButtonTextColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'white'</td>
                <td className="table-data">Text color of the pagination buttons.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


        {/* Snippet code section */}
        <div className="section-C-Container section-div">
            <p>Using this component in any page, full code snippet.</p><br/>
            <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>   
         </div>   
      </div>
  )
}



export default TablePage