import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

// Function to get the variant
const getVariant = (variant) => {
  switch (variant) {
    case 'primary':
      return css`
        background-color: #007bff;
        color: #fff;
      `;
    case 'secondary':
      return css`
        background-color: #6c757d;
        color: #fff;
      `;
    case 'success':
      return css`
        background-color: #28a745;
        color: #fff;
      `;
    case 'danger':
      return css`
        background-color: #dc3545;
        color: #fff;
      `;
    case 'warning':
      return css`
        background-color: #ffff00;
        color: #000;
      `;
    case 'dark':
      return css`
        background-color: #000;
        color: #fff;
      `;
    default:
      return css`
        background-color: #fff;
        color: #000;
        border: 0.1px solid gray;
      `;
  }
};

// Function to get the font size 
const getSize = (fontSize) => {
  if (fontSize === 'sm') {
    return css`
      font-size: 0.5rem; 
    `;
  } else if (fontSize === 'md') {
    return css`
      font-size: 0.625rem; 
    `;
  } else if (fontSize === 'lg') {
    return css`
      font-size: 1.25rem; 
    `;
  } else if (fontSize === 'xl') {
    return css`
      font-size: 1.875rem; 
    `;
  } else {
    const fontSizeRem = fontSize / 16;
    return css`
      font-size: ${fontSizeRem}rem; 
    `;
  }
};

// Function to get the Text Color
const getTextColor = (textColor) => {
  return css`
    color: ${textColor};
  `;
};

// Function to get custom weight
const getWeight = (fontWeight) => {
  switch (fontWeight) {
    case '1':
      return css`
        font-weight: 600;
      `;
    case '2':
      return css`
        font-weight: 700;
      `;
    case '3':
      return css`
        font-weight: 800;
      `;
    case '4':
      return css`
        font-weight: 900;
      `;
    case '5':
      return css`
        font-weight: bold;
      `;
    default:
      return css`
        font-weight: ${fontWeight};
      `;
  }
};

const ButtonStyled = styled(motion.button)`
  border: ${props => `${props.border || 'none'}`};
  border-radius: ${props => `${props.borderR || '4px'}`};
  outline: ${props => `${props.outline || 'none'}`};
  margin-left: ${props => `${props.marginL || '5px'}`};
  margin-right: ${props => `${props.marginR || '5px'}`};
  margin-top: ${props => props.marginT || '5px'}!important;
  margin-bottom: ${props => props.marginB || '5px'}!important;
  padding-left: ${props => props.paddingL || '20px'}!important;
  padding-right: ${props => props.paddingR || '20px'}!important;
  padding-top: ${props => props.paddingT || '8px'}!important;
  padding-bottom: ${props => props.paddingB || '8px'}!important;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: ${props => `${props.shadowX || '0px'} ${props.shadowY || '2px'} ${props.shadowB || '5px'} ${props.shadowColor || 'rgba(000, 000, 000, 0.9)'}`};
  ${({ variant }) => getVariant(variant)};
  ${({ fontSize }) => getSize(fontSize)};
  ${({ textColor }) => getTextColor(textColor)};
  min-height: ${props => `${props.height || 2}rem`};
  min-width: ${props => `${props.width || 8}rem`};
  width:${props => `${props.width || 'auto'}rem`};
  ${({ fontWeight }) => getWeight(fontWeight)};
  font-family: ${props => `${props.fontFamily || 'Arial, sans-serif'}`};
  &:hover {
    opacity: ${({ hover }) => (hover ? '0.8' : '1')};
    transition: transform 0.2s ease;
  };
  &:disabled {
    opacity: 0.6; 
    cursor: not-allowed; 
    transform: none;
    transition: none;
  }
  @media (max-width: 768px) {
    height: auto; 
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  } 
`;

const Button = ({ children, hover = "true",onClick, ...props }) => {
  return (
    <ButtonStyled hover={hover} {...props} onClick={onClick}>
      {children}
    </ButtonStyled>
  );
};

export default Button;
