import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Slider = ({ min, max, step, value, onChange, SliderCustomCss, labelCustomCss,...props }) => {
  const [sliderValue, setSliderValue] = useState(value || min);

  useEffect(() => {
    setSliderValue(value || min);
  }, [value, min]);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderValue(newValue);
    onChange && onChange(newValue);
  };

  const percentage = ((sliderValue - min) / (max - min)) * 100;

  return (
    <SliderContainer {...props} tabIndex={0} aria-label='SliderPicker'>
      <StyledSlider
        type="range"
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={handleChange}
        percentage={percentage}
        style={SliderCustomCss} 
        {...props}
      />
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',justifyItems:'center',width: props.SliderWidth || '100%'}}>
      <Leftlabel  style={labelCustomCss} {...props}>{min}</Leftlabel>
      <Rightlabel  style={labelCustomCss} {...props}>{max}</Rightlabel>
      </div>
    </SliderContainer>
  );
};

const Leftlabel = styled.div`
  width:50%;
  color: ${props => `${props.LabelTextColor || ' black'}`};
  font-size: ${props => `${props.LabelFontSize || ' 12px'}`}; 
  font-family: ${props => `${props.LabelFontFamily || ' inherit'}`}; 
  font-weight: ${props => `${props.LabelFontWeight || ' inherit'}`}; 
  margin-top: 0px;
  position: relative;
  display:flex;
  flex-direction:flex-start;
  align-items:center;
`;

const Rightlabel = styled.div`
  width:50%;
  color:${props => `${props.LabelTextColor || ' black'}`};
  font-size: ${props => `${props.LabelFontSize || ' 12px'}`}; 
  font-family: ${props => `${props.LabelFontFamily || ' inherit'}`}; 
  font-weight: ${props => `${props.LabelFontWeight || ' inherit'}`}; 
  margin-top: 0px;
  position: relative;
  display:flex;
  justify-content:flex-end;
  align-items:center;
`;
const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;




const StyledSlider = styled.input.attrs({ type: 'range' })`
  width:${props => `${props.SliderWidth || '100%'}`};
  margin: 10px 0;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(
    to right,
    ${props => `${props.SliderBgColor || 'green'}`} ${(props) => props.percentage}%,
    #ddd ${(props) => props.percentage}%
  );
  height: ${props => `${props.sliderHeight || '20px'}`}; 
  border-radius: ${props => `${props.SliderborderRadius || '5px'}`}; 
  outline: ${props => `${props.SliderOutline || 'none'}`}; 
  transition: background 0.3s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${props => `${props.SliderThumbWidth || '30px'}`};
    height: ${props => `${props.SliderThumbHeight || '30px'}`};
    background:${props => `${props.SliderThumbBgColor || ' white'}`};
    cursor: pointer;
    border-radius:${props => `${props.SliderThumbBorderRadius || '10%'}`}; 
    position:relative;
    top:${props => `${props.SliderThumbTop || '50%'}`};
    border:${props => `${props.SliderThumbBorder || '1px solid gray'}`};
  }

  &::-moz-range-thumb {
    width: ${props => `${props.SliderThumbWidth || '30px'}`};
    height: ${props => `${props.SliderThumbHeight || '30px'}`};
    background:${props => `${props.SliderThumbBgColor || ' white'}`};
    cursor: pointer;
    border-radius:${props => `${props.SliderThumbBorderRadius || '10%'}`}; 
    position:relative;
    top:${props => `${props.SliderThumbTop || '50%'}`};
    border:${props => `${props.SliderThumbBorder || '1px solid gray'}`};
  }
`;



export default Slider;
