import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';

const DatePickerPage = () => {

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    console.log('New Date Selected:', date.format('YYYY-MM-DD'));
    setSelectedDate(date);
  };



  const initialCode = `<div>
      <center>
      <h2>Responsive Date Pickers Example</h2>
      <br/>
      <br/>
      <br/>
      <DatePicker
        variant="responsive"
        onChange={handleDateChange}
      />
      </center>
    </div>`;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  

  const snipet_code = `
  
import React,{useState,useEffect} from 'react';
import DatePicker from './customComponents/DatePicker/DatePicker';



const App = () => {

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    console.log('New Date Selected:', date.format('YYYY-MM-DD'));
    setSelectedDate(date);
  };


  return (
   
      <>
       <div>
      <center>
      <h1>Responsive Date Pickers Example</h1>
      <div>
        Selected Date: {selectedDate ? selectedDate.format('YYYY-MM-DD') : '___________________'}
      </div>
      <br/>
      <DatePicker
        variant="responsive"
        onChange={handleDateChange}
      />
      </center>
    </div>
      

      </>
  );
};

export default App;


  `;

      // State to track whether initial highlight should be shown
      const [showInitialHighlight, setShowInitialHighlight] = useState(true);

      // Function to handle hover effect (remove initial highlight)
      const handleMouseEnter = () => {
        setShowInitialHighlight(true);
      };
    
      // Function to handle leaving hover effect (restore initial highlight if needed)
      const handleMouseLeave = () => {
        setShowInitialHighlight(false);
      };
       

  return (
    <div className="wrapper-container">
       <ScrollButton/>
      {/* Heading */}
      <div className='Header-section'>
        <center>
           <h2>Date Picker</h2>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>A date picker is an interactive component in a user interface that allows users to easily select a date from a calendar view. It enhances the user experience by providing a visual and intuitive way to choose dates, often used in forms and scheduling applications.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">Prop</th>
                <th className="table-header">Type</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">variant</td>
                <td className="table-data">string</td>
                <td className="table-data">Specifies the variant of the date picker. Can be one of 'desktop', 'mobile', 'responsive', or 'static'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">onChange</td>
                <td className="table-data">function</td>
                <td className="table-data">Callback function that is invoked when the selected date changes. It receives the selected date as a parameter.</td>
              </tr>
            </tbody>
          </table>
        </div>


      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
      </div>   
    </div>
  )
}

export default DatePickerPage