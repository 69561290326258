import React from 'react';
import { 
    Bar, 
    Line, 
    Pie, 
    Doughnut, 
    PolarArea, 
    Radar, 
    Scatter, 
    Bubble 
} from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, BarElement, LineElement, PointElement, CategoryScale, LinearScale, RadialLinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend
);

function Chart({ data, type, legend, legendLayout, color, bgColor, steps, tooltips, fontFamily, fontSize, width, height,...props }) {
    let ChartComponent;

    switch (type) {
        case 'bar':
            ChartComponent = Bar;
            break;
        case 'line':
            ChartComponent = Line;
            break;
        case 'pie':
            ChartComponent = Pie;
            break;
        case 'doughnut':
            ChartComponent = Doughnut;
            break;
        case 'polarArea':
            ChartComponent = PolarArea;
            break;
        case 'radar':
            ChartComponent = Radar;
            break;
        case 'scatter':
            ChartComponent = Scatter;
            break;
        case 'bubble':
            ChartComponent = Bubble;
            break;
        case 'mixed':
            ChartComponent = Bar; 
            break;
        default:
            ChartComponent = Bar; 
    }

    const options = {
        plugins: {
            legend: {
                display: legend,
                position: (type === 'pie' || type === 'doughnut' || type === 'polarArea' || type === 'radar') ? (legendLayout === 'row' ? 'bottom' : 'right') : 'default',
            },
            tooltip: {
                enabled: tooltips
            }
        },
        elements: {
            point: {
                radius: steps
            }
        },
        layout: {
            padding: {
                left: 50,
                right: 50,
                top: 50,
                bottom: 50
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: type === 'pie' || type === 'doughnut' || type === 'polarArea' || type === 'radar' ? false : true,
                type: 'category',
                ticks: {
                    color: color,
                    font: {
                        family: fontFamily,
                        size: fontSize
                    }
                }
            },
            y: {
                display: type === 'pie' || type === 'doughnut' || type === 'polarArea' || type === 'radar' ? false : true,
                ticks: {
                    color: color,
                    font: {
                        family: fontFamily,
                        size: fontSize
                    }
                }
            }
        },
        plugins: {
            datalabels: {
                display: true,
                align: 'top',
                color: color,
                font: {
                    family: fontFamily,
                    size: fontSize
                },
                formatter: (value, context) => {
                    return context.chart.data.labels[context.dataIndex];
                }
            }
        }
    };

    const style = {
        width: width,
        height: height,
        backgroundColor: bgColor
    };

    return (
        <div style={style} tabIndex={0} aria-label={type + ''+ 'chart'}>
            {type === 'mixed' ? (
                <ChartJS type='bar' data={data}  options={options || props.options} />
            ) : (
                <ChartComponent data={data}  options={options || props.options} />
            )}
        </div>
    );
}

export default Chart;
