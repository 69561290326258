import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styled component for the notification container
const NotificationContainer = styled.div`
  position: relative;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify:content:center;
  width: ${props => props.Notificationwidth || '300px'};
  font-size: ${props => props.NotificationfontSize || 'inherit'};
  font-family: ${props => props.NotificationfontFamily || 'inherit'};
  font-weight: ${props => props.NotificationfontWeight || 'inherit'};
  padding: 15px;
  border-radius: 5px;
  z-index:3;
  color: white;
  word-wrap:wrap;
  ${props => props.NotificationcustomCss};
  ${({ type }) => {
    switch (type) {
      case 'success':
        return 'background-color: #28a745;'; // green
      case 'warning':
        return 'background-color: #ffc107;'; // yellow
      case 'error':
        return 'background-color: #dc3545;'; // red
      default:
        return 'background-color: #007bff;'; // blue
    }
  }}
`;

const Icon = styled.span`
  margin-right: 10px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 35%;
    right: 8px;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
`;

const Notification = ({ message, type, icon, className,...props }) => {
  const [closed, setClosed] = useState(false);

  const handleClose = () => {
    setClosed(true);
  };

  return !closed ? (
    <NotificationContainer type={type} className={className}>
      {icon && <Icon>{icon}</Icon>}
      <div>{message}</div>
      <CloseButton onClick={handleClose}>X</CloseButton>
    </NotificationContainer>
  ) : null;
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'warning', 'error']),
  icon: PropTypes.node, // Allow passing an icon as a node
  className: PropTypes.string
};

export default Notification;
