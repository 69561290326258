import React,{useState} from 'react';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import InputField from '../../customComponents/Form-components/InputField/InputField';
import Checkbox from '../../customComponents/Form-components/CheckBox/CheckBox';
import RadioInput from '../../customComponents/Form-components/RadioBox/RadioInput';
import Select from '../../customComponents/Form-components/Dropdown/Select';
import MultiSelect from '../../customComponents/Form-components/Dropdown/MultiSelect';
import ScrollButton from '../Get-Started/ScrollButton';

const FormComponentPage = () => {

const [name,setName]=useState('');
const [email,setEmail] =useState('');
const [password,setPassword] = useState('');
const [number,setNumber] = useState('');
const [address,setAddress] = useState('');
const [hobby,setHobby] = useState(['']);
const [gender,setGender] = useState('');
const [city,setCity] = useState('');
const [mutiCity,setMulticity] = useState([]);

const handleName = (value) => setName(value);
const handleEmail = (value) => setEmail(value);
const handlePassword = (value) => setPassword(value);
const handleNumber = (value) => setNumber(value);
const handleAddress = (value) => setAddress(value);
const handleHobby = (value) => setHobby(value);
const handleGender = (value) => setGender(value);
const handleCity = (value) => setCity(value);
const handleMulticity = (value) => setMulticity(value);

const handleForm = async(event) => {
  event.preventDefault();
  const formData = {
    name,
    email,
    password,
    number,
    address,
    hobby,
    gender,
    city,
    mutiCity
  };
  console.log('Form Data:', formData);
  // Add your form submission logic here (e.g., sending data to a server)
};

// Custom validator function
const customValidator = (value) => {
if (value.length < 5) {
    return { error: true, errorMessage: "Character must not less then 5" };
}
return false;
};

const snipet_code_text = `
import React,{useState} from 'react';
import InputField from '../../customComponents/Form-components/InputField/InputField';
const App = () => {
    const [name,setName]=useState('');
    const handleName = (value) => setName(value);
    // Custom validator function
    const customValidator = (value) => {
    if (value.length < 5) {
        return { error: true, errorMessage: "Character must not less then 5" };
    }
    return false;
    };
    return(
    <>
       <InputField
              name="Name"
              type="text"
              value={name}
              placeholder="Enter your name"
              onChange={handleName}
              errorMessage="Please insert the proper value"
              validators={['required',customValidator]}
         />
    </>
    );
};
export default App;
`;

const snipet_code_email = `
import React,{useState} from 'react';
import InputField from '../../customComponents/Form-components/InputField/InputField';
const App = () => {
    const [email,setEmail] =useState('');
    const handleEmail = (value) => setEmail(value);
    return(
    <>
         <InputField
              name="Email"
              value={email}
              type="email"
              placeholder="Enter your Email"
              onChange={handleEmail}
              validators={['required','email']}
              />

    </>
    );
};
export default App;
`;


const snipet_code_Password = `
import React,{useState} from 'react';
import InputField from '../../customComponents/Form-components/InputField/InputField';
const App = () => {
    const [password,setPassword] = useState('');
    const handlePassword = (value) => setPassword(value);
    return(
    <>
          <InputField
              name="Password"
              value={password}
              type="password"
              placeholder="Enter your Password"
              onChange={handlePassword}
              validators={['required','password']}
              />
    </>
    );
};
export default App;
`;

const snipet_code_number = `
import React,{useState} from 'react';
import InputField from '../../customComponents/Form-components/InputField/InputField';
const App = () => {
    const [number,setNumber] = useState('');
    const handleNumber = (value) => setNumber(value);
    return(
    <>
        <InputField
              name="Number"
              value={number}
              type="number"
              placeholder="Enter your Number"
              onChange={handleNumber}
              validators={['required']}
              />
    </>
    );
};
export default App;
`;


const snipet_code_textarea = `
import React,{useState} from 'react';
import InputField from '../../customComponents/Form-components/InputField/InputField';
const App = () => {
    const [address,setAddress] = useState('');
    const handleAddress = (value) => setAddress(value);
    return(
    <>
          <InputField
              name="Address"
              value={address}
              type="textarea"
              placeholder="Enter your Address"
              onChange={handleAddress}
              validators={['required']}
              />
    </>
    );
};
export default App;
`;

const snipet_code_select = `
import React,{useState} from 'react';
import Select from '../../customComponents/Form-components/Dropdown/Select';
const App = () => {
    const [city,setCity] = useState('');
    const handleCity = (value) => setCity(value);
    return(
    <>
     <Select
              value={city}
              name="city"
              options={[
                        { value: 'india', label: 'India' },
                        { value: 'usa', label: 'USA' },
                        { value: 'uk', label: 'UK' },
                        { value: 'germany', label: 'Germany' },
                        { value: 'russia', label: 'Russia' },
                        { value: 'italy', label: 'Italy' },
                      ]}
              onChange={handleCity}
              /> 
    </>
    );
};
export default App;

`;
const snipet_code_multiselect = `
import React,{useState} from 'react';
import MultiSelect from '../../customComponents/Form-components/Dropdown/MultiSelect';
const App = () => {
    const [mutiCity,setMulticity] = useState([]);
    const handleMulticity = (value) => setMulticity(value);
    return(
    <>
    <MultiSelect
    value={mutiCity}
    name="city"
    options={[
              { value: 'india', label: 'India' },
              { value: 'usa', label: 'USA' },
              { value: 'uk', label: 'UK' },
              { value: 'germany', label: 'Germany' },
              { value: 'russia', label: 'Russia' },
              { value: 'italy', label: 'Italy' },
             ]}
    onChange={handleMulticity}
    />    
    </>
    );
};
export default App;
`;

const snipet_code_checkbox = `
import React,{useState} from 'react';
import Checkbox from '../../customComponents/Form-components/CheckBox/CheckBox';
const App = () => {
    const [hobby,setHobby] = useState(['']);
    const handleHobby = (value) => setHobby(value);
    return(
    <>
     <Checkbox
      name="hobby"
      options={['Cricket','Football','Hockey']}
      onChange={handleHobby}
      />   
    </>
    );
};
export default App;
`;

const snipet_code_radio = `
import React,{useState} from 'react';
import RadioInput from '../../customComponents/Form-components/RadioBox/RadioInput';
const App = () => {
    const [gender,setGender] = useState('');
    const handleGender = (value) => setGender(value);
    return(
    <>
        <RadioInput
        name="gender"
        value={gender} 
        options={['Female','Male']}
        onChange={handleGender}
        />  
    </>
    );
};
export default App;
`;

      // State to track whether initial highlight should be shown
      const [showInitialHighlight, setShowInitialHighlight] = useState(true);

      // Function to handle hover effect (remove initial highlight)
      const handleMouseEnter = () => {
        setShowInitialHighlight(true);
      };
    
      // Function to handle leaving hover effect (restore initial highlight if needed)
      const handleMouseLeave = () => {
        setShowInitialHighlight(false);
      };  

  return (
    <div className="wrapper-container">
       <ScrollButton/>
      <center>
           <h2 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>Form Components</h2>
        </center>
     <div className='InputFieldContainer'>
        {/* Heading */}
        <div className='Header-section'>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>Input Field</h3>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>The InputField component is a customizable input field that supports various features like validation, error messages, and different input types. It handles user input, validates it based on specified rules, and displays error messages if needed. It's a versatile component for building forms with interactive input fields in React applications.</p>
            <br></br>
            <p>The InputField component supports various input types such as text, email, password, and more, providing flexibility in form design and data collection.</p>
            <br></br>
            <p>The CheckBox component is a customizable checkbox input that allows users to select multiple options from a given list. It takes in options, selectedOptions, onChange, and label as props. It uses styled-components for styling and handles the selection state internally, updating the parent component through the onChange function. Each option is rendered as a checkbox with a styled span to indicate its checked state, ensuring a responsive and accessible user interface.</p>
            <br></br>
            <p>A Radio Input component allows users to select a single option from a list of choices. It typically comprises radio buttons with associated labels, where only one option can be selected at a time. This component facilitates user interaction by enabling them to make a single choice from a predefined set of options, often used in forms or surveys for selecting mutually exclusive options.</p>
            <br></br>
            <p>A Dropdown Input component presents users with a list of options in a compact format, allowing them to select one option from the list. It consists of a dropdown menu that expands when clicked, revealing the available choices. Users can then click on an option to select it. Dropdown inputs are commonly used in forms or settings menus to conserve space and provide a user-friendly selection interface.</p>
         </div>

      </div>  

      
      
            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>Text Input Demo:</label>
              <InputField
              name="Name"
              type="text"
              value={name}
              placeholder="Enter your name"
              onChange={handleName}
              errorMessage="Please insert the proper value"
              validators={['required',customValidator]}
              />
            </div>

                  {/* Snippet code section */}
            <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_text}</CodeSnippet>      
            </div>  



            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>Email Input Demo:</label>
              <InputField
              name="Email"
              value={email}
              type="email"
              placeholder="Enter your Email"
              onChange={handleEmail}
              validators={['required','email']}
              />
            </div>

                  {/* Snippet code section */}
            <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_email}</CodeSnippet>      
            </div>  

            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>Password Input Demo:</label>
              <InputField
              name="Password"
              value={password}
              type="password"
              placeholder="Enter your Password"
              onChange={handlePassword}
              validators={['required','password']}
              />
            </div>

                  {/* Snippet code section */}
             <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_Password}</CodeSnippet>      
            </div>  

            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>Number Input Demo:</label>
              <InputField
              name="Number"
              value={number}
              type="number"
              placeholder="Enter your Number"
              onChange={handleNumber}
              validators={['required']}
              />
            </div>
                  {/* Snippet code section */}
             <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_number}</CodeSnippet>      
            </div>  

            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>TextArea Input Demo:</label>
              <InputField
              name="Address"
              value={address}
              type="textarea"
              placeholder="Enter your Address"
              onChange={handleAddress}
              validators={['required']}
              />
            </div>
                  {/* Snippet code section */}
             <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_textarea}</CodeSnippet>      
            </div>  

            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>Select Input Demo:</label>
              <Select
              value={city}
              name="city"
              options={[
                        { value: 'india', label: 'India' },
                        { value: 'usa', label: 'USA' },
                        { value: 'uk', label: 'UK' },
                        { value: 'germany', label: 'Germany' },
                        { value: 'russia', label: 'Russia' },
                        { value: 'italy', label: 'Italy' },
                      ]}
              onChange={handleCity}
              />
            </div>
                  {/* Snippet code section */}
            <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_select}</CodeSnippet>      
            </div>  

            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>Multi-Select Demo:</label>
              <MultiSelect
              value={mutiCity}
              name="city"
              options={[
                        { value: 'india', label: 'India' },
                        { value: 'usa', label: 'USA' },
                        { value: 'uk', label: 'UK' },
                        { value: 'germany', label: 'Germany' },
                        { value: 'russia', label: 'Russia' },
                        { value: 'italy', label: 'Italy' },
                      ]}
              onChange={handleMulticity}
              />
            </div>
                  {/* Snippet code section */}
            <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_multiselect}</CodeSnippet>      
            </div>  

            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>Checkbox Demo:</label>
              <Checkbox
              name="hobby"
              options={['Cricket','Football','Hockey']}
              onChange={handleHobby}
              />
            </div>
                  {/* Snippet code section */}
             <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_checkbox}</CodeSnippet>      
            </div>  

            
            <div className='Demo section-div' style={{marginTop:'20px'}}>
              <label>Radio Input Demo:</label>
              <RadioInput
              name="gender"
              value={gender} 
              options={['Female','Male']}
              onChange={handleGender}
              />
            </div>
                  {/* Snippet code section */}
             <div className="section-C-Container section-div">
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code_radio}</CodeSnippet>      
            </div>  
            
          </div>  

 


    </div>
  )
}

export default FormComponentPage