import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import checkedIcon from './CheckedIcon.svg';

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 10px;

  @media(max-width: 864px){
    flex-direction: column;
  }
`;

const StyledCheckbox = styled.input`
  display: none;

  + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 2px solid rgba(0, 123, 255, 0.25);
      border-radius: 8px;
      background-color: #fff;
    }

    &:after {
      content: url(${checkedIcon});
      position: absolute;
      left: 2px;
      top: 2px;
      width: 18px;
      height: 18px;
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  &:checked + label:after {
    opacity: 1;
  }
  
  &:checked + label:before {
    background: blue;
  }
`;

const Checkbox = ({ name, options, onChange, checkboxStyles }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const checkboxesRef = useRef([]);

  const handleCheckboxChange = (value) => {
    const selectedIndex = selectedValues.indexOf(value);
    if (selectedIndex === -1) {
      setSelectedValues([...selectedValues, value]);
    } else {
      const updatedSelectedValues = selectedValues.filter((item) => item !== value);
      setSelectedValues(updatedSelectedValues);
    }

    setSelectedValues((updatedSelectedValues) => {
      onChange(updatedSelectedValues); // Call onChange with the updated values
      return updatedSelectedValues; // Return the updated values
    });
  };

  const handleKeyDown = (e, index) => {
    switch (e.key) {
      case 'ArrowRight':
        if (index < options.length - 1) {
          checkboxesRef.current[index + 1].focus();
        }
        break;
      case 'ArrowLeft':
        if (index > 0) {
          checkboxesRef.current[index - 1].focus();
        }
        break;
      case 'Enter':
      case ' ':
        handleCheckboxChange(options[index]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    checkboxesRef.current = checkboxesRef.current.slice(0, options.length);
  }, [options.length]);

  return (
    <CheckboxWrapper role="group" aria-labelledby={`${name}-label`}>
      {options.map((option, index) => (
        <div key={option}>
          <StyledCheckbox
            type="checkbox"
            id={option}
            name={name}
            value={option}
            checked={selectedValues.includes(option)}
            onChange={() => handleCheckboxChange(option)}
            style={checkboxStyles}
            aria-label={option}
            role="checkbox"
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (checkboxesRef.current[index] = el)}
          />
          <label htmlFor={option}>{option}</label>
          <br />
        </div>
      ))}
    </CheckboxWrapper>
  );
};

export default Checkbox;
