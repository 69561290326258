import React from 'react';
import { Skeleton as MUISkeleton } from '@mui/material';
import { Skeleton as AntSkeleton } from 'antd';

const Skeleton = ({
  type,
  SkeletonWidth,
  SkeletonHeight,
  SkeletonVariant,
  SkeletonAnimation,
  SkeletonActive,
  SkeletonColor,
  SkeletonHighlightColor,
  SkeletonStyle,
  ...props
}) => {
  switch (type) {
    case 'material':
      return (
        <MUISkeleton
          variant={SkeletonVariant}
          width={SkeletonWidth}
          height={SkeletonHeight}
          animation={SkeletonAnimation}
          sx={SkeletonStyle}
          {...props}
        />
      );
    case 'ant':
      const AntSkeletonStyled = AntSkeleton[SkeletonVariant];
      if(!AntSkeletonStyled){
        return false;
      }
      return (
        <AntSkeletonStyled
          active={SkeletonActive}
          paragraph={{ width: SkeletonWidth, rows: 1 }}
          title={false}
          style={SkeletonStyle}
          {...props}
        />
      );
    default:
      return null;
  }
};

export default Skeleton;
