import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IconButton, Drawer, List, ListItem, ListItemText, Collapse, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';
import ThemesToggleButton from '../../customComponents/Theme/ThemesToggleButton';

const Navbar = ({ navbarData, sidebarData }) => {
  const [open, setOpen] = useState(false);
  const [componentOpen, setComponentOpen] = useState(false);
  const [getStartedOpen, setGetStartedOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleComponentToggle = () => {
    setComponentOpen(!componentOpen);
  };

  const handleGetStartedToggle = () => {
    setGetStartedOpen(!getStartedOpen);
  };

  const { logo, companyName, menuItems, Home_link } = navbarData;
  const { components, menuItems: sidebarMenuItems, getStartedDetails } = sidebarData;

  return (
    <nav className="navbar1">
      <div className='logo-section'>
        <div onClick={handleDrawerToggle} className="menu-button">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
        </div>
        
        <div className='comp_logo_name_section'>
          {logo && <NavLink to={Home_link}><img src={logo} alt="Company Logo" className="logo" /></NavLink>}
          {companyName && <NavLink to={Home_link}><span className="company-name">{companyName}</span></NavLink>}
        </div>
      </div>

      <div className='menu-section1'>
        <ul className="nav-links">
          {menuItems.map(({ label, link }, index) => (
            <li key={index}>
              <NavLink to={link} activeClassName="active">
                {label}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className='theme'>
          <ThemesToggleButton />
        </div>
      </div>

      <Drawer
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        classes={{
          paper: 'drawer-paper',
        }}
      >
        <div className="drawer-header">
          {logo && <NavLink to={Home_link}><img src={logo} alt="Company Logo" className="logo" /></NavLink>}
          {companyName && <NavLink to={Home_link}><span className="company-name">{companyName}</span></NavLink>}
          <IconButton edge="end" color="inherit" aria-label="close" onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="drawer-content">
          <div className="scrollable-sidebar">
            <List>
              {sidebarMenuItems.map(({ label, link }, index) => (
                <ListItem key={index} button component={NavLink} to={link}>
                  <ListItemText primary={label} onClick={handleDrawerToggle} />
                </ListItem>
              ))}
            </List>
            <Divider style={{background:'gray'}} />
            <List>
              <ListItem button onClick={handleGetStartedToggle}>
                <ListItemText primary="Get Started" />
                {getStartedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Divider style={{background:'gray'}} />
              <Collapse in={getStartedOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {getStartedDetails.map((item, index) => (
                    <ListItem  key={index} button component={NavLink} to={item.link}>
                      <ListItemText primary={item.label} onClick={handleDrawerToggle} />
                    </ListItem>
                  ))}
                </List>
                <Divider style={{background:'gray'}} />
              </Collapse>
              <ListItem button onClick={handleComponentToggle}>
                <ListItemText primary="Components" />
                {componentOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Divider style={{background:'gray'}} />
              <Collapse in={componentOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {components.map((component, index) => (
                    <ListItem key={index} button component={NavLink} to={component.link}>
                      <ListItemText primary={component.label} onClick={handleDrawerToggle} />
                    </ListItem>
                  ))}
                </List>
                <Divider style={{background:'gray'}} />
              </Collapse>
            </List>
          </div>
        </div>
      </Drawer>

      {open && (
        <div className="backdrop" onClick={handleDrawerToggle} />
      )}
    </nav>
  );
};

export default Navbar;
