import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';

const ModalPage = () => {

  const initialCode = `
  <Button onClick={openModal}>Open Modal</Button>
  <Modal isOpen={isModalOpen} onClose={closeModal}>
    <ModalHeader onClose={closeModal}>
        <Typography variant="h4">Heading of Modal</Typography>
    </ModalHeader>
    <ModalBody>
        <Typography variant="body">If we add display: flex to a container, the child items all become flex items arranged in a row. They will all stretch to be as tall as the tallest item, as that item is defining the height of the items on the cross axis.</Typography>
    </ModalBody>
    <ModalFooter>
        <Button variant="danger" onClick={closeModal}>Close</Button>
        <Button variant="success">Save</Button>
    </ModalFooter>
</Modal>

  `;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  

  const snipet_code = `
    import React, { useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from './customComponents/Modal/Modal'; 
import Typography from './customComponents/Typography/Typography';
import Button from './customComponents/Button/Button';

const App = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <button onClick={openModal}>Open Modal</button>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ModalHeader onClose={closeModal}>
                    <Typography variant="h2">Heading of Modal</Typography>
                </ModalHeader>
                <ModalBody>
                    <Typography variant="body">If we add display: flex to a container, the child items all become flex items arranged in a row. They will all stretch to be as tall as the tallest item, as that item is defining the height of the items on the cross axis. </Typography>
                </ModalBody>
                <ModalFooter>
                    <Button variant="danger" onClick={closeModal}>Close</Button>
                    <Button variant="success">Save</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default App;

  `;


  // For Alert message
  const initialCode_alertM = `
                <div>
                <AlertMessage 
                icon={ <Icon IconName="CheckCircleOutlined" IconType="ant" />}
                message="Success! Your changes have been saved." 
                variant="success" 
                fontFamily="Arial" />
                <br/>

                <AlertMessage
                 icon={ <Icon IconName="CloseCircleOutlined" IconType="ant" IconColor={'red'} />}
                 message="Warning! This action cannot be undone." 
                 variant="warning" 
                 fontFamily="Verdana" />
                 <br/>

                <AlertMessage 
                icon={ <Icon IconName="CloseCircleOutlined" IconType="ant" IconColor={'red'} />}
                message="Error! Something went wrong."
                variant="error" 
                fontFamily="Courier New" />
                </div>


  `;

  const [code_alertM, setCode_alertM] = useState(initialCode_alertM);
  const handleCodeChange_alertM = (newCode) => {
    setCode_alertM(newCode);
  };

  

  const snipet_code_alertM = `
import React, { useState } from 'react';
import AlertMessage from './customComponents/Modal/AllertMessage';
import Icon from './customComponents/Icon/Icon';

const App = () => {
    return (
        <div>
                <div>
                <AlertMessage 
                icon={ <Icon IconName="CheckCircleOutlined" IconType="ant" />}
                message="Success! Your changes have been saved." 
                variant="success" 
                fontFamily="Arial" />

                <AlertMessage
                 icon={ <Icon IconName="CloseCircleOutlined" IconType="ant" IconColor={'red'} />}
                 message="Warning! This action cannot be undone." 
                 variant="warning" 
                 fontFamily="Verdana" />

                <AlertMessage 
                icon={ <Icon IconName="CloseCircleOutlined" IconType="ant" IconColor={'red'} />}
                message="Error! Something went wrong."
                variant="error" 
                fontFamily="Courier New" />
                </div>

        </div>
    );
};

export default App;

  `;


   // For Alert Dialog
   const initialCode_alertD = `
   <div>
      <Button onClick={OpenDialog}>Open Dialog</Button>
      <AlertDialogs isOpen={isOpenDialog} onOpen={OpenDialog}>
        <h2>This is the dialog content</h2>
        <br/>
        <p>You can put any content you want inside the dialog.</p>
        <br/>
        <Button variant="success" onClick={CloseDialog}>Agree</Button>
        <Button variant="danger" onClick={CloseDialog}>Disagree</Button>
      </AlertDialogs>
    </div>
`;

const [code_alertD, setCode_alertD] = useState(initialCode_alertD);
const handleCodeChange_alertD = (newCode) => {
setCode_alertD(newCode);
};



const snipet_code_alertD = `
import React, { useState } from 'react';
import AlertDialogs from './customComponents/Modal/AlertDialogs';

const App = () => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const OpenDialog = () => {
    setIsOpenDialog(true);
  };

  const CloseDialog = () => {
    setIsOpenDialog(false);
  };

  return (
    <div>
      <Button onClick={OpenDialog}>Open Dialog</Button>
      <AlertDialogs isOpen={isOpenDialog} onOpen={OpenDialog}>
        <h2>This is the dialog content</h2>
        <br/>
        <p>You can put any content you want inside the dialog.</p>
        <br/>
        <Button variant="success">Agree</Button>
        <Button variant="danger" onClick={CloseDialog}>Disagree</Button>
      </AlertDialogs>
    </div>
  );
};

export default App;


`;


// Notification
   const initialCode_N = `
   <div>
   <Notification 
   message="Success notification" 
   type="success"
   icon={ <Icon IconName="CheckCircleOutlined" IconType="ant" />}/>
   </div>
   <br/>

    <div>
   <Notification 
   message="Warning notification" 
   type="warning"
   icon={ <Icon IconName="CloseCircleOutlined" IconType="ant" />}/>
   </div>
   <br/>

    <div>
   <Notification 
   message="Error notification" 
   type="error"
   icon={ <Icon IconName="CloseCircleOutlined" IconType="ant" />}/>
   </div>
`;

const [code_N, setCode_N] = useState(initialCode_N);
const handleCodeChange_N = (newCode) => {
setCode_N(newCode);
};



const snipet_code_N = `
import React, { useState } from 'react';
import Notification from './customComponents/Modal/Notification';
import Icon from './customComponents/Icon/Icon';

const App = () => {
    return (
        <div>
            <div>
              <Notification 
              message="Success notification" 
              type="success"
              icon={ <Icon IconName="CheckCircleOutlined" IconType="ant" />}/>
              </div>
              <br/>

                <div>
              <Notification 
              message="Warning notification" 
              type="warning"
              icon={ <Icon IconName="CloseCircleOutlined" IconType="ant" />}/>
              </div>
              <br/>

                <div>
              <Notification 
              message="Error notification" 
              type="error"
              icon={ <Icon IconName="CloseCircleOutlined" IconType="ant" />}/>
              </div>
    );
};

export default App;


`;


// End

  // State to track whether initial highlight should be shown
  const [showInitialHighlight, setShowInitialHighlight] = useState(true);

  // Function to handle hover effect (remove initial highlight)
  const handleMouseEnter = () => {
    setShowInitialHighlight(true);
  };

  // Function to handle leaving hover effect (restore initial highlight if needed)
  const handleMouseLeave = () => {
    setShowInitialHighlight(false);
  }; 

  return (
    <div className="wrapper-container">
       <ScrollButton/>
       {/*Modal component*/}
        <div>
            {/* Heading */}
      <div className='Header-section'>
        <center><h2>Modal / Alert / Notification</h2></center>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>Modal</h3>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>The <strong>Modal Component</strong>  in React is a versatile UI element used to display content in a layer above the main application. It consists of several parts: Modal (the main container), ModalHeader (for the title and close button), ModalBody (for the main content), and ModalFooter (for actions like buttons). The modal supports various props for customization, including isOpen, onClose, bgColor, borderRadius, boxShadow, display, and customCss, allowing you to tailor its appearance and behavior to your needs</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Default Value</th>
                  <th className="table-header">Description</th>
                  <th className="table-header">Example</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">isOpen</td>
                  <td className="table-data">boolean</td>
                  <td className="table-data">false</td>
                  <td className="table-data">Determines whether the modal is open.</td>
                  <td className="table-data">{`isOpen={true}`}</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">onClose</td>
                  <td className="table-data">function</td>
                  <td className="table-data">N/A</td>
                  <td className="table-data">Callback function to close the modal.</td>
                  <td className="table-data">{`onClose={closeModal}`}</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalWidth</td>
                  <td className="table-data">string</td>
                  <td className="table-data">'500px'</td>
                  <td className="table-data">Width of the modal content.</td>
                  <td className="table-data">ModalWidth="600px"</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalBgColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">'#fff'</td>
                  <td className="table-data">Background color of the modal content.</td>
                  <td className="table-data">ModalBgColor="#f0f0f0"</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalBorderRadius</td>
                  <td className="table-data">string</td>
                  <td className="table-data">'8px'</td>
                  <td className="table-data">Border radius of the modal content.</td>
                  <td className="table-data">ModalBorderRadius="10px"</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalfontSize</td>
                  <td className="table-data">string</td>
                  <td className="table-data">'inherit'</td>
                  <td className="table-data">Font size of the modal content.</td>
                  <td className="table-data">ModalfontSize="16px"</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalboxShadow</td>
                  <td className="table-data">string</td>
                  <td className="table-data">'0 4px 8px rgba(0, 0, 0, 0.2)'</td>
                  <td className="table-data">Shadow effect of the modal content.</td>
                  <td className="table-data">ModalboxShadow="0 0 10px rgba(0,0,0,0.5)"</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">Modaldisplay</td>
                  <td className="table-data">string</td>
                  <td className="table-data">'flex'</td>
                  <td className="table-data">Display property of the modal content.</td>
                  <td className="table-data">Modaldisplay="flex"</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalTopPosition</td>
                  <td className="table-data">string</td>
                  <td className="table-data">'2vh'</td>
                  <td className="table-data">Position from the top of the modal.</td>
                  <td className="table-data">ModalTopPosition="10vh"</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalcustomCss</td>
                  <td className="table-data">object</td>
                  <td className="table-data">N/A</td>
                  <td className="table-data">Custom CSS styles for the modal content.</td>
                  <td className="table-data">{`ModalcustomCss={{ padding: '2rem' }}`}</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalHeadercustomCss</td>
                  <td className="table-data">object</td>
                  <td className="table-data">N/A</td>
                  <td className="table-data">Custom CSS styles for the modal header.</td>
                  <td className="table-data">{`ModalHeadercustomCss={{ fontSize: '20px' }}`}</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalBodycustomCss</td>
                  <td className="table-data">object</td>
                  <td className="table-data">N/A</td>
                  <td className="table-data">Custom CSS styles for the modal body.</td>
                  <td className="table-data">{`ModalBodycustomCss={{ color: 'red' }}`}</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">ModalFootercustomCss</td>
                  <td className="table-data">object</td>
                  <td className="table-data">N/A</td>
                  <td className="table-data">Custom CSS styles for the modal footer.</td>
                  <td className="table-data">{`ModalFootercustomCss={{ textAlign: 'center' }}`}</td>
                </tr>
              </tbody>
            </table>
          </div>

      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
      </div>
        </div>

       {/* End */}

       {/* Alert message */}
        <div>

            {/* Heading */}
      <div className='Header-section'>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>Alert Message</h3>
        </center>
         <div className="content-section section-div">
            <p><strong>Alert Messages</strong> provide immediate feedback to users about system events or actions, such as success, error, or warning notifications. They are typically brief and non-intrusive, appearing momentarily at the top or bottom of the screen.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code_alertM} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange_alertM}>
                {code_alertM}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Required</th>
                  <th className="table-header">Description</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">message</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Yes</td>
                  <td className="table-data">The message to be displayed in the alert.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">icon</td>
                  <td className="table-data">node</td>
                  <td className="table-data">No</td>
                  <td className="table-data">An optional icon to be displayed next to the message.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">variant</td>
                  <td className="table-data">oneOf</td>
                  <td className="table-data">No</td>
                  <td className="table-data">The variant of the alert, determining its background and text color. Possible values: 'success', 'warning', 'error'.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">fontSize</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">The font size of the alert message.</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">fontFamily</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">The font family of the alert message.</td>
                </tr>
              </tbody>
            </table>
          </div>

      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code_alertM}</CodeSnippet>      
      </div>
        </div>

       {/* End */}

       {/* ALert dialogs */}
        <div>
            {/* Heading */}
      <div className='Header-section'>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>Alert Dialogs</h3>
        </center>
         <div className="content-section section-div">
            <p><strong>Alert Dialogs</strong> require user interaction, often used to confirm or cancel actions. They overlay the main content and usually include options like "OK" or "Cancel" for user response.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code_alertD} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange_alertD}>
                {code_alertD}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Required</th>
                  <th className="table-header">Description</th>
                  <th className="table-header">Example</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">children</td>
                  <td className="table-data">node</td>
                  <td className="table-data">Yes</td>
                  <td className="table-data">The content to be displayed inside the alert dialog.</td>
                  <td className="table-data">&lt;p&gt;This is an alert!&lt;/p&gt;</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">onClick</td>
                  <td className="table-data">func</td>
                  <td className="table-data">Yes</td>
                  <td className="table-data">The function to handle the closing action of the dialog.</td>
                  <td className="table-data">{`() => handleClose()`}</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">isOpen</td>
                  <td className="table-data">bool</td>
                  <td className="table-data">Yes</td>
                  <td className="table-data">Determines whether the alert dialog is open or closed.</td>
                  <td className="table-data">true or false</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">DialogBgColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Background color of the dialog content.</td>
                  <td className="table-data">'lightgray'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">DialogTextColor</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Text color of the dialog content.</td>
                  <td className="table-data">'black'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">DialogFontFamily</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Font family of the dialog content.</td>
                  <td className="table-data">'Arial'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">DialogFontSize</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Font size of the dialog content.</td>
                  <td className="table-data">'16px'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">DialogFontWeight</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Font weight of the dialog content.</td>
                  <td className="table-data">'bold'</td>
                </tr>
              </tbody>
            </table>
          </div>


      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code_alertD}</CodeSnippet>      
      </div>
        </div>
       {/* End */}

       {/* Notification */}
       <div>

          {/* Heading */}
      <div className='Header-section'>
        <center>
           <h3 style={{marginBottom:'2rem',marginTop:'2rem',color: 'blue'}}>Notification</h3>
        </center>
         <div className="content-section section-div">
            <p><strong>Notifications</strong> are messages designed to inform users about important events or updates. They can be transient pop-ups or persistent messages and often appear at the top or bottom of the screen. Notifications can convey information about system statuses, reminders, incoming messages, or other significant events, ensuring users are kept informed without interrupting their workflow.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code_N} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange_N}>
                {code_N}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
            <table className="styled-table">
              <thead className="table-head">
                <tr className="">
                  <th className="table-header">Prop</th>
                  <th className="table-header">Type</th>
                  <th className="table-header">Required</th>
                  <th className="table-header">Description</th>
                  <th className="table-header">Example</th>
                </tr>
              </thead>
              <tbody className="table-body">
                <tr className="table-row">
                  <td className="table-data">message</td>
                  <td className="table-data">string</td>
                  <td className="table-data">Yes</td>
                  <td className="table-data">The message to be displayed in the notification.</td>
                  <td className="table-data">"Operation successful!"</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">type</td>
                  <td className="table-data">oneOf(['success', 'warning', 'error'])</td>
                  <td className="table-data">No</td>
                  <td className="table-data">The type of notification, determining its background color.</td>
                  <td className="table-data">'success'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">icon</td>
                  <td className="table-data">node</td>
                  <td className="table-data">No</td>
                  <td className="table-data">An optional icon to be displayed alongside the message.</td>
                  <td className="table-data">&lt;IconComponent /&gt;</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">className</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Optional class name for custom styling.</td>
                  <td className="table-data">'my-notification'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">Notificationwidth</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Custom width of the notification.</td>
                  <td className="table-data">'300px'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">NotificationfontSize</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Custom font size of the notification text.</td>
                  <td className="table-data">'16px'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">NotificationfontFamily</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Custom font family of the notification text.</td>
                  <td className="table-data">'Arial'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">NotificationfontWeight</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Custom font weight of the notification text.</td>
                  <td className="table-data">'bold'</td>
                </tr>
                <tr className="table-row">
                  <td className="table-data">NotificationcustomCss</td>
                  <td className="table-data">string</td>
                  <td className="table-data">No</td>
                  <td className="table-data">Custom CSS for additional styles.</td>
                  <td className="table-data">'background-color: lightblue;'</td>
                </tr>
              </tbody>
            </table>
          </div>

      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code_N}</CodeSnippet>      
      </div>
       </div>

       {/* End */}
    </div>
  )
}

export default ModalPage