import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';


const ButtonPage = () => {
  const initialCode = `
  <Button variant="primary" textColor="white" onClick={haddleButton}>Primary</Button>
  <Button variant="secondary" textColor="white" onClick={haddleButton}>secondary</Button>
  <Button variant="danger" textColor="white" onClick={haddleButton}>danger</Button>
  <Button variant="success" textColor="white" onClick={haddleButton}>danger</Button>
  <Button variant="warning" textColor="black" onClick={haddleButton}>warning</Button>
  `;
  const [code, setCode] = useState(initialCode);
  const handleButton = () => alert('Hello,I am button');

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    console.log(newCode);
  };
  

  

  const snipet_code = `
  import React from 'react';
  import Button from './Components/Button/Button';
  const App = () => {
    return (
      <>
        <Button variant="primary" textColor="white" onClick={haddleButton}>Primary</Button>
        <Button variant="secondary" textColor="white" onClick={haddleButton}>secondary</Button>
        <Button variant="danger" textColor="white" onClick={haddleButton}>danger</Button>
        <Button variant="success" textColor="white" onClick={haddleButton}>danger</Button>
        <Button variant="warning" textColor="black" onClick={haddleButton}>warning</Button>
      </>
    );
  };
  export default App;
  `;
   // State to track whether initial highlight should be shown
   const [showInitialHighlight, setShowInitialHighlight] = useState(true);

   // Function to handle hover effect (remove initial highlight)
   const handleMouseEnter = () => {
     setShowInitialHighlight(true);
   };
 
   // Function to handle leaving hover effect (restore initial highlight if needed)
   const handleMouseLeave = () => {
     setShowInitialHighlight(false);
   };

  return (
    <div className="wrapper-container">
       <ScrollButton/>
        {/* Heading */}
      <div className='Header-section'>
           <center>
            <h2>Button</h2>
          </center>


         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>Buttons allow users to take actions, and make choices, with a single tap. Buttons communicate actions that users can take. They are typically placed throughout your UI, in places like:</p>

            <div style={{marginLeft:'2rem',marginBottom:'2rem'}}>
            <ul>
              <li>Modal windows</li>
              <li>Forms</li>
              <li>Cards</li>
              <li>Toolbars</li>
            </ul>
            </div>
      
            <p>There are five variants in this button component</p>
            <div style={{marginLeft:'2rem',marginBottom:'2rem'}}>
            <ul>
              <li>primary</li>
              <li>secondary</li>
              <li>success</li>
              <li>danger</li>
              <li>warning</li>
            </ul>
            </div>
         </div>

      </div>
     

     {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
           <div className='width-100'>
              <Preview code={code} handle={handleButton} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

      

       {/* Props details section */}
       <div className="section-B-Container section-div"> 
       <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

       <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">Prop</th>
                <th className="table-header">Type</th>
                <th className="table-header">Default</th>
                <th className="table-header">Description</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">variant</td>
                <td className="table-data">string</td>
                <td className="table-data">'default'</td>
                <td className="table-data">Defines the button style. Possible values: 'primary', 'secondary', 'success', 'danger', 'warning', 'dark', 'default'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">fontSize</td>
                <td className="table-data">string | number</td>
                <td className="table-data">'md'</td>
                <td className="table-data">Sets the font size. Possible string values: 'sm', 'md', 'lg', 'xl'. If a number is provided, it is converted to rem units.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">textColor</td>
                <td className="table-data">string</td>
                <td className="table-data">undefined</td>
                <td className="table-data">Sets the text color of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">fontWeight</td>
                <td className="table-data">string</td>
                <td className="table-data">undefined</td>
                <td className="table-data">Sets the font weight. Possible values: '1', '2', '3', '4', '5'.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">border</td>
                <td className="table-data">string</td>
                <td className="table-data">'none'</td>
                <td className="table-data">Sets the border style of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">borderR</td>
                <td className="table-data">string</td>
                <td className="table-data">'4px'</td>
                <td className="table-data">Sets the border radius of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">outline</td>
                <td className="table-data">string</td>
                <td className="table-data">'none'</td>
                <td className="table-data">Sets the outline style of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">marginL</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px'</td>
                <td className="table-data">Sets the left margin of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">marginR</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px'</td>
                <td className="table-data">Sets the right margin of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">marginT</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px'</td>
                <td className="table-data">Sets the top margin of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">marginB</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px'</td>
                <td className="table-data">Sets the bottom margin of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">paddingL</td>
                <td className="table-data">string</td>
                <td className="table-data">'14px'</td>
                <td className="table-data">Sets the left padding of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">paddingR</td>
                <td className="table-data">string</td>
                <td className="table-data">'14px'</td>
                <td className="table-data">Sets the right padding of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">paddingT</td>
                <td className="table-data">string</td>
                <td className="table-data">'8px'</td>
                <td className="table-data">Sets the top padding of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">paddingB</td>
                <td className="table-data">string</td>
                <td className="table-data">'8px'</td>
                <td className="table-data">Sets the bottom padding of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">disabled</td>
                <td className="table-data">boolean</td>
                <td className="table-data">false</td>
                <td className="table-data">Disables the button if true.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">shadowX</td>
                <td className="table-data">string</td>
                <td className="table-data">'0px'</td>
                <td className="table-data">Sets the horizontal shadow offset of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">shadowY</td>
                <td className="table-data">string</td>
                <td className="table-data">'2px'</td>
                <td className="table-data">Sets the vertical shadow offset of the button.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">shadowB</td>
                <td className="table-data">string</td>
                <td className="table-data">'5px'</td>
                <td className="table-data">Sets the blur radius of the button shadow.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">shadowColor</td>
                <td className="table-data">string</td>
                <td className="table-data">'rgba(0, 0, 0, 0.9)'</td>
                <td className="table-data">Sets the color of the button shadow.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">height</td>
                <td className="table-data">number</td>
                <td className="table-data">2</td>
                <td className="table-data">Sets the height of the button in rem units.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">width</td>
                <td className="table-data">number</td>
                <td className="table-data">8</td>
                <td className="table-data">Sets the width of the button in rem units.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">fontFamily</td>
                <td className="table-data">string</td>
                <td className="table-data">'Arial, sans-serif'</td>
                <td className="table-data">Sets the font family of the button text.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">hover</td>
                <td className="table-data">boolean</td>
                <td className="table-data">true</td>
                <td className="table-data">Enables hover effect if true.</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">onClick</td>
                <td className="table-data">function</td>
                <td className="table-data"></td>
                <td className="table-data">Enables to pass the event handle.</td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>


     {/* Snippet code section */}
     <div className="section-C-Container section-div">
      <p>Using this component in any page, full code snippet.</p><br/>
      <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>   
      </div> 
      
    </div>
  )
}


export default ButtonPage