import React,{useState} from 'react';
import './Component.css';
import ScrollButton from '../Get-Started/ScrollButton';


const Component = () => {
   // State to track whether initial highlight should be shown
   const [showInitialHighlight, setShowInitialHighlight] = useState(true);

   // Function to handle hover effect (remove initial highlight)
   const handleMouseEnter = () => {
     setShowInitialHighlight(true);
   };
 
   // Function to handle leaving hover effect (restore initial highlight if needed)
   const handleMouseLeave = () => {
     setShowInitialHighlight(false);
   };
  return (
    <div class="container">
       <ScrollButton/>
        <header class="header">
            <h1>Atomic-Fusion Component Library</h1>
        </header>
        <main style={{padding:'4rem 6rem'}} className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <p style={{marginTop:'2rem'}}>Atomic-Fusion UI Library is an innovative and comprehensive collection of React components designed to streamline the development process and enhance user experience across web applications. By leveraging modern design principles and robust engineering practices, Atomic-Fusion provides developers with a toolkit that is both powerful and easy to use, ensuring that projects are not only visually appealing but also highly functional and performant.</p>
          <br/>
          <p>One of the standout features of Atomic-Fusion is its extensive collection of pre-built components. These include fundamental elements like buttons and typography, as well as more complex components such as charts, modals, loaders, and progress bars. Additionally, it offers interactive elements like accordions, breadcrumbs, alerts, alert dialogs, and various form components. Each component is meticulously crafted to be customizable and themeable, allowing developers to easily align them with the specific design requirements of their projects. Furthermore, the library is fully responsive, ensuring that applications built with Atomic-Fusion look and function flawlessly on all devices.</p>

          <br/>
          <p>The library also places a strong emphasis on accessibility and performance. Every component in Atomic-Fusion is designed with accessibility in mind, adhering to ARIA standards and best practices to ensure that applications are usable by all individuals, including those with disabilities. Additionally, the components are optimized for performance, with minimal overhead and efficient rendering, resulting in fast and responsive user interfaces.</p>
        </main>
    </div>
  )
}

export default Component