import React from 'react';
import './About.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputField from '../../customComponents/Form-components/InputField/InputField';
import Button from '../../customComponents/Button/Button';
const About = () => {
  return (
    <div className='WrapperContainer'>
      
    
      <div className='container' style={{background:'transparent',minHeight:'300px',marginBottom:'20px'}}>
       <div className='row'>
        <div className='col-6'>
        <h2>About Us</h2>
        <p>Welcome to [Your Company Name], a leading innovator in [Your Industry]. Our journey began with a single, transformative idea: to revolutionize the way [your industry or niche] operates. Since our inception, we've been driven by a commitment to excellence, innovation, and customer satisfaction.</p>
        </div>
        <div className='col-6'>
        </div>
       </div>
      </div>

      <div className='container' style={{background:'transparent',minHeight:'300px',marginBottom:'20px'}}>
       <div className='row'>
        <div className='col-6'>
        </div>
        <div className='col-6'>
        <h2>Our Mission</h2>
        <p>At [Your Company Name], our mission is to provide exceptional [products/services] that meet the highest standards of quality and innovation. We strive to create solutions that not only meet but exceed the expectations of our clients, helping them achieve their goals and enhance their lives.</p>
        </div>
       </div>
      </div>

      <div className='container' style={{background:'transparent',minHeight:'300px',marginBottom:'20px'}}>
       <div className='row'>
        <div className='col-6'>
        <h2>Our Vision</h2>
        <p>We envision a world where [your industry or niche] is seamlessly integrated into daily life, making it more accessible, efficient, and enjoyable for everyone. By leveraging cutting-edge technology and innovative design, we aim to set new standards and lead the way in [your industry]</p>
        </div>
        <div className='col-6'>
        </div>
       </div>
      </div>

      <div className='container'style={{background:'transparent',minHeight:'300px'}}>
        <div className='row' style={{paddingTop:'30px',paddingBottom:'30px'}}>
          <div className='col-3'></div>
          <div className='col-6'>
            <center><h1>CONTACT US</h1></center>
            <form>
                <label for="exampleInputEmail1">Name</label>
                <InputField
                      name="name"
                      type="text"
                      placeholder="Enter your Name"
                      validators={['required']}
                />
              
              <div className="form-group">
                <label for="exampleInputEmail1">Email</label>
                <InputField
                      name="email"
                      type="email"
                      placeholder="Enter your Email"
                      validators={['required']}
                />
                
              </div>
              <div className="form-group">
                <label for="exampleInputEmail1">Contact No.</label>
                <InputField
                      name="number"
                      type="number"
                      placeholder="Enter your Number"
                      validators={['required']}
                />
                
              </div>
              <div className="form-group">
                <label for="exampleInputEmail1">Comments</label>
                <InputField
                      name="comment"
                      type="textarea"
                      textareaRow="10"
                      placeholder="Enter your Comments"
                      validators={['required']}
                />
              </div>
              <center><Button variant="success">Submit</Button></center>
            </form>
          </div>
          <div className='col-3'></div>
        </div>
      </div>

      


      </div>
  
  )
}


export default About