import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const LinearProgressBar = ({ totalSize,Labeldisplay=true, downloadedSize, ...props }) => {
  const [value, setValue] = useState(0);
  const [LabelVisible]=useState(Labeldisplay);

  useEffect(() => {
    const percentage = (downloadedSize / totalSize) * 100;
    setValue(percentage);
  }, [downloadedSize, totalSize]);

  const getColorForValue = (value) => {
    if (value <= 25) return props.progressfillBgColor || 'green'; 
    if (value <= 50) return props.progressfillBgColor || 'green';
    if (value <= 75) return props.progressfillBgColor || 'green';
    return props.progressfillBgColor || 'green';
  };

  return (
    <ProgressContainer tabIndex={0} aria-label='Linear Progress Bar' {...props}>
      <LinearProgress {...props}>
        <LinearBackground {...props} />
        <LinearProgressBarFill
          width={value}
          fillColor={getColorForValue(value)}
          {...props}
        />
        {LabelVisible?<LinearText {...props}>{`${value.toFixed(2)}%`}</LinearText>:''}
      </LinearProgress>
    </ProgressContainer>
  );
};

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
`;

const LinearProgress = styled.div`
  width: 100%;
  height: ${(props) => `${props.progressHeight || '30px'}`};
  position: relative;
  background-color: #e6e6e6;
  border-radius: ${(props) => `${props.progressBorderRadius || '15px'}`};
  overflow: hidden;
  margin-top: 20px;
`;

const LinearBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => `${props.progressBeforefillBgColor || '#e6e6e6'}`};
`;

const LinearProgressBarFill = styled.div`
  position: absolute;
  height: 100%;
  background-color: ${(props) => props.fillColor};
  width: ${({ width }) => width}%;
  transition: width 0.35s;
`;

const LinearText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: ${(props) => `${props.textAlign || 'center'}`};
  font-size: ${(props) => `${props.fontSize || '1.2em'}`};
  font-weight: ${(props) => `${props.fontWeight || 'bold'}`};
  color: ${(props) => `${props.textColor || 'white'}`};
`;

export default LinearProgressBar;
