import React,{useState} from 'react';
import styled from 'styled-components';
import { LiveProvider,LivePreview } from 'react-live';
import Button from '../../customComponents/Button/Button';
import Typography from '../../customComponents/Typography/Typography'
import Accordion from '../../customComponents/Accordian/Accordion';
import Card,{CardHeader,CardBody,CardFooter, CardContainer} from '../../customComponents/Card/Card';
import Chart from '../../customComponents/ChartAndGraphs/Chart';
import DatePicker from '../../customComponents/DatePicker/DatePicker';
import CheckBox from '../../customComponents/Form-components/CheckBox/CheckBox'
import SelectInput from '../../customComponents/Form-components/Dropdown/SelectInput';
import InputField from '../../customComponents/Form-components/InputField/InputField';
import RadioInput from '../../customComponents/Form-components/RadioBox/RadioInput';
import Icon from '../../customComponents/Icon/Icon';
import CircleProgressBar from '../../customComponents/Loader/CircleProgressBar';
import LinearProgressBar from '../../customComponents/Loader/LinearProgressBar';
import Loader from '../../customComponents/Loader/Loader';
import Skeleton from '../../customComponents/Loader/Skeleton';
import AlertDialogs from '../../customComponents/Modal/AlertDialogs';
import AlertMessage from '../../customComponents/Modal/AlertMessage';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../customComponents/Modal/Modal';
import Notification from '../../customComponents/Modal/Notification';
import BreadCrumbs from '../../customComponents/Navigations/BreadCrumbs';
import Navbar from '../../customComponents/Navigations/Navbar';
import Pagination from '../../customComponents/Navigations/Pagination';
import Tabs from '../../customComponents/Navigations/Tabs';
import SliderPicker from '../../customComponents/Slider And Range Picker/SliderPicker';
import RangePicker from '../../customComponents/Slider And Range Picker/RangePicker';
import Table from '../../customComponents/Table/Table';
import Tooltip from '../../customComponents/Tooltips/Tooltips';
import Popover from '../../customComponents/Tooltips/Popover';



const componentMap = {
  Button: Button,
  Typography: Typography,
  Accordion: Accordion,
  CardContainer:CardContainer,
  CardHeader:CardHeader,
  CardBody:CardBody,
  CardFooter:CardFooter,
  Chart: Chart,
  DatePicker: DatePicker,
  CheckBox: CheckBox,
  SelectInput: SelectInput,
  InputField: InputField,
  RadioInput: RadioInput,
  Icon: Icon,
  CircleProgressBar: CircleProgressBar,
  LinearProgressBar: LinearProgressBar,
  Loader: Loader,
  Skeleton: Skeleton,
  AlertDialogs: AlertDialogs,
  AlertMessage: AlertMessage,
  Modal: Modal,
  ModalFooter:ModalFooter,
  ModalHeader:ModalHeader,
  ModalBody:ModalBody,
  Notification: Notification,
  BreadCrumbs: BreadCrumbs,
  Navbar: Navbar,
  Pagination: Pagination,
  Tabs: Tabs,
  SliderPicker: SliderPicker,
  RangePicker: RangePicker,
  Table: Table,
  Tooltip: Tooltip,
  Popover: Popover
  //Add more components
};

const Preview = ({ code, handle, onChange,customCssPreview,...props }) => {
  // Modal----------------
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
        setIsModalOpen(true);
    };
     
  const closeModal = () => {
        setIsModalOpen(false);
    };

    // End------------------


    // AlertDialog----------------
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    const OpenDialog = () => {
      setIsOpenDialog(true);
    };
  
    const CloseDialog = () => {
      setIsOpenDialog(false);
    };

    // End-----------------===

    // Pagination-------
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    // End---------


    // Navbar----------
    const logo = (
      <img
        src="https://media.istockphoto.com/id/1331491686/vector/element-design.jpg?s=612x612&w=0&k=20&c=QIMRS2IPiQyyTZJR_G1JAjH_ErBBkrDPtQe2GBNgm2w="
        alt="Logo"
      />
    );

    // End==========
  

  
  const input = `<div> ${code}</div>`;
  const haddleButton = handle;
  const handleDateChange = onChange;
  const handleRangeChange = onChange;

  return (
    <Container>
      <Heading>Preview</Heading>
      <ContentWrapper>
        <LiveProvider code={input} scope={{
           ...componentMap, 
           haddleButton, 
           handleDateChange, 
           handleRangeChange,
           isModalOpen,
           openModal,
           closeModal,
           isOpenDialog,
           OpenDialog,
           CloseDialog,
           currentPage,
           handlePageChange,
           logo,
           ...props }}>
          <LivePreview style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',opacity:'none',background:'white',minHeight:'80px',customCssPreview}}/>
        </LiveProvider>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 0px;
  color: black;
  background-color: white;
  border-radius: 8px;
  font-family: 'Courier New', Courier, monospace;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity:2;
`;

const Heading = styled.h3`
  margin: 0;
  font-size: 18px;
  padding: 10px;
  background: gray;
  color: white;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-direction: ${props => props.justifyContent || 'column'}; 
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'flex-start'};
  width: 100%;
  height: 100%;
  min-height:200px;
  overflow-x: auto; /* Add this line to enable horizontal scrolling */
  box-sizing: border-box;
`;

export default Preview;
