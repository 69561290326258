import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const PopoverText = styled.div`
  --message-background: ${({ messageBgColor }) => messageBgColor || 'gray'};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: ${({ messageWidth }) => messageWidth || '120px'};
  background-color: var(--message-background);
  color: ${({ messageTextColor }) => messageTextColor || '#fff'};
  font-family: ${({ messageFontFamily }) => messageFontFamily || 'inherit'};
  font-size: ${({ messageFontSize }) => messageFontSize || 'inherit'};
  font-weight: ${({ messageFontWeight }) => messageFontWeight || 'inherit'};
  text-align: ${({ messageTextAlign }) => messageTextAlign || 'left'};
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 3;
  opacity:${({ messageOpacity }) => messageOpacity};
  ${({ position }) => getPositionStyles(position)}

  &::after {
    content: '';
    position: absolute;
    border-style: solid;
  }
`;

const getPositionStyles = (position) => {
  switch (position) {
    case 'top':
      return css`
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        &::after {
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-width: 5px 5px 0;
          border-color: var(--message-background) transparent transparent transparent;
        }
      `;
    case 'top-left':
      return css`
        bottom: calc(100% + 5px);
        left: 5px;
        transform: translateX(0);
        &::after {
          top: 100%;
          left: 15%;
          transform: translateX(0);
          border-width: 5px 5px 0;
          border-color: var(--message-background) transparent transparent transparent;
        }
      `;
    case 'top-right':
      return css`
        bottom: calc(100% + 5px);
        right: 5px;
        transform: translateX(0);
        &::after {
          top: 100%;
          right: 15%;
          transform: translateX(0);
          border-width: 5px 5px 0;
          border-color: var(--message-background) transparent transparent transparent;
        }
      `;
    case 'bottom':
      return css`
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        &::after {
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent var(--message-background) transparent;
        }
      `;
    case 'bottom-left':
      return css`
        top: calc(100% + 5px);
        left: 5px;
        transform: translateX(0);
        &::after {
          bottom: 100%;
          left: 15%;
          transform: translateX(0);
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent var(--message-background) transparent;
        }
      `;
    case 'bottom-right':
      return css`
        top: calc(100% + 5px);
        right: 5px;
        transform: translateX(0);
        &::after {
          bottom: 100%;
          right: 15%;
          transform: translateX(0);
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent var(--message-background) transparent;
        }
      `;
    case 'left':
      return css`
        top: 50%;
        right: calc(100% + 5px);
        transform: translateY(-50%);
        &::after {
          top: 50%;
          right: -5px;
          transform: translateY(-50%);
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent var(--message-background);
        }
      `;
    case 'right':
      return css`
        top: 50%;
        left: calc(100% + 5px);
        transform: translateY(-50%);
        &::after {
          top: 50%;
          left: -5px;
          transform: translateY(-50%);
          border-width: 5px 5px 5px 0;
          border-color: transparent var(--message-background) transparent transparent;
        }
      `;
    default:
      return css`
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        &::after {
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-width: 5px 5px 0;
          border-color: var(--message-background) transparent transparent transparent;
        }
      `;
  }
};

const Popover = ({ message, children, position = 'top', messageFontFamily, messageFontSize, messageFontWeight, messageBgColor, messageTextColor, messageWidth,messageTextAlign,messageOpacity }) => {
  const [isVisible, setIsVisible] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTogglePopover = () => {
    setIsVisible((prevState) => !prevState);
  };

  return (
    <span>
      <PopoverContainer ref={popoverRef} tabIndex={0} aria-label='Popover'>
      <div onClick={handleTogglePopover} style={{ cursor: 'pointer' }}>
        {children}
      </div>
      <PopoverText isVisible={isVisible} messageWidth={messageWidth} position={position} messageBgColor={messageBgColor} messageTextColor={messageTextColor} messageFontFamily={messageFontFamily} messageFontSize={messageFontSize} messageFontWeight={messageFontWeight} messageTextAlign={messageTextAlign} messageOpacity={messageOpacity}>
        {message}
      </PopoverText>
    </PopoverContainer>
    </span>
  );
};

export default Popover;
