import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
    display: ${props =>`${props.display || 'block'}`};
    width: ${props =>`${props.width || '100%'}`};
    padding: .375rem .75rem;
    font-size: ${props =>`${props.fontSize || '1rem'}`};
    font-weight: ${props =>`${props.fontWeight || '400'}`};
    line-height: ${props =>`${props.lineHeight || '1.5'}`};
    color:${props =>`${props.textColor || '#212529'}`};
    background-color:${props =>`${props.BgColor || '#fff'}`};
    background-clip: padding-box;
    border: ${props =>`${props.border || '1px solid rgba(0, 123, 255, 0.25)'}`}; 
    border-radius:${props =>`${props.borderRadius || '.25rem'}`};
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    ${ props => props.InputCustomCss}

    &:focus {
        border: 1px solid rgba(0, 123, 255, 0.25)!important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        outline: 0ch;

    }
`;

const StyledTextarea = styled.textarea`
    display: ${props =>`${props.display || 'block'}`};
    width: ${props =>`${props.width || '100%'}`};
    padding: .375rem .75rem;
    font-size: ${props =>`${props.fontSize || '1rem'}`};
    font-weight: ${props =>`${props.fontWeight || '400'}`};
    line-height: ${props =>`${props.lineHeight || '1.5'}`};
    color:${props =>`${props.textColor || '#212529'}`};
    background-color:${props =>`${props.BgColor || '#fff'}`};
    background-clip: padding-box;
    border: ${props =>`${props.border || '1px solid rgba(0, 123, 255, 0.25)'}`}; 
    border-radius:${props =>`${props.borderRadius || '.25rem'}`};
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    ${props => props.InputCustomCss}

    &:focus {
        border: 1px solid rgba(0, 123, 255, 0.25)!important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        outline: 0ch;

    }
`;

const ErrorMessage = styled.span`
    display:${props =>`${props.errorDisplay || 'block'}`};
    color: ${props =>`${props.errorTextColor || '#ff0000'}`}; 
    font-size: ${props =>`${props.errorfontSize || '0.9rem'}`}; 
    padding:  0.5rem;
    margin: 3px 2px;
    ${props => props.ErrorCustomCss}
`;

const FormDiv = styled.div`
    display: block;
    unicode-bidi: isolate;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    ${props => props.InputWrapperCustomCss}
`;

const Label = styled.label`
    margin-bottom: .5rem;
    display: inline-block;
    ${props => props.LabelCustomCss}
`;

const regex = {
    email: new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ),
    number: new RegExp('^[0-9]+$'),
};

const InputField = ({ value, label, placeholder, validators, type, onChange, textareaRow, errorMessage,...props }) => {
    const [error, setError] = useState(false);

    const handleChange = (event) => {
        const { value } = event.target;
        setError(validateInput(validators, errorMessage, value));
        onChange(value);
    };

    return (
        <FormDiv {...props}>
            {label && <Label htmlFor="app-input-field" {...props}>{label}</Label>}
            {type === 'textarea' ? (
                <StyledTextarea
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    rows={textareaRow}
                    autoComplete='off'
                    {...props}
                />
            ) : (
                <StyledInput
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleChange}
                    autoComplete='off'
                    {...props}
                />
            )}
            {error && <ErrorMessage {...props}>{error.errorMessage}</ErrorMessage>}
        </FormDiv>
    );
};

InputField.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validators: PropTypes.array,
    type: PropTypes.string,
    textareaRow: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
};

InputField.defaultProps = {
    value: '',
    label: '',
    placeholder: '',
    type: 'text',
    validators: [],
};

export default InputField;

export const Validators = {
    email: (value, errorMessage) => {
        if (value) {
            const result = regex.email.test(value);
            if (!result) return { error: true, errorMessage: errorMessage ? errorMessage : 'Invalid email address' };
        }
        return false;
    },
    required: (value, errorMessage) => {
        if (!value || !value.trim().length) {
            return { error: true, errorMessage: errorMessage ? errorMessage : 'This field is required' };
        }
        return false;
    },
    number: (value, errorMessage) => {
        if (value) {
            const result = regex.number.test(value);
            if (!result) return { error: true, errorMessage: errorMessage ? errorMessage : 'Please enter a valid number' };
        }
        return false;
    },
    passwordLength: (value, errorMessage) => {
        if (value) {
            const length = value.length;
            if (length < 8 || length > 16) {
                return { error: true, errorMessage: errorMessage ? errorMessage : 'Password must be between 8 and 16 characters long' };
            }
        }
        return false;
    },
    passwordComplex: (value, errorMessage) => {
        if (value) {
            // Check for complex characters
            const complexRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]+$/;
            const isComplex = complexRegex.test(value);
            if (!isComplex) {
                return { error: true, errorMessage: errorMessage ? errorMessage : 'Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character' };
            }
        }
        return false;
    },
};

export const validateInput = (validators, errorMessage, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            if (typeof validators[i] === 'string' && Validators[validators[i]]) {
                const error = Validators[validators[i]](value, errorMessage);
                if (error) {
                    return error;
                }
            } else if (typeof validators[i] === 'object' && validators[i].type && Validators[validators[i].type]) {
                const { type, errorMessage } = validators[i];
                const error = Validators[type](value, errorMessage);
                if (error) {
                    return error;
                }
            } else if (typeof validators[i] === 'function') {
                const error = validators[i](value);
                if (error) {
                    return error;
                }
            }
        }
    }
    return false;
};
