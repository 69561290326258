import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const getTooltipPosition = (position) => {
  switch (position) {
    case 'top':
      return css`
        bottom: 125%;
        left: 50%;
        transform: translateX(-50%);
        &::after {
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-width: 5px 5px 0;
          border-color: var(--message-background) transparent transparent transparent;
        }
      `;
    case 'top-left':
      return css`
        bottom: 125%;
        left: 0;
        transform: translateX(0);
        &::after {
          top: 100%;
          left: 10%;
          transform: translateX(0);
          border-width: 5px 5px 0;
          border-color: var(--message-background) transparent transparent transparent;
        }
      `;
    case 'top-right':
      return css`
        bottom: 125%;
        right: 0;
        transform: translateX(0);
        &::after {
          top: 100%;
          right: 10%;
          transform: translateX(0);
          border-width: 5px 5px 0;
          border-color: var(--message-background) transparent transparent transparent;
        }
      `;
    case 'bottom':
      return css`
        top: 125%;
        left: 50%;
        transform: translateX(-50%);
        &::after {
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent var(--message-background) transparent;
        }
      `;
    case 'bottom-left':
      return css`
        top: 125%;
        left: 0;
        transform: translateX(0);
        &::after {
          bottom: 100%;
          left: 10%;
          transform: translateX(0);
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent var(--message-background) transparent;
        }
      `;
    case 'bottom-right':
      return css`
        top: 125%;
        right: 0;
        transform: translateX(0);
        &::after {
          bottom: 100%;
          right: 10%;
          transform: translateX(0);
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent var(--message-background) transparent;
        }
      `;
    case 'left':
      return css`
        top: 50%;
        right: 125%;
        transform: translateY(-50%);
        &::after {
          top: 50%;
          right: -5px;
          transform: translateY(-50%);
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent var(--message-background);
        }
      `;
    case 'left-top':
      return css`
        top: 0;
        right: 125%;
        transform: translateY(0);
        &::after {
          top: 10%;
          right: -5px;
          transform: translateY(0);
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent var(--message-background);
        }
      `;
    case 'left-bottom':
      return css`
        bottom: 0;
        right: 125%;
        transform: translateY(0);
        &::after {
          bottom: 10%;
          right: -5px;
          transform: translateY(0);
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent var(--message-background);
        }
      `;
    case 'right':
      return css`
        top: 50%;
        left: 125%;
        transform: translateY(-50%);
        &::after {
          top: 50%;
          left: -5px;
          transform: translateY(-50%);
          border-width: 5px 5px 5px 0;
          border-color: transparent var(--message-background); transparent transparent;
        }
      `;
    case 'right-top':
      return css`
        top: 0;
        left: 125%;
        transform: translateY(0);
        &::after {
          top: 10%;
          left: -5px;
          transform: translateY(0);
          border-width: 5px 5px 5px 0;
          border-color: transparent var(--message-background) transparent transparent;
        }
      `;
    case 'right-bottom':
      return css`
        bottom: 0;
        left: 125%;
        transform: translateY(0);
        &::after {
          bottom: 10%;
          left: -5px;
          transform: translateY(0);
          border-width: 5px 5px 5px 0;
          border-color: transparent var(--message-background) transparent transparent;
        }
      `;
    default:
      return css`
        bottom: 125%;
        left: 50%;
        transform: translateX(-50%);
        &::after {
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-width: 5px 5px 0;
          border-color: var(--message-background) transparent transparent transparent;
        }
      `;
  }
};

const TooltipText = styled.div`
  --message-background: ${({ messageBgColor }) => messageBgColor || 'gray'};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: ${({ messageWidth }) => messageWidth || '120px'};
  background-color: var(--message-background);
  color: ${({ messageTextColor }) => messageTextColor || 'white'};
  font-family: ${({ messageFontFamily }) => messageFontFamily || 'inherit'};
  font-size: ${({ messageFontSize }) => messageFontSize || 'inherit'};
  font-weight: ${({ messageFontWeight }) => messageFontWeight || 'inherit'};
  text-align: ${({ messageTextAlign }) => messageTextAlign || 'left'};
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  opacity:${({ messageOpacity }) => messageOpacity};
  ${({ position }) => getTooltipPosition(position)}

  &::after {
    content: '';
    position: absolute;
    border-style: solid;
  }
`;

const Tooltip = ({ message, children, position = 'top', messageFontFamily, messageFontSize, messageFontWeight, messageBgColor, messageTextColor, messageWidth,messageTextAlign,messageOpacity }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipWidth, setTooltipWidth] = useState('120px');
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const width = tooltipRef.current.offsetWidth;
      if (width > 120) {
        setTooltipWidth(`${width}px`);
      } else {
        setTooltipWidth('120px');
      }
    }
  }, [message]);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <span>
      <TooltipContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} tabIndex={0} aria-label='Tooltips'>
      {children}
      <TooltipText isVisible={isVisible} messageWidth={messageWidth} ref={tooltipRef} position={position} messageBgColor={messageBgColor} messageTextColor={messageTextColor} messageFontFamily={messageFontFamily} messageFontSize={messageFontSize} messageFontWeight={messageFontWeight} messageTextAlign={messageTextAlign} messageOpacity={messageOpacity}>
        {message}
      </TooltipText>
    </TooltipContainer>
    </span>
  );
};
export default Tooltip;
