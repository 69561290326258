import React,{useState} from 'react';
import './Usage.css';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from './ScrollButton';

const Usage = () => {
  const initialCode = `
  <Button variant="primary" textColor="white" onClick={haddleButton}>Primary</Button>
  <Button variant="secondary" textColor="white" onClick={haddleButton}>secondary</Button>
  <Button variant="danger" textColor="white" onClick={haddleButton}>danger</Button>
  <Button variant="success" textColor="white" onClick={haddleButton}>danger</Button>
  <Button variant="warning" textColor="black" onClick={haddleButton}>warning</Button>
  `;
  const [code, setCode] = useState(initialCode);
  const handleButton = () => alert('Hello,I am button');

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    console.log(newCode);
  };

  const snipet_code = `
  import React from 'react';
  import Button from './Components/Button/Button';
  const App = () => {
    return (
      <>
        <Button variant="primary" textColor="white" onClick={haddleButton}>Primary</Button>
        <Button variant="secondary" textColor="white" onClick={haddleButton}>secondary</Button>
        <Button variant="danger" textColor="white" onClick={haddleButton}>danger</Button>
        <Button variant="success" textColor="white" onClick={haddleButton}>danger</Button>
      </>
    );
  };
  export default App;
  `;

  // State to track whether initial highlight should be shown
  const [showInitialHighlight, setShowInitialHighlight] = useState(true);

  // Function to handle hover effect (remove initial highlight)
  const handleMouseEnter = () => {
    setShowInitialHighlight(true);
  };

  // Function to handle leaving hover effect (restore initial highlight if needed)
  const handleMouseLeave = () => {
    setShowInitialHighlight(false);
  };

  return (
    <div class="container">
       <ScrollButton/>
      <header class="header">
          <h1>Usage</h1>
      </header>
      <div style={{padding:'40px'}}>
        <div className={`usage-first-container section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
        <h3 style={{fontFamily:'sans-serif',fontWeight:'800',fontSize:'18px'}}>Learn the basics of working with Atomic-Fusion UI components.</h3>
         <br/>
         <h3>Quickstart</h3>
         <br/>
         <p>After installation, you can import any Atomic-Fusion UI component and start playing around. For example, try changing the variant on the Button to outlined to see how the style changes:</p>
        </div>
         <br/>
         <div className='section-div'>
              {/* Preview and Editor section */}
            <div className="section-A-Container" style={{marginTop:'10px'}}>
              <h3>Code Editor with preview.</h3>
              <div className="preview-editor-section column">
                <div className='width-100'>
                    <Preview code={code} handle={handleButton} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0,5rem'}} />
                  </div>
                  <div className='width-100'>
                    <CodeEditor heading="Editor" onChange={handleCodeChange}>
                      {code}
                    </CodeEditor>
                  </div>
              </div> 
            </div>

            <br/>
              {/* Snippet code section */}
              <div className="section-C-Container section-div " style={{marginBottom:'40px'}}>
                <p>Using this component in any page, full code snippet.</p><br/>
                <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>   
                </div> 

         </div>
      </div>
   </div>
  )
}

export default Usage