import React, { useRef, useEffect } from 'react';

const AutoPlayVideo = ({ src, width, height }) => {
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   const video = videoRef.current;
  //   video.play();

  //   // return () => {
  //   //   video.pause();
  //   // };
  // }, []);

  return (
    // <video ref={videoRef} width={width} height={height} controls>
    //   <source src={src} type="video/mp4" />
    //   Your browser does not support the video tag.
    // </video>
    <video width={width} height={height} controls>
    <source src={src} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  );
};

export default AutoPlayVideo;
