import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: ${props => props.width || '400px'};
    background-color: ${props => props.bgColor || '#fff'};
    border: ${props => props.border || '1px solid #ccc'};
    border-radius: ${props => props.borderRadius || '10px'};
    box-shadow: ${props => props.boxShadow || '0 4px 8px grey'};
    overflow:hidden;
    margin:1rem;
    text-align:${props => props.textAlign};
    ${props => props.CustomCss};
    

    @media(max-width:768px){
        width:100%;
    }
`;
const Header = styled.div`
   padding:0.5rem;
   margin: 0.5rem;
   width: ${props => props.width};
   background-color: ${props => props.bgColor};
   color: ${props => props.textColor};
   height: ${props => props.height};
   font-size: ${props => props.fontSize};
   font-family: ${props => props.fontFamily};
   ${props => props.CustomCss};
`;
const BodyContainer = styled.div`
    padding:0.5rem;
    margin: 1rem;
    display:${props => props.width || 'flex'};
    flex-direction:column;
    gap:1rem;
    width: ${props => props.width};
    background-color: ${props => props.bgColor};
    color: ${props => props.textColor};
    height: ${props => props.height};
    font-size: ${props => props.fontSize};
    font-family: ${props => props.fontFamily};
    ${props => props.CustomCss};
`;
const FooterContainer = styled.div`
        padding:0.5rem;
        margin: 1rem;
        display:${props => props.width || 'flex'};
        flex-direction:column;
        gap:1rem;
        width: ${props => props.width};
        background-color: ${props => props.bgColor};
        color: ${props => props.textColor};
        height: ${props => props.height};
        font-size: ${props => props.fontSize};
        font-family: ${props => props.fontFamily};
        ${props => props.CustomCss};
`;

const CardContainer = ({ children, ...props }) => {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
};

const CardHeader = ({ children, ...props }) => {
  return (
    <Header {...props}>
      {children}
    </Header>
  );
};

const CardBody = ({ children, ...props }) => {
  return (
    <BodyContainer {...props}>
      {children}
    </BodyContainer>
  );
};

const CardFooter = ({ children, ...props }) => {
  return (
    <FooterContainer {...props}>
      {children}
    </FooterContainer>
  );
};

const Card = ({children}) => {
  return (
    <div>{children}</div>
  );
};

export { CardContainer, CardHeader, CardBody, CardFooter, Card };
export default Card;
