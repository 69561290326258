import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  position:relative;
`;

const Slider = styled.div`
  position: relative;
  width: ${props => `${props.PickerWidth || '100%'}`};
`;

const Track = styled.div`
  background-color: ${props => `${props.PickerTrackBgColor || 'green'}`};
  border-radius: ${props => `${props.PickerTrackerBorderRadius || '3px'}`}; 
  height: ${props => `${props.RangePickerHeight || '20px'}`};
  width:100%; 
  position: absolute;
  top:-5%;
`;

const Range = styled.div`
  background-color: ${props => `${props.PickerRangeBgColor || 'red'}`};
  border-radius: ${props => `${props.PickerRangeBorderRadius || '3px'}`};
  height: ${props => `${props.RangePickerHeight || '20px'}`};
  position: absolute;
  width:100%; 
  top:-5%;
`;

const LeftValue = styled.div`
  color:${props => `${props.LabelColor || '#dee2e6'}`}; 
  font-size:${props => `${props.LabelfontSize || '12px'}`};
  font-family:${props => `${props.LabelfontFamily || 'inherit'}`};
  font-weight:${props => `${props.LabelfontWeight || 'inherit'}`};
  margin-top: 50px;
  left: 0;
  position: absolute;
`;

const RightValue = styled.div`
  color:${props => `${props.LabelColor || '#dee2e6'}`}; 
  font-size:${props => `${props.LabelfontSize || '12px'}`};
  font-family:${props => `${props.LabelfontFamily || 'inherit'}`};
  font-weight:${props => `${props.LabelfontWeight || 'inherit'}`};
  margin-top: 50px;
  right: 0;
  position: absolute;
`;

const Thumb = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  pointer-events: none;
  position: absolute;
  height: 0px;
  width:${props => `${props.PickerWidth || '100%'}`}; 
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color:${props => `${props.PickerThumbBgColor || 'white'}`};
    border: ${props => `${props.PickerThumbBorder || '1px solid black'}`}; 
    border-radius: ${props => `${props.PickerThumbBorderRadius || '10%'}`};  
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: ${props => `${props.PickerThumbHeight || '30px'}`};
    width: ${props => `${props.PickerThumbWidth || '30px'}`};
    pointer-events: all;
    position: relative;
    top: ${props => `${props.PickerThumbTop || '10px'}`};
  }

  &::-moz-range-thumb {
    background-color:${props => `${props.PickerThumbBgColor || 'white'}`};
    border: ${props => `${props.PickerThumbBorder || '1px solid black'}`}; 
    border-radius: ${props => `${props.PickerThumbBorderRadius || '10%'}`};  
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: ${props => `${props.PickerThumbHeight || '30px'}`};
    width: ${props => `${props.PickerThumbWidth || '30px'}`};
    pointer-events: all;
    position: relative;
    top: ${props => `${props.PickerThumbTop || '10px'}`};
  }
`;

const ThumbLeft = styled(Thumb)`
  z-index: 1;
`;

const ThumbRight = styled(Thumb)`
  z-index: 1;
`;

const MultiRangeSlider = ({ min, max, onChange,step,...props }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <>
    <Container {...props}>
      <ThumbLeft
        min={min}
        max={max}
        value={minVal}
        step={step || 1}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        style={{ zIndex: minVal > max - 100 && "5" }}
        {...props}
      />
      <ThumbRight
        min={min}
        max={max}
        step={step || 1}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        {...props}
      />

      <Slider {...props} tabIndex={0} aria-label="SliderRangePicker">
        <Track {...props} />
        <Range ref={range} {...props}/>
        <LeftValue style={{color:'black'}} {...props}>{min}</LeftValue>
        <RightValue style={{color:'black'}} {...props}>{max}</RightValue>
      </Slider>
    </Container>
    </>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
