import React,{useState} from 'react';
import CodeEditor from '../Editor/Editor';
import Preview from '../Editor/Preview';
import './style.css';
import CodeSnippet from '../Editor/CodeSnippet';
import ScrollButton from '../Get-Started/ScrollButton';

const CardPage = () => {
  const initialCode = `
              <div style={{display:'flex',flexDirection:'row'}}>
              <CardContainer
                bgColor="transparent"
                borderRadius="15px"
                boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
                textAlign="center"
                style={{padding:'20px'}}
            >
               <CardHeader
                bgColor="transparent"
                textColor="#fff"
                fontSize="24px"
                fontFamily="Arial, sans-serif"
                >
                    <Typography variant="h3" textAlign="center">Card1</Typography>
                </CardHeader>

                 <CardBody
                    bgColor="#ffffff"
                    textColor="#333"
                    height="auto"
                    fontSize="18px"
                    fontFamily="Arial, sans-serif"
                >
                    <img src="https://th.bing.com/th/id/OIP.tLotgCDtzgTdwJcTiXWRCwHaEK?rs=1&pid=ImgDetMain" style={{ height: '200px', width: '100%', borderRadius: '5px' }} alt="Card1" />
                </CardBody>

                <CardFooter
                    bgColor="#ffffff"
                    textColor="#333"
                    height="auto"
                    fontSize="16px"
                    fontFamily="Arial, sans-serif"
                >
                    <Typography variant="h4">Heading</Typography>
                    <Typography variant="body">Capturing moments in life's tapestry, one click at a time.</Typography>
                    <Button variant="primary" width="6">Read more</Button>
                </CardFooter>
               
            </CardContainer>
            <CardContainer
                bgColor="transparent"
                borderRadius="15px"
                boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
                textAlign="center"
                style={{padding:'20px'}}
            >
               <CardHeader
                bgColor="transparent"
                textColor="#fff"
                fontSize="24px"
                fontFamily="Arial, sans-serif"
                >
                    <Typography variant="h3" textAlign="center">Card1</Typography>
                </CardHeader>

                 <CardBody
                    bgColor="#ffffff"
                    textColor="#333"
                    height="auto"
                    fontSize="18px"
                    fontFamily="Arial, sans-serif"
                >
                    <img src="https://th.bing.com/th/id/OIP.tLotgCDtzgTdwJcTiXWRCwHaEK?rs=1&pid=ImgDetMain" style={{ height: '200px', width: '100%', borderRadius: '5px' }} alt="Card1" />
                </CardBody>

                <CardFooter
                    bgColor="#ffffff"
                    textColor="#333"
                    height="auto"
                    fontSize="16px"
                    fontFamily="Arial, sans-serif"
                >
                    <Typography variant="h4">Heading</Typography>
                    <Typography variant="body">Capturing moments in life's tapestry, one click at a time.</Typography>
                    <Button variant="primary" width="6">Read more</Button>
                </CardFooter>
               
            </CardContainer>
              </div>
            `;

  const [code, setCode] = useState(initialCode);
  const handleCodeChange = (newCode) => {
    setCode(newCode);
    // console.log(newCode);
  };

  

  const snipet_code = `
  import React from 'react';
import { CardContainer, CardHeader, CardBody, CardFooter } from './customComponents/Card/Card';
import image from './customComponents/Card/download.jpg';
import Typography from './customComponents/Typography/Typography';
import Button from './customComponents/Button/Button';

const App = () => {

    return (
        <div style={{ margin: 'auto', width: '80%', display: 'flex', flexDirection: 'row' }}>
            <CardContainer
                width="400px"
                bgColor="#f0f0f0"
                borderRadius="15px"
                boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
                textAlign="center"
            >
                <CardHeader
                    width="100%"
                    bgColor="#4CAF50"
                    textColor="#fff"
                    height="80px"
                    fontSize="24px"
                    fontFamily="Arial, sans-serif"
                >
                    <Typography variant="h3">Card1</Typography>
                </CardHeader>
                <CardBody
                    width="100%"
                    bgColor="#ffffff"
                    textColor="#333"
                    height="auto"
                    fontSize="18px"
                    fontFamily="Arial, sans-serif"
                >
                    <img src={image} style={{ height: '300px', width: '100%', borderRadius: '5px' }} alt="Card1" />
                </CardBody>
                <CardFooter
                    width="100%"
                    bgColor="#ffffff"
                    textColor="#333"
                    height="auto"
                    fontSize="16px"
                    fontFamily="Arial, sans-serif"
                >
                    <Typography variant="h4">Heading</Typography>
                    <Typography variant="body">Capturing moments in life's tapestry, one click at a time.</Typography>
                    <Button variant="primary">Read more</Button>
                </CardFooter>
            </CardContainer>

            <CardContainer
                width="400px"
                bgColor="#f0f0f0"
                borderRadius="15px"
                boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
                textAlign="center"
            >
                <CardHeader
                    width="100%"
                    bgColor="#FF5722"
                    textColor="#fff"
                    height="80px"
                    fontSize="24px"
                    fontFamily="Arial, sans-serif"
                >
                    <Typography variant="h3">Card2</Typography>
                </CardHeader>
                <CardBody
                    width="100%"
                    bgColor="#ffffff"
                    textColor="#333"
                    height="auto"
                    fontSize="18px"
                    fontFamily="Arial, sans-serif"
                >
                    <img src={image} style={{ height: '300px', width: '100%', borderRadius: '5px' }} alt="Card2" />
                </CardBody>
                <CardFooter
                    width="100%"
                    bgColor="#ffffff"
                    textColor="#333"
                    height="auto"
                    fontSize="16px"
                    fontFamily="Arial, sans-serif"
                >
                    <Typography variant="h4">Heading</Typography>
                    <Typography variant="body">Capturing moments in life's tapestry, one click at a time.</Typography>
                    <Button variant="primary">Read more</Button>
                </CardFooter>
            </CardContainer>
        </div>
    );
};

export default App;

  `;

      // State to track whether initial highlight should be shown
  const [showInitialHighlight, setShowInitialHighlight] = useState(true);

  // Function to handle hover effect (remove initial highlight)
  const handleMouseEnter = () => {
    setShowInitialHighlight(true);
  };

  // Function to handle leaving hover effect (restore initial highlight if needed)
  const handleMouseLeave = () => {
    setShowInitialHighlight(false);
  };

  return (
    <div className="wrapper-container">
       <ScrollButton/>
      {/* Heading */}
      <div className='Header-section'>
        <center>
           <h2>Card</h2>
        </center>
         <div className={`content-section section-div ${showInitialHighlight ? 'initial-highlight' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
            <p>The "Card" component serves as a captivating canvas for content display, blending sleek design with intuitive functionality. With its structured layout encompassing header, body, and footer sections, this versatile element offers an enticing platform for presenting information. Seamlessly responsive, it ensures a visually appealing experience across diverse devices, captivating users with its elegance and adaptability.</p>
         </div>

      </div>
     

      {/* Preview and Editor section */}
      <div className="section-A-Container section-div">
        <p>Code Editor with preview.</p>
        <div className="preview-editor-section column">
            <div className='width-100'>
              <Preview code={code} style={{display:'flex',justifyContent:'start!important',alignItems:'start!important',gap:'0.5rem'}} customCssPreview={{display:'flex',flexDirection:'row!important'}}/>
            </div>
            <div className='width-100'>
              <CodeEditor heading="Editor" onChange={handleCodeChange}>
                {code}
              </CodeEditor>
            </div>
        </div> 
      </div>

     {/* Props details section */}
      <div className="section-B-Container section-div">
      <p>Apart from the above variant property there many properties that can be implement in the button component.</p>

      <div className="table-container">
          <table className="styled-table">
            <thead className="table-head">
              <tr className="">
                <th className="table-header">CSS Prop</th>
                <th className="table-header">Description</th>
                <th className="table-header">Default Value</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr className="table-row">
                <td className="table-data">containerCustomCss</td>
                <td className="table-data">Custom CSS object to style the accordion container.</td>
                <td className="table-data">-</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TitleCustomCss</td>
                <td className="table-data">Custom CSS object to style the title of each accordion item.</td>
                <td className="table-data">-</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">contentCustomCss</td>
                <td className="table-data">Custom CSS object to style the content of each accordion item.</td>
                <td className="table-data">-</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SymbolCustomCss</td>
                <td className="table-data">Custom CSS object to style the icon symbol in each accordion item.</td>
                <td className="table-data">-</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TitleBgColor</td>
                <td className="table-data">Background color of the title section.</td>
                <td className="table-data">#f1f1f1</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TitlePadding</td>
                <td className="table-data">Padding of the title section.</td>
                <td className="table-data">15px</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TitleTextColor</td>
                <td className="table-data">Text color of the title section.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TitlefontSize</td>
                <td className="table-data">Font size of the title section.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TitlefontFamily</td>
                <td className="table-data">Font family of the title section.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">TitlefontWeight</td>
                <td className="table-data">Font weight of the title section.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SymbolTextColor</td>
                <td className="table-data">Text color of the icon symbol.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SymbolfontSize</td>
                <td className="table-data">Font size of the icon symbol.</td>
                <td className="table-data">1.5rem</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">SymbolfontWeight</td>
                <td className="table-data">Font weight of the icon symbol.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">containerBorder</td>
                <td className="table-data">Border of the accordion container.</td>
                <td className="table-data">1px solid #ccc</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">containerBorderRadius</td>
                <td className="table-data">Border radius of the accordion container.</td>
                <td className="table-data">5px</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">containerWidth</td>
                <td className="table-data">Width of the accordion container.</td>
                <td className="table-data">90vw</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">ContentBgColor</td>
                <td className="table-data">Background color of the content section.</td>
                <td className="table-data">white</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">ContentTextColor</td>
                <td className="table-data">Text color of the content section.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">ContentfontSize</td>
                <td className="table-data">Font size of the content section.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">ContentfontFamily</td>
                <td className="table-data">Font family of the content section.</td>
                <td className="table-data">inherit</td>
              </tr>
              <tr className="table-row">
                <td className="table-data">ContentfontWeight</td>
                <td className="table-data">Font weight of the content section.</td>
                <td className="table-data">inherit</td>
              </tr>
            </tbody>
          </table>
        </div>


      </div> 

      {/* Snippet code section */}
      <div className="section-C-Container section-div">
          <p>Using this component in any page, full code snippet.</p><br/>
          <CodeSnippet heading="Snipet Code">{snipet_code}</CodeSnippet>      
      </div>   
    </div>
  )
}

export default CardPage